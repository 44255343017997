import React, { useEffect, useState } from "react";
import CheckAvailable from "./CheckAvailable";
import GuestDetails from "./GuestDetails";
import Overview from "./Overview";
import moment from "moment";
import { getProfile, getToken } from "../../../services/Authentication";
import { get, post } from "../../../services/ApiRouting";
import { Spin, message } from "antd";
import { useDrawerContext } from "../../../context/DrawerControlContext";

export default function CreateBooking() {
  const [bookingSteps, setBookingSteps] = useState("availiblity");
  const [rooms, setRooms] = useState([]);
  const [selectedRoomsList, setSelectedRoomsList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [guestList, setGuestList] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [paymentType, setPaymentType] = useState("Full Payment");
  const { profile } = getProfile();
  const { access_token } = getToken();
  const [loading, setLoading] = useState(false);
  const { setDrawerData, drawerData } = useDrawerContext();


  const findGuestCount = (type) => {
    let totalCount;

    const totalAdultSpace = selectedRoomsList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.adult_space,
      0
    );
    const totalChildSpace = selectedRoomsList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.child_space,
      0
    );

    if (type === "child") {
      const data = guestList.filter((value) => value.age < 18);
      if (parseInt(data.length) > parseInt(totalChildSpace)) {
        totalCount = parseInt(data.length) - parseInt(totalChildSpace);
      }
    } else {
      const data = guestList.filter((value) => value.age >= 18);
      if (parseInt(data.length) > parseInt(totalAdultSpace)) {
        totalCount = parseInt(data.length) - parseInt(totalAdultSpace);
      }
    }
    return totalCount ? totalCount : 0;
  };

  const TotalGuest = (type) => {
    let data = null;
    if (type === "adult") {
      data = guestList.filter((value) => value.age >= 18);
    } else {
      data = guestList.filter((value) => value.age < 18);
    }

    return data?.length;
  };

  const priceDetail = (type, id) => {
    // =========== Guest Ammount =============
    if (type === "extraGuest") {
      const guestAmount =
        parseInt(
          findGuestCount("adult") *
            parseInt(
              selectedRoomsList[0]?.exctra_adult_price
                ? selectedRoomsList[0]?.exctra_adult_price
                : 0
            ) +
            parseInt(findGuestCount("child"))
        ) *
        parseInt(
          selectedRoomsList[0]?.extra_child_price
            ? selectedRoomsList[0]?.extra_child_price
            : 0
        );

      return guestAmount;
    }

    // =============== Room Amount ==============
    if (type === "room") {
      const start = new Date(selectedDate[0]);
      const end = new Date(selectedDate[1]);
      let totalRoomAmount = 0;

      while (start <= end) {
        const dayOfWeek = start.getDay();
        let roomAmount;

        if (dayOfWeek === 5) {
          // Friday
          roomAmount = selectedRoomsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(currentValue.friday_price) *
                parseInt(currentValue.quantity),
            0
          );
        } else if (dayOfWeek === 6) {
          // Saturday
          roomAmount = selectedRoomsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(currentValue.saturday_price) *
                parseInt(currentValue.quantity),
            0
          );
        } else if (dayOfWeek === 0) {
          // Sunday
          roomAmount = selectedRoomsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(currentValue.sunday_price) *
                parseInt(currentValue.quantity),
            0
          );
        } else {
          // Weekdays
          roomAmount = selectedRoomsList.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(currentValue.price) * parseInt(currentValue.quantity),
            0
          );
        }

        totalRoomAmount += roomAmount;
        start.setDate(start.getDate() + 1); // Move to the next day
      }

      return totalRoomAmount;
    }

    // ============= Taxes ==========
    if (type === "tax") {
      const subtotal = priceDetail("extraGuest") + priceDetail("room");
      const tax = parseInt(profile.account.tax_payment);

      const taxAmount = (subtotal * tax) / 100;
      return taxAmount;
    }

    // ============= Taxes ==========
    if (type === "payingAmount") {
      const totalAmount =
        priceDetail("extraGuest") + priceDetail("room") + priceDetail("tax");

      const payingAmount =
        totalAmount / (paymentType === "Full Payment" ? 1 : 2);
      return payingAmount;
    }

    // =============== Total amount ==========
    if (type === "total") {
      const totalAmount =
        priceDetail("extraGuest") + priceDetail("room") + priceDetail("tax");
      return totalAmount;
    }
  };

  const handleSubmitForm = async () => {
    try {
      setLoading(true);

      const roomData = selectedRoomsList.map((value) => ({
        item_type: "room",
        item_id: value.id,
        amount: priceDetail("room", value.id) * parseInt(value.quantity),
        quantity: value.quantity,
      }));

      const data = {
        user_id: selected.id,
        check_in: moment(new Date(selectedDate[0])).format("YYYY-MM-DD"),
        check_out: moment(new Date(selectedDate[1])).format("YYYY-MM-DD"),
        guest_adult: TotalGuest("adult"),
        guest_child: TotalGuest("child"),
        extra_guest_amount: priceDetail("extraGuest"),
        tax: priceDetail("tax"),
        paid_amount: calculatePayments(paymentType)?.paying,
        total: priceDetail("total"),
        account_id: profile.account_id,
        branch_id: "",
        booking_status: "payment pending",
        guests: guestList,
        items: roomData,
      };

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/booking", data, header);

      if (resp) {
        setLoading(false);
        message.success("booking success");
        setSelectedRoomsList([]);
        setGuestList([]);
        setSelectedRooms([]);
        setRooms([]);
        setBookingSteps("availiblity");
        setSelected(null);
        setSelectedDate();
        setDrawerData({
          isOpen: false,
        });
      }
    } catch (error) {
      setLoading(false);
      message.error("Unable to booked please try again");
      console.log(error);
    }
  };

  // =============== Number Of boolkings find =================

  const calculateWeekdaysAndWeekends = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let weekdays = 0;
    let weekends = 0;

    while (start <= end) {
      const dayOfWeek = start.getDay();
      if (dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0) {
        // Friday (5), Saturday (6), Sunday (0)
        weekends++;
      } else {
        weekdays++;
      }
      start.setDate(start.getDate() + 1);
    }

    return { weekdays, weekends };
  };

  const handleFetchRoomList = async (value) => {
    try {
      setSelectedDate(value);
      setLoading(true);
      const fromDate = moment(new Date(value[0])).format("YYYY-MM-DD");
      const toDate = moment(new Date(value[1])).format("YYYY-MM-DD");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await get(
        "/booking-check/" + fromDate + "/" + toDate + "/" + profile.account_id,
        header
      );
      if (resp) {
        setLoading(false);
        setRooms(resp.data);
      }
    } catch (error) {
      setLoading(false);
      setRooms([]);
      console.error(error);
      message.error("Sorry unable to find room");
    }
  };

  useEffect(() => {
    if (drawerData?.date?.length > 0) {
      const renderTime = setTimeout(() => {
        setSelectedDate(drawerData.date);
        handleFetchRoomList(drawerData.date);
      }, 300);
      return () => clearTimeout(renderTime);
    }
  }, [drawerData]);

  const calculatePayments = (type) => {
    let paying = 0;
    let remaining = 0;

    switch (type) {
      case "Full Payment":
        paying = priceDetail("total");
        remaining = 0;
        break;

      case "Advance Only":
        if (
          parseInt(profile.account.advnc_pay) < 0 ||
          parseInt(profile.account.advnc_pay) > 100
        ) {
          alert("Advance percentage must be between 0 and 100.");
          return;
        }
        paying =
          (parseInt(profile.account.advnc_pay) / 100) * priceDetail("total");
        remaining = priceDetail("total") - paying;
        break;

      case "Payment while checkout":
        paying = 0;
        remaining = priceDetail("total");
        break;

      default:
        alert("Invalid payment type selected.");
        return;
    }

    return { paying, remaining };
  };

  return (
    <Spin spinning={loading}>
      {bookingSteps === "availiblity" && (
        <CheckAvailable
          setBookingSteps={setBookingSteps}
          rooms={rooms}
          setRooms={setRooms}
          selectedRoomsList={selectedRoomsList}
          setSelectedRoomsList={setSelectedRoomsList}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          handleFetchRoomList={handleFetchRoomList}
        />
      )}
      {bookingSteps === "guestDetails" && (
        <GuestDetails
          setBookingSteps={setBookingSteps}
          selected={selected}
          setSelected={setSelected}
          guestList={guestList}
          setGuestList={setGuestList}
        />
      )}
      {bookingSteps === "Overview" && (
        <Overview
          setBookingSteps={setBookingSteps}
          selectedRoomsList={selectedRoomsList}
          selectedDate={selectedDate}
          selected={selected}
          guestList={guestList}
          handleSubmitForm={handleSubmitForm}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          priceDetail={priceDetail}
          calculateWeekdaysAndWeekends={calculateWeekdaysAndWeekends}
          findGuestCount={findGuestCount}
          calculatePayments={calculatePayments}
        />
      )}
    </Spin>
  );
}
