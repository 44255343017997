const storeToken = (value, isRemeber, data) => {
  if (value) {
    if (isRemeber) {
      localStorage.setItem("access_token", value);
      localStorage.setItem("profile", JSON.stringify(data));
    } else {
      sessionStorage.setItem("access_token", value);
      sessionStorage.setItem("profile", JSON.stringify(data));
    }
  }
};

const getToken = () => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = localStorage.getItem("access_token");
  } else {
    access_token = sessionStorage.getItem("access_token");
  }
  return { access_token };
};

const getProfile = () => {
  let profile;
  const access_profile = JSON.parse(sessionStorage.getItem("access_profile"));
  if (access_profile) {
    profile = access_profile;
  } else {
    profile = JSON.parse(localStorage.getItem("profile"));
    if (profile) {
      profile = JSON.parse(localStorage.getItem("profile"));
    } else {
      profile = JSON.parse(sessionStorage.getItem("profile"));
    }
  }
  return { profile };
};

const getAccessProfile = () => {
  let access_profile = JSON.parse(sessionStorage.getItem("access_profile"));
  let access_account = JSON.parse(sessionStorage.getItem("access_account"));
  return { access_profile, access_account };
};

const removeAccess = () => {
  sessionStorage.removeItem("access_profile");
  sessionStorage.removeItem("access_account");
};

const removeToken = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export {
  storeToken,
  getToken,
  removeToken,
  getProfile,
  getAccessProfile,
  removeAccess,
};
