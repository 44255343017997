import { Button, Collapse, Empty, message, Select, Spin } from "antd";
import React from "react";
import { FaCheck } from "react-icons/fa6";
import { post } from "../../services/ApiRouting";
import { getToken } from "../../services/Authentication";

export default function CleanRoom({ loading, rooms, setRooms }) {
  const { access_token } = getToken();

  const updateDetails = async (value) => {
    try {
      const formData = new FormData();

      formData.append("id", value.id);
      formData.append("room_clean_status", value.room_clean_status);
      formData.append("_method", "put");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/room-clean-update", formData, header);

      if (resp) {
        message.success("Update successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hasData = rooms?.some((value) => value.rooms.length > 0);

  return (
    <Spin spinning={loading}>
      {hasData ? (
        rooms?.map((value, index) =>
          value.rooms.length > 0 ? (
            <Collapse
              collapsible="header"
              activeKey={[1]}
              style={{ margin: "0 0 15px 0" }}
              items={[
                {
                  key: "1",
                  label: value.room_villa_name,
                  children: value.rooms.map((valueData, i) => (
                    <div className="row mb-3">
                      <div className="col-md-4 d-flex align-items-center">
                        {valueData.room_no}
                      </div>
                      <div className="col-2 d-flex align-items-center">-</div>
                      <div className="col-md-4 d-flex align-items-center">
                        <Select
                          className="w-100"
                          placeholder="Clean status"
                          value={valueData.room_clean_status}
                          onChange={(value) =>
                            setRooms((prev) => {
                              const prevData = [...prev];
                              const prevRoom = [...prevData[index].rooms];
                              prevRoom[i] = {
                                ...prevRoom[i],
                                room_clean_status: value,
                              };
                              prevData[index] = {
                                ...prevData[index],
                                rooms: prevRoom,
                              };
                              return prevData;
                            })
                          }
                          options={[
                            { label: "clean", value: "clean" },
                            { label: "cleaning", value: "cleaning" },
                            { label: "unclean", value: "unclean" },
                          ]}
                        />
                      </div>
                      <div className="col-md-2 d-flex align-items-center">
                        <Button
                          icon={<FaCheck />}
                          style={{
                            background: "green",
                            color: "#fff",
                            height: "40px",
                            width: "100%",
                            borderRadius: "10px",
                          }}
                          onClick={() => updateDetails(valueData)}
                        ></Button>
                      </div>
                    </div>
                  )),
                },
              ]}
            />
          ) : null
        )
      ) : (
        <Empty />
      )}
    </Spin>
  );
}
