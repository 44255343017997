import React from "react";
import {
  Col,
  DatePicker,
  Row,
  Segmented,
  Select,
  Input,
  Space,
  Badge,
  Button,
} from "antd";
import { FiSearch } from "react-icons/fi";
import { IoGridSharp } from "react-icons/io5";
import { PiListFill } from "react-icons/pi";
import dayjs from "dayjs";
import { FiRefreshCcw } from "react-icons/fi";

const { RangePicker } = DatePicker;

export default function SubHeader({
  dateRange,
  setDateRange,
  searchText,
  setSearchText,
  fetchData
}) {
  return (
    <>
      <div className="d-flex align-items-center  w-100">
        <div className="d-flex">
          <RangePicker
            allowClear={false}
            defaultValue={[
              dayjs(dateRange[0], "YYYY-MM-DD"),
              dayjs(dateRange[1], "YYYY-MM-DD"),
            ]}
            className="formInput"
            style={{ width: "100%" }}
            onChange={(value) => setDateRange(value)}
            format="DD MMM YYYY"
          />

          <Input
            placeholder="Room Name"
            className="formInput mx-3"
            prefix={<FiSearch />}
            value={searchText}
            style={{ width: "100%" }}
            onInput={(e) => setSearchText(e.target.value)}
          />
        </div>

        <Button className="addButtons" icon={<FiRefreshCcw />} onClick={fetchData}>Refresh data</Button>

        <div className="grid">
          {/* <Segmented
            style={{ padding: 7, background: "#808080", color: "#fff" }}
            options={[
              { label: "List", value: "list", icon: <PiListFill /> },
              { label: "Grid", value: "grid", icon: <IoGridSharp /> },
            ]}
          /> */}
        </div>
      </div>
      <div className="colorDetail my-3">
        <Space direction="horizontal">
          <Badge color="#00C3DE" text="Not Check in yet" />
          <Badge color="#00D12F" text="Checked In" />
          <Badge color="#FFA500" text="Checked Out" />
        </Space>
      </div>
    </>
  );
}
