import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { MdFoodBank } from "react-icons/md";
import TableComp from "../../components/Table/TableComp";
import { get, post } from "../../services/ApiRouting";
import moment from "moment";
import { Avatar, Badge, Button, Popover, Tooltip, message } from "antd";
import DiscountModal from "../../components/Modal/DiscountModal/DiscountModal";
import { FaEdit, FaRegImage, FaTrash } from "react-icons/fa";
import { PiCurrencyInrBold } from "react-icons/pi";
import { getProfile, getToken } from "../../services/Authentication";
import { useNavigate } from "react-router-dom";

export default function FoodList() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { profile } = getProfile();
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);

  const columns = [
    {
      key: "sort",
      dataIndex: "sort",
      width: "2%",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "30%",
    },
    {
      title: "Categorie",
      dataIndex: "categoryName",
      key: "categoryName",
      width: "10%",
    },
    {
      title: "Qty & Price",
      dataIndex: "quantities",
      key: "quantities",
      width: "10%",
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   key: "discount",
    //   width: "10%",
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const handleAdd = () => {};

  const { access_token } = getToken();
  const navigate = useNavigate();

  const fetchFoodData = async (search, page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };

      const result = await get(
        `/main-food-details/${profile.account_id}/${page - 1}/${limit}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );
      setTotal(result?.total);
      const convertedData = result.data.map((value, index) => ({
        ...value,
        name: (
          <div className="m-0 d-flex align-items-center">
            <span
              className={`${value.type.replace(/ /g, "")} typeVegNonveg mx-2`}
            ></span>
            {value.name}
          </div>
        ),
        key: index + 1,
        image: <Avatar src={value.image} icon={<FaRegImage />} />,
        description: <p>{value.description}</p>,
        created_at: moment(value.created_at).format("DD MMM YYYY"),

        discount: (
          <DiscountModal value={value} handleAddDiscount={handleAddDiscount} />
        ),
        quantities: (
          <Popover
            title="Quantity & Price"
            content={value?.detail?.map((dataValue) => (
              <div className="d-flex justify-content-between align-items-center">
                <strong>{dataValue.quantity}</strong>
                <span className="d-flex align-items-center">
                  <PiCurrencyInrBold />
                  {dataValue.price}
                </span>
              </div>
            ))}
          >
            <Badge showZero count={value?.detail?.length} />
          </Popover>
        ),
        action: (
          <div className="d-flex">
            <Tooltip title="Edit" placement="bottom">
              <Button
                className="iconButtonAction edit"
                onClick={() => navigate(`/update-food/${value.slug}`)}
              >
                <FaEdit />
              </Button>
            </Tooltip>
            <Tooltip title="Remove" placement="bottom">
              <Button
                className="iconButtonAction remove"
                // onClick={() => handleRemove(index, value.id)}
              >
                <FaTrash />
              </Button>
            </Tooltip>
          </div>
        ),
      }));
      setDataSource(convertedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log(limit);
    const bounseTime = setTimeout(() => {
      fetchFoodData(searchText, page, limit);
    }, 500);

    return () => clearTimeout(bounseTime);
    // eslint-disable-next-line
  }, [searchText, page, limit]);

  const handleAddDiscount = async (discountSelectedKey, id) => {
    try {
      if (discountSelectedKey) {
        setLoading(true);

        const formData = new FormData();
        formData.append("entity_type", "food");
        formData.append("entity_id", id);
        formData.append("discount_id", discountSelectedKey[0]);

        const resp = await post("/discount-assign", formData);

        if (resp) {
          message.success("Discount Selected succesfully");
          setLoading(false);
          // setIsModalOpen(false);
        }
      }
    } catch (error) {
      console.error(error);
      message.error("unable add discount please try again");
      setLoading(false);
    }
  };

  const handleListSearch = (text) => {
    setSearchText(text);
  };

  return (
    <div>
      <PageTitle
        title="Food List"
        buttonText="Add Food"
        handleAdd={handleAdd}
        isButtonShow={false}
        icon={<MdFoodBank />}
        link="/add-food"
        refreshData={() => fetchFoodData("", page, limit)}
        handleListSearch={handleListSearch}
      />
      <div className="px-4">
        <TableComp
          isDragble={true}
          columns={columns}
          setDataSource={setDataSource}
          dataSource={dataSource}
          loading={loading}
          total={total}
          page={page}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </div>
  );
}
