import React, { useEffect, useState } from "react";
import SearchPopupModal from "../../Modal/SearchPopupModal";
import { Divider, Avatar, Form, Button, Image, Dropdown, Empty } from "antd";
import { IoMail } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { FaCirclePlus, FaUser } from "react-icons/fa6";
import { FaTrashAlt, FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { HiDocumentArrowUp } from "react-icons/hi2";
import { FaRegFileWord } from "react-icons/fa";
import GuestDetailModal from "../../Modal/GuestDetailModal";

export default function GuestDetails({
  setBookingSteps,
  selected,
  setSelected,
  guestList,
  setGuestList,
}) {
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [imgPath, setImgPath] = useState(null);
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();

  const [modalDetail, setModalDetail] = useState({ isOpen: false, title: "" });

  const handleAddGuest = (value) => {
    console.log(value);
    const data = {
      name: value.name,
      age: value.age,
      file: file,
      imagePath: file ? URL.createObjectURL(file) : null,
    };
    setGuestList((prev) => [...prev, data]);
    form.resetFields();
    setFile(null);
  };

  const handleUpdateGuest = (value) => {
    // Create a shallow copy of the guest list
    const updatedGuestList = [...guestList];

    // Update the specific guest details
    updatedGuestList[modalDetail?.index] = {
      ...updatedGuestList[modalDetail?.index],
      age: value.age,
      file: file ? file : modalDetail.value.file,
      imagePath: file
        ? URL.createObjectURL(file.originFileObj)
        : modalDetail.value.imagePath,
      name: value.name,
    };

    // Update the state with the new guest list
    setGuestList(updatedGuestList);

    // Reset the form fields
    form.resetFields();

    // Reset the file state
    setFile(null);
  };

  const handleRemove = (index) => {
    if (window.confirm("Are sure you want to remove ?")) {
      guestList.splice(index, 1);
      setGuestList([...guestList]);
    }
  };

  useEffect(() => {
    if (selected) {
      const index = guestList.findIndex((value) => value.type === "self");

      const data = {
        name: selected.name,
        age: selected.age,
        file: null,
        imagePath: null,
        type: "self",
      };

      if (index >= 0) {
        // Update the existing entry
        setGuestList((prev) =>
          prev.map((item, i) => (i === index ? data : item))
        );
      } else {
        // Add a new entry
        setGuestList((prev) => [...prev, data]);
      }
    }
  }, [selected]);

  return (
    <div>
      <h6>Search Customer</h6>
      <SearchPopupModal
        api="/users-list/user/"
        list={list}
        setList={setList}
        selected={selected}
        setSelected={setSelected}
      />
      {selected && (
        <div className="selectedDetail">
          <Divider orientation="left">Booking By</Divider>
          <h5 className="d-flex align-items-baseline">
            <Avatar src={selected.image} icon={<FaUser />} />
            &nbsp;{selected.name}
          </h5>
          <div className="selectedContact d-flex align-items-center">
            <p className="p-0 d-flex align-items-center">
              <IoCall />
              &nbsp;
              {selected.phone}
            </p>
            <p className="mx-3 p-0 d-flex align-items-center">
              <IoMail /> &nbsp;
              {selected.email}
            </p>
          </div>
        </div>
      )}

      <Divider orientation="left">Guest's</Divider>

      {guestList?.length > 0 ? (
        <div className="guestList">
          {guestList.map((value, index) => (
            <div className="guestCard mb-2 d-flex justify-content-between align-items-center">
              <div className="detail d-flex align-items-center">
                <FaUserCircle />
                &nbsp;&nbsp;
                {value?.name}&nbsp;&nbsp;
                {value.age ? value.age + " Years" : null}&nbsp;&nbsp;
                {value.file ? <FaRegFileWord /> : null}
              </div>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "Update Detail",
                      key: "1",
                      icon: <HiDocumentArrowUp />,
                      onClick: () =>
                        setModalDetail({
                          title: "Update",
                          isOpen: true,
                          value: value,
                          index: index,
                        }),
                    },
                    {
                      label: "Remove",
                      key: "2",
                      icon: <FaTrashAlt />,
                      danger: true,
                      onClick: () => handleRemove(index),
                    },
                    // {
                    //   label: "Add new",
                    //   key: "3",
                    //   icon: <FaCirclePlus />,
                    //   onClick: () =>
                    //     setModalDetail({ title: "Add", isOpen: true }),
                    // },
                  ],
                }}
                placement="bottomLeft"
              >
                <Button>
                  <HiDotsVertical />
                </Button>
              </Dropdown>
            </div>
          ))}

          <button
            className="addNewuttonGuest"
            onClick={() => setModalDetail({ title: "Add", isOpen: true })}
          >
            Add New Guest
          </button>
        </div>
      ) : (
        <Empty />
      )}

      <Divider />
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="addButtons w-100 mt-3"
          onClick={() => setBookingSteps("availiblity")}
        >
          Previous
        </Button>

        <Button
          disabled={guestList.length === 0 || selected === null}
          className="uploadButton w-100 mt-3 mx-3"
          onClick={() => setBookingSteps("Overview")}
        >
          Submit & Next
        </Button>
      </div>

      <Image
        width={200}
        style={{
          display: "none",
        }}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
        preview={{
          visible,
          src: imgPath,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      <GuestDetailModal
        modalDetail={modalDetail}
        setModalDetail={setModalDetail}
        handleAddGuest={handleAddGuest}
        handleUpdateGuest={handleUpdateGuest}
        setFile={setFile}
      />
    </div>
  );
}
