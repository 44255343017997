import { Button, Form, Input, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getToken } from "../../../services/Authentication";
import { post } from "../../../services/ApiRouting";
import { useDrawerContext } from "../../../context/DrawerControlContext";

export default function UpdateLocation() {
  const [form] = Form.useForm();
  const { setDrawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [files, setFiles] = useState();
  const { drawerData } = useDrawerContext();

  const resizeImage = (file, maxSize, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const scaleSize = maxSize / Math.max(img.width, img.height);
        canvas.width = img.width * scaleSize;
        canvas.height = img.height * scaleSize;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            callback(blob);
          },
          file.type,
          0.7
        ); // Adjust quality as needed
      };
    };
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; // Get the first selected file

    if (selectedFile) {
      resizeImage(selectedFile, 500, (resizedBlob) => {
        setFiles(
          new File([resizedBlob], selectedFile.name, {
            type: selectedFile.type,
          })
        );
      });
    }
  };

  const columns = [
    {
      name: "id",
      col: "col-12 d-none",
    },
    {
      label: "Image",
      name: "images",
      col: "col-12",
      prop: (
        <Input
          accept=".JPEG, .JPG, .PNG, .WEBP, .AVIF"
          type="file"
          className="formInput"
          placeholder="Select Image"
          onChange={(e) => handleFileChange(e)}
        />
      ),
    },
    {
      label: "Location Name",
      name: "name",
      col: "col-12",
    },
    {
      label: "Location City",
      name: "city",
      col: "col-12",
    },
    {
      label: "Location State",
      name: "state",
      col: "col-12",
    },
    {
      label: "Location Country",
      name: "country",
      col: "col-12",
    },
    {
      name: "country",
      col: "col-12 w-100",
      prop: (
        <Button
          htmlType="submit"
          className="text-white bg-success w-100"
          style={{ height: 45 }}
        >
          Update
        </Button>
      ),
    },
  ];

  const onFinish = async (value) => {
    try {
      console.log(value);
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const formData = new FormData();
      formData.append("id", value.id);
      formData.append("name", value.name);
      formData.append("city", value.city);
      formData.append("state", value.state);
      formData.append("country", value.country);
      formData.append("_method", "PUT");

      if (value.images) {
        formData.append("image", files);
      }
      const resp = await post("/location/"+value.id, formData, header);

      if (resp) {
        setLoading(false);
        setFiles(null);
        setDrawerData({
          isOpen: false,
        });
        message.success("Account Updated successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable updated account please try again");
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      form.setFieldsValue(
        drawerData.value // Spread drawerData.value to set form fields
      );
    }, 300);

    return () => clearTimeout(dbounce); // Cleanup the timeout on unmount or dependency change
  }, [drawerData.value]);

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="row">
          {columns.map((val) => (
            <div className={val.col}>
              <Form.Item label={val.label} name={val.name}>
                {val.prop ? (
                  val.prop
                ) : (
                  <Input placeholder={val.label} className="formInput" />
                )}
              </Form.Item>
            </div>
          ))}
        </div>
      </Form>
    </Spin>
  );
}
