import React from "react";
import Count from "../components/Dashboard/Count";
import VisitChart from "../components/Dashboard/VisitChart";
import BookingChart from "../components/Dashboard/BookingChart";
import { Divider } from "antd";
import RoomBooks from "../components/Dashboard/RoomBooks";

export default function Dashboard() {
  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-7">
          <Count />
          <BookingChart />
        </div>
        <div className="col-md-5">
          <VisitChart />
          <RoomBooks />
        </div>
      </div>
    </div>
  );
}
