import { Button, DatePicker, Input } from "antd";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { FiRefreshCcw } from "react-icons/fi";

export default function CheckInHeader({
  currentDate,
  setCurrentDate,
  search,
  setSearch,
  fetchData,
}) {
  return (
    <div
      className="d-flex align-items-center justify-content-between mb-4"
      style={{ maxWidth: "700px" }}
    >
      <DatePicker
        allowClear={false}
        className="formInput"
        style={{ width: "100%", marginRight: "20px" }}
        format="DD MMM YYYY"
        value={currentDate}
        onChange={setCurrentDate}
      />
      <Input
        placeholder="Room Name"
        className="formInput"
        prefix={<FiSearch />}
        style={{ width: "100%" }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Button
        icon={<FiRefreshCcw />}
        onClick={() => fetchData(currentDate, search)}
        className="addButtons mx-3"
      >
        Refresh Data
      </Button>
    </div>
  );
}
