import React, { useEffect, useState } from "react";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { get } from "../../../services/ApiRouting";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import dayjs from "dayjs";
import { HiDocumentArrowUp } from "react-icons/hi2";
import { FaRegFileWord, FaTrashAlt, FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import GuestDetailModal from "../../Modal/GuestDetailModal";
import { MdMeetingRoom } from "react-icons/md";
import { MdBedroomParent } from "react-icons/md";
import { getProfile, getToken } from "../../../services/Authentication";
import { FaRegEdit } from "react-icons/fa";

const { RangePicker } = DatePicker;

export default function EditBooking() {
  const { drawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const [modalDetail, setModalDetail] = useState({ isOpen: false, title: "" });
  const [visible, setVisible] = useState(false);
  const [imgPath, setImgPath] = useState(null);
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();
  const [rooms, setRooms] = useState([]);
  const { access_token } = getToken();
  const { profile } = getProfile();
  const [bookingDetail, setBookingDetail] = useState(null);

  const fetchBookingDetails = async (search, account_id) => {
    try {
      setLoading(true);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await get(
        `/booking/${0}/${1}/${account_id}${search !== "" ? "/" + search : ""}`,
        header
      );

      if (resp) {
        setLoading(false);
        setBookingDetail(resp?.data[0]);
        setRooms(resp?.data[0]?.items);
        setGuestList(resp?.data[0]?.guests);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchBookingDetails(drawerData.id, profile.account_id);
    }, 300);

    return () => clearTimeout(renderTime);
  }, [drawerData]);

  const handleRemove = (index) => {
    if (window.confirm("Are sure you want to remove ?")) {
      guestList.splice(index, 1);
      setGuestList([...guestList]);
    }
  };

  const handleAddGuest = (value) => {
    const data = {
      name: value.name,
      age: value.age,
      file: file,
      imagePath: file ? URL.createObjectURL(file) : null,
    };
    setGuestList((prev) => [...prev, data]);
    form.resetFields();
    setFile(null);
  };

  const handleUpdateGuest = (value) => {
    // Create a shallow copy of the guest list
    const updatedGuestList = [...guestList];

    // Update the specific guest details
    updatedGuestList[modalDetail?.index] = {
      ...updatedGuestList[modalDetail?.index],
      age: value.age,
      file: file ? file : modalDetail.value.file,
      imagePath: file
        ? URL.createObjectURL(file.originFileObj)
        : modalDetail.value.imagePath,
      name: value.name,
    };

    // Update the state with the new guest list
    setGuestList(updatedGuestList);

    // Reset the form fields
    form.resetFields();

    // Reset the file state
    setFile(null);
  };

  const items = [
    {
      key: "1",
      label: "Rooms",
      children: (
        <>
          {rooms?.length > 0 ? (
            <>
              {rooms?.map((value) => (
                <div className="guestCard mb-2 d-flex justify-content-between align-items-center">
                  <div className="detail d-flex align-items-center">
                    <MdMeetingRoom />
                    &nbsp;&nbsp;
                    {value?.name}&nbsp;&nbsp;&nbsp;&nbsp;
                    <MdBedroomParent />
                    &nbsp;&nbsp;
                    {value?.quantity}
                  </div>
                  <div className="removeButton">
                    <Button style={{ background: "orange", color: "#fff" }}>
                      <FaRegEdit />
                    </Button>
                    <Button style={{ background: "red", color: "#fff" }}>
                      <FaTrashAlt />
                    </Button>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-between align-items-center">
                <Select
                  className="w-100"
                  option={[]}
                  placeholder="Select Room"
                />
                <Button
                  style={{
                    height: "40px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  className="addButtons"
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <Empty />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Guests",
      children: (
        <>
          {guestList?.length > 0 ? (
            <div className="guestList">
              {guestList.map((value, index) => (
                <div className="guestCard mb-2 d-flex justify-content-between align-items-center">
                  <div className="detail d-flex align-items-center">
                    <FaUserCircle />
                    &nbsp;&nbsp;
                    {value?.name}&nbsp;&nbsp;
                    {value.age ? value.age + " Years" : null}&nbsp;&nbsp;
                    {value.file ? <FaRegFileWord /> : null}
                  </div>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: "Update Detail",
                          key: "1",
                          icon: <HiDocumentArrowUp />,
                          onClick: () =>
                            setModalDetail({
                              title: "Update",
                              isOpen: true,
                              value: value,
                              index: index,
                            }),
                        },
                        {
                          label: "Remove",
                          key: "2",
                          icon: <FaTrashAlt />,
                          danger: true,
                          onClick: () => handleRemove(index),
                        },
                      ],
                    }}
                    placement="bottomLeft"
                  >
                    <Button>
                      <HiDotsVertical />
                    </Button>
                  </Dropdown>
                </div>
              ))}

              <button
                className="addNewuttonGuest"
                onClick={() => setModalDetail({ title: "Add", isOpen: true })}
              >
                Add New Guest
              </button>
            </div>
          ) : (
            <Empty />
          )}
        </>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <RangePicker
        className="formInput w-100"
        value={[
          dayjs(bookingDetail?.check_in),
          dayjs(bookingDetail?.check_out),
        ]}
        disabled={[true, false]}
      />

      <Tabs
        style={{ marginTop: "20px" }}
        defaultActiveKey="1"
        items={items}
        // onChange={onChange}
        indicator={{
          size: (origin) => origin - 20,
          // align: alignValue,
        }}
      />

      <GuestDetailModal
        modalDetail={modalDetail}
        setModalDetail={setModalDetail}
        handleAddGuest={handleAddGuest}
        handleUpdateGuest={handleUpdateGuest}
        setFile={setFile}
      />

      <Divider orientation="left">Payment Detail</Divider>

      <Row>
        <Col sm={16}>
          Room Cost
          <br />
          <strong>(0 Weekdays, 0 Weekends)</strong>
        </Col>
        <Col sm={2}>-</Col>
        <Col sm={6}></Col>
      </Row>
      <Row>
        <Col sm={16}>Tax Charges</Col>
        <Col sm={2}>-</Col>
        <Col sm={6}></Col>
      </Row>
      <Divider />

      <Row>
        <Col sm={16}>Paid</Col>
        <Col sm={2}>-</Col>
        <Col sm={6}></Col>
      </Row>
      <Row>
        <Col sm={16}>Remaining</Col>
        <Col sm={2}>-</Col>
        <Col sm={6}></Col>
      </Row>
      <Divider />
      <Row>
        <Col sm={16}>
          <strong>Total</strong>
        </Col>
        <Col sm={2}>-</Col>
        <Col sm={6}></Col>
      </Row>
    </Spin>
  );
}
