import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { Button, Divider, Input, Table } from "antd";
import { get } from "../../../services/ApiRouting";
import moment from "moment";
import { PiCurrencyInrBold } from "react-icons/pi";

export default function DisountList({
  discountSelectedKey,
  setViewComponent,
  discountList,
  setDiscountList,
  setDiscountSelectedKey,
  setLoading,
}) {
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Coupon Code",
      key: "coupon",
      dataIndex: "coupon",
    },
    {
      title: "Amount / Rate",
      key: "amountRate",
      dataIndex: "amountRate",
    },
    {
      title: "Start At",
      key: "start",
      dataIndex: "start",
    },
    {
      title: "End At",
      key: "end",
      dataIndex: "end",
    },
    {
      title: "Created At",
      key: "created",
      dataIndex: "created",
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    const resp = await get("/discount-detail/discount");

    if (resp) {
      const data = resp.data.map((valueData) => ({
        ...valueData,
        key: valueData.id,
        name: (
          <span style={{ textTransform: "capitalize" }}>{valueData.name}</span>
        ),
        type: (
          <span style={{ textTransform: "capitalize" }}>{valueData.type}</span>
        ),
        coupon: valueData.coupon_code ? valueData.coupon_code : "-",
        amountRate:
          valueData.amount_rate_type === "amount" ? (
            <div>
              <PiCurrencyInrBold /> <span>{valueData.amount_rate}</span>
            </div>
          ) : (
            <div>
              <span>{valueData.amount_rate}</span>&nbsp;%
            </div>
          ),
        start: valueData.start_at
          ? moment(valueData.start_at).format("DD MMM YYYY")
          : "-",
        end: valueData.end_at
          ? moment(valueData.end_at).format("DD MMM YYYY")
          : "-",
        created: moment(valueData.created_at).format("DD MMM YYYY"),
      }));
      setDiscountList(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // ============== Select Discount ================
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDiscountSelectedKey(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <Divider />
      <div className="headerPanel mb-4 d-flex justify-content-between align-items-center">
        <Input
          prefix={<FiSearch />}
          className="searchInput"
          placeholder="Search here.."
        />
        <Button
          onClick={() => setViewComponent("add")}
          icon={<IoMdAddCircle />}
          style={{ height: "40px", background: "green", color: "#fff" }}
          type="primery"
        >
          Add New
        </Button>
      </div>
      <Table
        rowSelection={{
          defaultSelectedRowKeys: [discountSelectedKey], // Corrected key name
          type: "radio",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={discountList}
      />
    </div>
  );
}
