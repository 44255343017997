import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Checkbox, Form, Input, Button, message } from "antd";
import { post } from "../services/ApiRouting";
import { getToken, storeToken } from "../services/Authentication";
import { useDispatch } from "react-redux";
import { setUserToken } from "../features/authSlice";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = getToken();
  const [remember, setRemember] = useState(false);

  const handleSubmitForm = async (value) => {
    try {
      console.log(value);
      const formData = new FormData();
      formData.append("username", value.username);
      formData.append("password", value.password);

      const resp = await post("/admin-login", formData);

      if (resp.status) {
        message.success("Login Suucessfull");
        storeToken(resp.token, value.remember, resp.data);
        dispatch(setUserToken({ token: resp.token }));
        navigate("/");
      }
    } catch (error) {
      message.error("Username or Password is wrong");
      console.error(error);
    }
  };

  useEffect(() => {
    if (access_token) {
      dispatch(setUserToken({ token: access_token }));
    }
  }, [access_token]);

  return (
    <div
      className="loginPage"
      style={{ background: "#000", width: "100vw", height: "100vh" }}
    >
      <div className="background">
        <div className="shape" />
        <div className="shape" />
      </div>
      <Form layout="vertical" autoComplete="off" onFinish={handleSubmitForm}>
        <h3>Login Here</h3>
        <div className="inputField">
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true }]}
          >
            <Input placeholder="Email or Phone" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
        </div>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox
            value={remember}
            onChange={(e) => setRemember(e.target.value)}
          >
            Remember me
          </Checkbox>
        </Form.Item>

        <Form.Item className="w-100">
          <Button htmlType="submit" classNames="formButton">
            Log In
          </Button>
        </Form.Item>
        <div className="social">
          <NavLink to="/forgot-password">Forgot password</NavLink>
        </div>
      </Form>
    </div>
  );
}
