import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Layout, theme } from "antd";
import Sidebar from "../components/layout/sidebar";
import Header from "../components/layout/header";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAccessProfile,
  getProfile,
  removeAccess,
} from "../services/Authentication";
import { IoClose } from "react-icons/io5";

const LayoutContext = createContext();

export const useLayoutContext = () => useContext(LayoutContext);

const LayoutProvider = ({ children, title }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { Content } = Layout;

  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const divRef = useRef();
  const [isAdminAccess, setIsAdminAccess] = useState(false);
  const { profile } = getProfile();
  const { access_account } = getAccessProfile();
  const navigate = useNavigate();

  const location = useLocation();
  // ================ Get Param Details ==============
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params) {
      // Get query parameters
      const access = params.get("account_access");
      const encodedProfile = params.get("access_profile");

      try {
        // Decode the profile safely
        const access_profile = encodedProfile
          ? JSON.parse(atob(encodedProfile))
          : null;

        if (access_profile) {
          sessionStorage.setItem(
            "access_profile",
            JSON.stringify(access_profile)
          );
          sessionStorage.setItem("access_account", access);
        }

        // Update state only if access exists
        if (access) {
          setIsAdminAccess(access);
        }
      } catch (error) {
        console.error("Failed to decode access_profile:", error);
      }
    }
  }, [location]);

  const contextValue = {
    isOpen,
    setIsOpen,
    open,
    setOpen,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <Layout style={{ padding: "10px", background: "#eee" }}>
        <Sidebar isAdminAccess={isAdminAccess} isOpen={isOpen} />
        <Layout>
          {(isAdminAccess || access_account) && (
            <div className="px-3">
              <div className="admin_access">
                <div className="container">
                  <div className="left">
                    <strong>Loged In as:</strong>
                    <span className="mx-1">{profile.name}</span>
                  </div>
                  <Button
                    onClick={() => {
                      removeAccess();
                      window.location ="/accounts-list";
                    }}
                    icon={<IoClose />}
                  >
                    Exit Access
                  </Button>
                </div>
              </div>
            </div>
          )}
          <Header
            isAdminAccess={isAdminAccess}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            colorBgContainer={colorBgContainer}
            title={title}
          />
          <div className="contentDiv d-flex">
            <Content
              ref={divRef}
              style={{
                margin: "24px 16px",
                height: "86vh",
                overflowY: "auto",
                borderRadius: "10px",
                background: colorBgContainer,
              }}
            >
              {children}
            </Content>
          </div>
        </Layout>
      </Layout>
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
