import React, { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, Drawer, Empty, InputNumber, List } from "antd";
import { FaShoppingCart } from "react-icons/fa";
import { PiCurrencyInrBold } from "react-icons/pi";

export default function Cart({ cart, setCart }) {
  const [showCart, setShowCart] = useState(false);

  const navigate = useNavigate();
  const handlePlaceOrder = () => {
    navigate("/place-order");
    setShowCart(false);
  };

  // ====================== Increase Quantity ====================

  const handleQuantityUpdate = (value, index) => {
    const prevData = cart;
    prevData[index].itemQty = parseInt(value);
    prevData[index].price = cart[index].fixedPrice * value;
    setCart([...prevData]);
  };

  // ================= Remove Cart Data ===================

  const removeCartData = (index) => {
    const prevData = cart;

    prevData.splice(index, 1);
    setCart([...prevData]);
  };

  const handleCartList = () => {
    setShowCart((prev) => !prev);
  };

  console.log(cart)

  return (
    <div>
      <Badge count={cart.length}>
        <div className="cart-icon-section" onClick={handleCartList}>
          <FaShoppingCart />
          Your Cart
        </div>
      </Badge>
      <Drawer
        title="Cart"
        placement="right"
        onClose={handleCartList}
        open={showCart}
      >
        {cart && cart.length > 0 ? (
          <>
            <List itemLayout="horizontal">
              {cart.map((value, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={value.image} />}
                    title={
                      <div className="d-flex justify-content-between">
                        <span>{value.name}</span>
                        <span
                          onClick={() => removeCartData(index)}
                          style={{ color: "red" }}
                        >
                          <BsTrashFill />
                        </span>
                      </div>
                    }
                    description={
                      <div className="d-flex justify-content-start align-items-center">
                        <span>Price:&nbsp;<PiCurrencyInrBold />&nbsp;{value.amount} </span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                          Qty:
                          <InputNumber
                            min={1}
                            max={10}
                            type="number"
                            onChange={(value) =>
                              handleQuantityUpdate(value, index)
                            }
                            style={{ width: 65 }}
                            value={value.quantity}
                            placeholder="Qty"
                          />
                        </span>
                      </div>
                    }
                  />
                </List.Item>
              ))}
            </List>
            <div
              className="bottomButton"
              style={{
                bottom: 0,
                left: 0,
                position: "sticky",
                background: "#fff",
                width: "100%",
                padding: "15px",
              }}
            >
              <Button
                style={{
                  width: "100%",
                  background: "green",
                  color: "#fff",
                  height: "40px",
                  borderRadius: "50px",
                }}
                onClick={handlePlaceOrder}
              >
                Proceed to checkout
              </Button>
            </div>
          </>
        ) : (
          <Empty />
        )}
      </Drawer>
    </div>
  );
}
