import {
  Avatar,
  Button,
  Col,
  Divider,
  Image,
  List,
  Popover,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import { FaAngleDown, FaEye, FaTrash, FaUser } from "react-icons/fa6";
import { IoCall, IoMail } from "react-icons/io5";
import { LuIndianRupee } from "react-icons/lu";
import { MdDateRange, MdWatchLater } from "react-icons/md";
import { MdCurrencyRupee } from "react-icons/md";
import { FaDoorOpen } from "react-icons/fa6";

export default function Overview({
  selectedRoomsList,
  selectedDate,
  selected,
  guestList,
  setBookingSteps,
  handleSubmitForm,
  paymentType,
  setPaymentType,
  priceDetail,
  calculateWeekdaysAndWeekends,
  findGuestCount,
  calculatePayments,
}) {
  const [visible, setVisible] = useState(false);
  const [imgPath, setImgPath] = useState(null);

  return (
    <div>
      <div className="aboutRoom">
        <div
          className="date d-flex align-items-center"
          style={{ fontSize: ".95rem" }}
        >
          <MdDateRange />
          &nbsp;{moment(new Date(selectedDate[0])).format("DD MMMM YYYY")}
          &nbsp;&nbsp;<strong>To</strong> &nbsp;&nbsp;
          <MdDateRange />
          &nbsp;{moment(new Date(selectedDate[1])).format("DD MMMM YYYY")}
        </div>
        <List
          className="priceList"
          itemLayout="horizontal"
          dataSource={selectedRoomsList}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <List.Item.Meta
                style={{ marginTop: "10px" }}
                title={
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={{ cursor: "pointer" }}>
                      {index + 1}) {item.room_villa_name}
                    </span>
                    <span></span>
                    <span
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <Popover
                        style={{ maxWidth: 300 }}
                        content={
                          <div style={{ maxWidth: 300 }}>
                            <h5>{item.priceName}</h5>
                            <div className="row">
                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Price
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.price}
                              </div>
                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Check In At
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <MdWatchLater />
                                {moment(
                                  "2023-12-12 " + item.check_in_time
                                ).format("HH:MM A")}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Check Out At
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <MdWatchLater />
                                {moment(
                                  "2023-12-12 " + item.check_out_time
                                ).format("HH:MM A")}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Extra Adult Charge
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.exctra_adult_price}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Extra Child Charge
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.extra_child_price
                                  ? item.extra_child_price
                                  : 0}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Overtime Surcharge
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.surcharge_price}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Overtime rounded an hour
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <MdWatchLater /> {item.surcharge_hour} Min
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Friday Price
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.friday_price}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Saturday Price
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.saturday_price}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Sunday Price
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.sunday_price}
                              </div>

                              <div className="col-7">
                                <strong style={{ fontSize: ".8rem" }}>
                                  Holiday Price
                                </strong>
                              </div>
                              <div className="col-1">-</div>
                              <div className="col-4">
                                <LuIndianRupee />
                                {item.holiday_price}
                              </div>
                            </div>
                          </div>
                        }
                        title=""
                      >
                        <span
                          style={{
                            color: "#000",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <BiRupee /> {item.price}&nbsp;
                          <FaAngleDown />
                          &nbsp;/ Per Night
                        </span>
                      </Popover>
                    </span>
                    <span className="d-flex align-items-center">
                      <FaDoorOpen />
                      &nbsp;{item.quantity}
                    </span>
                  </div>
                }
                description={<p className="m-0">{item.typeName}</p>}
              />
            </List.Item>
          )}
        />
      </div>
      <div
        className="bookingDetails mb-3 mt-3 p-3"
        style={{ background: "#eee", padding: "10px", borderRadius: "5px" }}
      >
        {selected && (
          <div className="selectedDetail">
            <h6
              style={{ fontSize: "1.2rem" }}
              className="d-flex align-items-baseline"
            >
              <Avatar src={selected.image} icon={<FaUser />} />
              &nbsp;{selected.name}
            </h6>
            <div className="selectedContact d-flex align-items-center">
              <p className="p-0 d-flex align-items-center">
                <IoCall />
                &nbsp;{selected.phone}
              </p>
              <p className="mx-3 p-0 d-flex align-items-center">
                <IoMail /> &nbsp;
                {selected.email}
              </p>
            </div>
          </div>
        )}
        <div className="guestDetail">
          <List
            dataSource={guestList}
            renderItem={(item, index) => (
              <List.Item key={item.name}>
                <List.Item.Meta
                  title={
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        {index + 1}) &nbsp; {item.name}
                      </span>
                      <div className="d-flex align-items-center justify-content-center">
                        <div>Age:&nbsp;({item.age}&nbsp;Year)</div>
                        &nbsp; &nbsp;
                        {item.file && (
                          <Button
                            style={{ background: "#000", color: "#fff" }}
                            onClick={() => {
                              setVisible(true);
                              setImgPath(item.imagePath);
                            }}
                            title="View File"
                          >
                            <FaEye />
                          </Button>
                        )}
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
      <div className="paymentDetail">
        <Select
          placeholder="Select payment type"
          className="mb-4 w-100"
          onChange={(value) => setPaymentType(value)}
          value={paymentType}
          options={[
            {
              value: "Full Payment",
              label: "Full Payment",
            },
            {
              value: "Advance Only",
              label: "Advance Only",
            },
            {
              value: "Payment while checkout",
              label: "Payment while checkout ",
            },
          ]}
        />
        {findGuestCount("adult") !== 0 || findGuestCount("child") !== 0 ? (
          <Row>
            <Col span={16}>
              Extra guest Charge&nbsp;
              <strong>
                ({findGuestCount("adult")} Adult, {findGuestCount("child")}{" "}
                Child)
              </strong>
            </Col>
            <Col span={2}>-</Col>
            <Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <MdCurrencyRupee /> {priceDetail("extraGuest")}
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col span={16}>
            <span>Room Charges</span>
            <br />
            <strong>
              (
              {calculateWeekdaysAndWeekends(selectedDate[0], selectedDate[1])
                .weekdays +
                " Weekdays, " +
                calculateWeekdaysAndWeekends(selectedDate[0], selectedDate[1])
                  .weekends +
                " Weekends"}
              )
            </strong>
          </Col>
          <Col span={2}>-</Col>
          <Col
            span={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MdCurrencyRupee /> {priceDetail("room")}
          </Col>
        </Row>

        <Row>
          <Col span={16}>Tax & Charges</Col>
          <Col span={2}>-</Col>
          <Col
            span={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MdCurrencyRupee /> {priceDetail("tax")}
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={16}>
            <strong>Total</strong>
          </Col>
          <Col span={2}>-</Col>
          <Col
            span={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MdCurrencyRupee /> {priceDetail("total")}
          </Col>
        </Row>

        <Row>
          <Col span={16}>
            Paying (<strong>{paymentType}</strong>)
          </Col>
          <Col span={2}>-</Col>
          <Col
            span={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MdCurrencyRupee /> {calculatePayments(paymentType)?.paying}
          </Col>
        </Row>

        <Row>
          <Col span={16}>Balance</Col>
          <Col span={2}>-</Col>
          <Col
            span={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MdCurrencyRupee /> {calculatePayments(paymentType)?.remaining}
          </Col>
        </Row>
      </div>
      <Divider />

      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="addButtons w-100 mt-3"
          onClick={() => setBookingSteps("guestDetails")}
        >
          Previous
        </Button>

        <Button
          disabled={guestList.length === 0}
          className="uploadButton w-100 mt-3 mx-3"
          onClick={handleSubmitForm}
        >
          Make Booking
        </Button>
      </div>

      <Image
        width={200}
        style={{
          display: "none",
        }}
        preview={{
          visible,
          src: imgPath,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </div>
  );
}
