import { Button, Modal, Spin, message } from "antd";
import React, { useRef, useState } from "react";
import { BiRupee } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import DisountList from "./DisountList";
import AddDiscount from "./AddDiscount";

export default function DiscountModal({ value, handleAddDiscount }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewComponent, setViewComponent] = useState("list");
  const [discountList, setDiscountList] = useState([]);
  const [discountSelectedKey, setDiscountSelectedKey] = useState(
    value?.discount[0]?.id
  );
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formRef = useRef();

  const submitForm = () => {
    formRef.current.submit();
  };

  return (
    <div>
      {value.discount[0] ? (
        value.discount[0].amount_rate_type === "amount" ? (
          <span
            onClick={showModal}
            style={{
              color: "#000",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <BiRupee /> {value?.discount[0]?.amount_rate} <FaAngleDown />
          </span>
        ) : (
          <span
            style={{
              color: "#000",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={showModal}
          >
            {value?.discount[0]?.amount_rate + " %"} <FaAngleDown />
          </span>
        )
      ) : (
        <Button style={{color:"#000", borderColor:"#000"}} onClick={showModal}>
          Add <FaAngleDown />
        </Button>
      )}
      <Modal
        width={1100}
        title="Discount List"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={
          <>
            <Button
              onClick={handleCancel}
              type="primary"
              style={{ color: "#fff", background: "#000" }}
            >
              Cancel
            </Button>
            {viewComponent === "add" ? (
              <Button
                type="primary"
                style={{ color: "#fff", background: "green" }}
                onClick={submitForm}
              >
                Add
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ color: "#fff", background: "green" }}
                onClick={() => handleAddDiscount(discountSelectedKey, value.id)}
              >
                Add
              </Button>
            )}
          </>
        }
      >
        <Spin spinning={loading}>
          {" "}
          {viewComponent === "list" ? (
            <DisountList
              setViewComponent={setViewComponent}
              discountList={discountList}
              setDiscountList={setDiscountList}
              setDiscountSelectedKey={setDiscountSelectedKey}
              setLoading={setLoading}
              discountSelectedKey={discountSelectedKey}
            />
          ) : (
            <AddDiscount
              setViewComponent={setViewComponent}
              discountList={discountList}
              setDiscountList={setDiscountList}
              formRef={formRef}
              setLoading={setLoading}
            />
          )}
        </Spin>
      </Modal>
    </div>
  );
}
