import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function VisitChart() {
  // utils/dateUtils.js
  const getPrevious7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(moment(date).format("DD MMM"));
    }
    return dates;
  };

  const data = {
    labels: getPrevious7Days(),
    datasets: [
      {
        label: "7 Days website visits",
        data: [450, 300, 500, 700, 600, 750, 800],
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: false,
      },
    },
  };

  return (
    <div className="dashboardCard">
      <div className="dashboardHeader d-flex align-items-baseline justify-content-between">
        <div className="leftPanel">
          <h5>Daily Visitor's</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
        </div>
        {/* <div className="rightPanel">
        <Button icon={<CiExport />}>Export</Button>
      </div> */}
      </div>
      <div className="dashboardBody">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}
