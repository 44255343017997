import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { Pagination, Table } from "antd";
import { MdDragIndicator } from "react-icons/md";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MdDragIndicator
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                  fontSize: "20px",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const TableComp = ({
  setDataSource,
  dataSource,
  columns,
  isDragble,
  loading,
  total,
  limit,
  page,
  setPage,
}) => {
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  return (
    <>
      {isDragble ? (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              style={{ zIndex: 999 }}
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              loading={loading}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
      ) : (
        <Table
          columns={columns}
          style={{ zIndex: 999 }}
          dataSource={dataSource}
          loading={loading}
          pagination={false}
        />
      )}
      <Pagination
        className="mt-4"
        hideOnSinglePage
        total={total}
        pageSize={limit}
        current={page}
        responsive
        onChange={(page, pageSize) => setPage(page)}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSizeOptions={[20, 50, 100, 200]}
      />
    </>
  );
};
export default TableComp;
