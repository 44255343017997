import React, { useEffect, useState } from "react";
import TableComp from "../components/Table/TableComp";
import PageTitle from "../components/PageTitle/PageTitle";
import { RiHotelFill } from "react-icons/ri";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getToken } from "../services/Authentication";
import moment from "moment";
import { get } from "../services/ApiRouting";
import { Button, Tooltip, Space, Avatar } from "antd";
import { useDrawerContext } from "../context/DrawerControlContext";
import { FaImage } from "react-icons/fa";

export default function Branches() {
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "Profile",
      key: "image",
      dataIndex: "image",
      width: "5%",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      width: "15%",
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
      width: "20%",
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      width: "15%",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
    },
  ];

  const { access_token } = getToken();
  const [loading, setLoading] = useState(false);
  const [branchesList, setBranchesList] = useState([]);
  const { setDrawerData } = useDrawerContext();

  const fetchData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get("/branches-list", header);

      if (resp) {
        const data = resp.data.map((valueData) => ({
          ...valueData,
          key: valueData.id,
          name: (
            <span style={{ textTransform: "capitalize" }}>
              {valueData.name}
            </span>
          ),
          image: <Avatar icon={<FaImage />} src={valueData.image} />,
          address: (
            <p>
              {valueData.address_one +
                " " +
                valueData.address_two +
                " " +
                valueData.city +
                " " +
                valueData.state +
                " " +
                valueData.pincode}
            </p>
          ),
          created_at: moment(valueData.created_at).format("DD MMM YYYY"),
          action: (
            <Space>
              <Tooltip title="Edit" placement="bottom">
                <Button
                  type="primary"
                  className="iconButtonAction edit"
                  // onClick={() => handleEdit(valueData)}
                >
                  <FaEdit />
                </Button>
              </Tooltip>

              <Tooltip title="Removed" placement="bottom">
                <Button type="primary" className="iconButtonAction remove">
                  <FaTrash />
                </Button>
              </Tooltip>
            </Space>
          ),
        }));
        setBranchesList(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleAdd = () => {
    setDrawerData({
      title: "Add Branches",
      width: 450,
      type: "AddBranches",
      isOpen: true,
    });
  };

  return (
    <div>
      <PageTitle
        buttonText="Add Branch"
        icon={<RiHotelFill />}
        link=""
        handleAdd={handleAdd}
        isButtonShow={true}
        isShwoButton={false}
      />

      <div className="container">
        <TableComp
          columns={columns}
          dataSource={branchesList}
          loading={loading}
        />
      </div>
    </div>
  );
}
