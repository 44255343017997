import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import DrawerProvider from "./context/DrawerControlContext";
import ImageResizerProvider from "./context/imageResizer";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { getProfile } from "./services/Authentication";
import { ConfigProvider, App as AntdApp } from "antd"; // Import the App component from antd

const root = ReactDOM.createRoot(document.getElementById("root"));
const { profile } = getProfile();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "#808080",
              headerColor: "#fff",
              borderColor: "#e0e0e0",
              bodySortBg: "#eee",
              rowSelectedBg: "#afafaf",
              rowSelectedHoverBg: "#eee",
              borderRadius: 0,
            },
            Popover: {
              zIndexPopup: "1000000 !important",
            },
            Tabs: {
              inkBarColor: "#000",
              itemSelectedColor: "#000",
              itemActiveColor: "#000",
              itemColor: "#808080",
              itemHoverColor: "#444",
              titleFontSize: "18px",
              verticalItemMargin: "20px",
            },
            Pagination: {
              itemActiveBg: "#afafaf",
              itemBg: "#eee",
            },
          },
        }}
      >
        <AntdApp>
          <ImageResizerProvider>
            <DrawerProvider>
              <App />
            </DrawerProvider>
          </ImageResizerProvider>
        </AntdApp>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);
