import React, { createContext, useContext, useState } from "react";
import StickyDrower from "../components/Drawer/StickyDrower";

const DrawerContext = createContext();

export const useDrawerContext = () => useContext(DrawerContext);

const DrawerProvider = ({ children }) => {
  const [drawerData, setDrawerData] = useState({
    title: "",
    type: "",
    width: 0,
    isOpen: false,
  });

  const closedDrawer = () => setDrawerData({ ...drawerData, isOpen: false });

  const contextValue = {
    drawerData,
    closedDrawer,
    setDrawerData,
  };

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
      <StickyDrower />
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
