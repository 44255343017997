import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Popconfirm,
  Select,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";
import { UploadOutlined } from "@ant-design/icons";
import AdminitiesAddModal from "../../Modal/AdminitiesAddModal";
import { del, get, post } from "../../../services/ApiRouting";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { getProfile, getToken } from "../../../services/Authentication";
import { FaTrashAlt } from "react-icons/fa";

export default function UpdateRoom() {
  const [activeDetail, setActiveDetail] = useState("detail");
  const [fileList, setFileList] = useState([]);
  const [aminitiesList, setAminitiesList] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomPrice, setRoomPrice] = useState([]);
  const [loading, setLoading] = useState(false);
  const [findData, setFindData] = useState({ type: "", search: "" });
  const [amintiesSearch, setAmintiesSearch] = useState("");
  const [form] = Form.useForm();
  const { drawerData } = useDrawerContext();
  const [roomList, setRoomList] = useState([]);
  const { profile } = getProfile();
  const { access_token } = getToken();

  const fetchRoomDetail = async (type, page, limit, search) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(
        `/room-other-detail/${type}/${profile.account_id}/${page}/${limit}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );
      if (resp) {
        if (Array.isArray(resp.data)) {
          if (type === "price") {
            setRoomPrice(resp.data);
          } else {
            setRoomData(resp.data);
          }
        } else {
          setRoomPrice([]);
          setRoomData([]);
          message.error("No data found");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      if (findData.type !== "") {
        fetchRoomDetail(findData.type, 0, 10, findData.search);
      }
    }, 500);

    return () => clearTimeout(renderTime);
    // eslint-disable-next-line
  }, [findData]);

  // ===================== Fetch Aminties ===============

  const fetchAminties = async (search) => {
    try {
      setLoading(true);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await get("/aminities-detail/" + search + "", header);

      if (resp) {
        setAminitiesList(resp.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchAminties(amintiesSearch);
    });

    return () => clearTimeout(renderTime);
  }, [amintiesSearch]);

  useEffect(() => {
    console.log(drawerData.value);
    form.setFieldsValue({
      id: drawerData.value.id,
      name: drawerData.value.room_villa_name,
      type: drawerData.value.typeName,
      price: drawerData.value.priceName,
      aminities: drawerData.value.aminities.map(
        (value) => value.aminities_name
      ),
      description: drawerData.value.room_villa_description,
      adultSpace: drawerData.value.adult_space,
      childSpace: drawerData.value.child_space,
    });
    setFileList(
      drawerData?.value.images?.length > 0
        ? drawerData?.value?.images?.map((value) => ({
            thumbUrl: value.image,
            name: value.images_desc,
            id: value.id,
          }))
        : []
    );

    setRoomList(drawerData.value.rooms);

    const renderTime = setTimeout(() => {
      setAmintiesSearch("");
      fetchRoomDetail("price", 0, 10, "");
      fetchRoomDetail("categorie", 0, 10, "");
    });

    return () => clearTimeout(renderTime);
  }, [drawerData]);

  const handleUpdateDetail = async (value) => {
    try {
      const formData = new FormData();
      const priceId = roomPrice.find(
        (valueData) => valueData.name === value.price
      )?.id;
      const typeId = roomData.find(
        (valueData) => valueData.name === value.type
      )?.id;
      formData.append("id", value.id);
      formData.append("room_villa_name", value.name);
      formData.append("room_villa_price_id", priceId);
      formData.append("room_villa_type_id", typeId);
      formData.append("priority", roomList.length);
      formData.append("adult_space", value.adultSpace);
      formData.append("child_space", value.childSpace);
      // formData.append("room_villa_child_space", value.childSpace);
      formData.append("room_villa_description", value.description);
      // formData.append("room_villa_address");
      //  formData.append('branch_id_reff', );
      formData.append("account_id", profile.account_id);
      formData.append("_method", "put");
      formData.append("rooms", JSON.stringify(roomList));


      value.aminities.forEach(function (data) {
        const amintiesId = aminitiesList.find(
          (valueData) => valueData.aminities_name === data
        )?.id;
        formData.append("aminities[]", amintiesId);
      });

      fileList.forEach(function (data) {
        formData.append("images[]", data.originFileObj);
      });

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/room-detail-update", formData, header);

      if (resp) {
        setFileList([]);
        setActiveDetail("detail");
        message.success("Updated succesfullty");
        setLoading(false);
        
      }
    } catch (error) {
      console.error(error);
      message.error("Unable to remove try again");
    }
  };

  // ================= Remove Room Image ==============

  const handleRemoveImage = async (file) => {
    if (window.confirm("Are you sure want remove ?")) {
      try {
        setLoading(true);
        const resp = await del("/room-image-remove/" + file.id);

        if (resp) {
          setLoading(false);
          message.success("Removed successfully");
        }
      } catch (error) {
        console.error(error);
        message.error("Unable to remove please try again");
      }
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleUpdateDetail}>
      <div style={{ display: activeDetail === "detail" ? "block" : "none" }}>
        <Form.Item name="id" className="d-none">
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Room Name">
          <Input className="formInput" placeholder="Room Name" />
        </Form.Item>
        <Form.Item label="Select Room Type" name="type">
          <Select
            showSearch
            placeholder="Room Type"
            loading={loading}
            onSearch={(value) =>
              setFindData({ type: "categorie", search: value })
            }
          >
            {roomData.map((value) => (
              <Select.Option value={value.name}>{value.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Price Rule" name="price">
          <Select
            showSearch
            placeholder="Price Rules"
            loading={loading}
            onSearch={(value) => setFindData({ type: "price", search: value })}
          >
            {roomPrice.map((value) => (
              <Select.Option value={value.name}>
                {value.name} - <PiCurrencyInrBold />
                {value.price}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Aminities" name="aminities">
          <Select
            loading={loading}
            placeholder="Aminities"
            mode="multiple"
            onSearch={(value) => setAmintiesSearch(value)}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <AdminitiesAddModal
                  setAminitiesList={setAminitiesList}
                  aminitiesList={aminitiesList}
                />
              </>
            )}
          >
            {aminitiesList.map((value) => (
              <Select.Option
                value={value.aminities_name}
                label={value.aminities_name}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={value.aminites_image} style={{ maxWidth: 20 }} />
                  <span style={{ marginLeft: "5px" }}>
                    {value.aminities_name}
                  </span>
                </div>{" "}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea
            placeholder="Description"
            showCount
            maxLength={500}
            rows={5}
            className="formInput"
          />
        </Form.Item>
        <div className="row">
          <div className="col-6">
            {" "}
            <Form.Item name="adultSpace" label="Adult Space">
              <Input
                type="number"
                min={0}
                defaultValue={0}
                className="formInput"
                placeholder="Adult Space"
              />
            </Form.Item>
          </div>
          <div className="col-6">
            {" "}
            <Form.Item name="childSpace" label="Child Space">
              <Input
                type="number"
                min={0}
                defaultValue={0}
                className="formInput"
                placeholder="Child Space"
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button
            loading={loading}
            onClick={() => setActiveDetail("room")}
            className="uploadButton w-100"
          >
            Next
          </Button>
        </Form.Item>
      </div>
      <div style={{ display: activeDetail === "room" ? "block" : "none" }}>
        {roomList?.length > 0 ? (
          roomList?.map((value, index) => (
            <div className="row mb-3">
              <div className="col-md-5">
                <Input
                  className="formInput w-100"
                  placeholder="Enter Romm No."
                  value={value.room_no}
                  onChange={(e) => {
                    const prevData = [...roomList];
                    prevData[index].room_no = e.target.value;
                    setRoomList(prevData);
                  }}
                />
              </div>
              <div className="col-md-5">
                <Select
                  placeholder="Condition"
                  className="w-100"
                  onChange={(value) => {
                    const prevData = [...roomList];
                    prevData[index].room_clean_status = value;
                    setRoomList(prevData);
                  }}
                  value={value.room_clean_status}
                  options={[
                    { label: "clean", value: "clean" },
                    { label: "cleaning", value: "cleaning" },
                    { label: "unclean", value: "unclean" },
                  ]}
                />
              </div>
              <div className="col-md-2">
                <div className="buttonGroup">
                  <Popconfirm
                    title="Delete the room"
                    description="Are you sure to delete this room?"
                    onConfirm={() => {
                      const prevData = [...roomList];
                      prevData.splice(index, 1);
                      setRoomList(prevData);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="cancelButton">
                      <FaTrashAlt />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Empty />
        )}
        <button
          type="button"
          onClick={() => {
            setRoomList((prev) => [
              ...prev,
              { room_no: "", room_clean_status: "" },
            ]);
          }}
          className="addNewuttonGuest mt-3"
        >
          Add More Room
        </button>

        <Form.Item>
          <div className="d-flex justify-content-between mt-5">
            <Button
              className="w-100 mx-2 bg-dark text-white"
              style={{ minHeight: "40px" }}
              onClick={() => setActiveDetail("detail")}
              loading={loading}
            >
              {" "}
              Previous
            </Button>
            <Button
              onClick={() => setActiveDetail("image")}
              className="uploadButton w-100 mx-2"
              loading={loading}
              disabled={roomList?.length <= 0}
            >
              {" "}
              Next
            </Button>
          </div>
        </Form.Item>
      </div>
      <div style={{ display: activeDetail === "image" ? "block" : "none" }}>
        <Form.Item label="Uplaod Room Images" name="images">
          <Upload
            onRemove={(file) => handleRemoveImage(file)}
            multiple
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture"
            fileList={[...fileList]}
            accept=".jpg,.png,.webp,.jpeg,.tiff"
            onChange={(value) => setFileList(value.fileList)}
          >
            <Button className="w-100" icon={<UploadOutlined />}>
              Upload Images
            </Button>
          </Upload>

          {fileList && fileList.length === 0 ? <Empty /> : ""}
        </Form.Item>
        <Form.Item>
          <div className="d-flex justify-content-between">
            <Button
              className="w-100 mx-2 bg-dark text-white"
              onClick={() => setActiveDetail("room")}
              style={{ height: "40px" }}
              loading={loading}
            >
              {" "}
              Previous
            </Button>
            <Button
              htmlType="submit"
              className="uploadButton w-100 mx-2"
              loading={loading}
            >
              {" "}
              Submit
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}
