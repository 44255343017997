import React, { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import { MdOutlineBedroomParent, MdWatchLater } from "react-icons/md";
import { useDrawerContext } from "../context/DrawerControlContext";
import TableComp from "../components/Table/TableComp";
import { TbCategoryPlus } from "react-icons/tb";
import { PiCurrencyInrBold } from "react-icons/pi";
import { del, get, post } from "../services/ApiRouting";
import {
  Avatar,
  Badge,
  Button,
  Popover,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa6";
import DiscountModal from "../components/Modal/DiscountModal/DiscountModal";
import { LuIndianRupee } from "react-icons/lu";
import { getProfile, getToken } from "../services/Authentication";
import { MdMeetingRoom } from "react-icons/md";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Summary",
    dataIndex: "summary",
    key: "summary",
  },
  {
    title: "Rooms",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Room Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  // {
  //   title: "Discount",
  //   dataIndex: "disount",
  //   key: "disount",
  // },
  {
    title: "Aminities",
    dataIndex: "aminitie",
    key: "aminitie",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export default function Rooms() {
  const { setDrawerData } = useDrawerContext();
  const [roomData, setRoomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { profile } = getProfile();
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);

  // =============== Add Room ==============
  const handleAdd = () => {
    setDrawerData({
      title: "Add Room",
      width: 400,
      type: "AddRoom",
      isOpen: true,
    });
  };

  // =================== Update Room ==============
  const handleUpdate = (value) => {
    setDrawerData({
      title: "Update Room",
      width: 400,
      type: "UpdateRoom",
      isOpen: true,
      value: value,
    });
  };

  // ============= Add Price Rules ===========
  const handleAddPriceRules = () => {
    setDrawerData({
      title: "Price Rules",
      width: 400,
      type: "PriceRules",
      isOpen: true,
    });
  };

  // ================= Add Room Type =============
  const handleAddRoomType = () => {
    setDrawerData({
      title: "Room Type",
      width: 400,
      type: "RoomType",
      isOpen: true,
    });
  };

  // ============== Dynamic Color Code genearate =============
  function generateRandomColor() {
    // Generate random values for red, green, and blue channels
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Generate a random opacity value between 0.1 and 0.5 for light opacity

    // Construct the color code with light opacity
    const colorCode = `rgba(${red}, ${green}, ${blue})`;
    return colorCode;
  }

  // =========== Fetch Rooms =============

  const { access_token } = getToken();

  const fetchRoomData = async (search, page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(
        `/rooms-detail/${profile.account_id}/${page - 1}/${limit}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );
      if (resp) {
        setTotal(resp?.total);

        const updateDate = resp.data.map((value) => ({
          ...value,
          name: <strong>{value.room_villa_name}</strong>,
          summary: value.room_villa_description,
          priority: (
            <Popover
              trigger="hover"
              content={
                <div>
                  {value.rooms.map((valueData) => (
                    <div
                      style={{
                        minWidth: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <strong className="d-flex align-items-center">
                        <MdMeetingRoom />
                        {valueData.room_no}
                      </strong>
                      <Tag
                        color={
                          valueData.room_clean_status === "clean"
                            ? "#008000"
                            : valueData.room_clean_status === "cleaning"
                            ? "#FFA500"
                            : "#FF0000"
                        }
                      >
                        {valueData.room_clean_status}
                      </Tag>
                    </div>
                  ))}
                </div>
              }
            >
              <Tag color="#000" style={{ cursor: "pointer" }}>
                {value?.priority} <FaAngleDown />
              </Tag>
            </Popover>
          ),
          type: (
            <Popover
              trigger="hover"
              content={
                <div style={{ maxWidth: 300 }}>
                  <h5>{value.typeName}</h5>
                  <p>{value.typeDescription}</p>
                </div>
              }
            >
              <Tag color={generateRandomColor()} style={{ cursor: "pointer" }}>
                {value.typeName} <FaAngleDown />
              </Tag>
            </Popover>
          ),
          aminitie: (
            <Avatar.Group
              maxCount={2}
              maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            >
              {value.aminities.map((valueAminite) => (
                <Tooltip title={valueAminite.aminities_name} placement="top">
                  <Avatar
                    src={valueAminite.aminites_image}
                    style={{ background: "#eee" }}
                  />
                </Tooltip>
              ))}
            </Avatar.Group>
          ),
          disount: (
            <DiscountModal
              value={value}
              handleAddDiscount={handleAddDiscount}
            />
          ),
          price: (
            <Popover
              style={{ maxWidth: 300 }}
              content={
                <div style={{ maxWidth: 300 }}>
                  <h5>{value.priceName}</h5>
                  <div className="row">
                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>Price</strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.price}
                    </div>
                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>Check In At</strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <MdWatchLater />
                      {value.check_in_time}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>Check In At</strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <MdWatchLater />
                      {value.check_out_time}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Extra Adult Charge
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.exctra_adult_price}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Extra Child Charge
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.extra_child_price ? value.extra_child_price : 0}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Overtime Surcharge
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.surcharge_price}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Overtime rounded an hour
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <MdWatchLater /> {value.surcharge_hour} Min
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Friday Price
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.friday_price}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Saturday Price
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.saturday_price}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Sunday Price
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.sunday_price}
                    </div>

                    <div className="col-7">
                      <strong style={{ fontSize: ".8rem" }}>
                        Holiday Price
                      </strong>
                    </div>
                    <div className="col-1">-</div>
                    <div className="col-4">
                      <LuIndianRupee />
                      {value.holiday_price}
                    </div>
                  </div>
                </div>
              }
              title=""
            >
              <span
                style={{
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <BiRupee /> {value.price}&nbsp;
                <FaAngleDown />
              </span>
            </Popover>
          ),
          action: (
            <Space size="middle">
              <Button
                type="primary"
                className="iconButtonAction edit"
                onClick={() => handleUpdate(value)}
              >
                <FaEdit />
              </Button>
              <Button
                type="primary"
                className="iconButtonAction remove"
                onClick={() => handleRemoveRoom(value.id)}
              >
                <FaTrash />
              </Button>
            </Space>
          ),
        }));
        setRoomData(updateDate);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchRoomData(searchText, page, limit);
    }, 500);

    return () => clearTimeout(renderTime);
    // eslint-disable-next-line
  }, [searchText, page, limit]);

  // ============== Remove Room ================
  const handleRemoveRoom = async (id) => {
    if (window.confirm("Are sure you want to remove ?")) {
      try {
        setLoading(true);
        const header = {
          Authorization: "Bearer " + access_token,
        };
        const resp = await del("/rooms-detail/" + id, header);

        if (resp) {
          setLoading(false);
          message.success("Removed Successfully");
        }
      } catch (error) {
        console.error(error);
        message.error("Unable to removed please try again");
      }
    }
  };

  // ================ Add Discount to room =============

  const handleAddDiscount = async (discountSelectedKey, id) => {
    try {
      if (discountSelectedKey) {
        setLoading(true);

        const formData = new FormData();
        formData.append("entity_type", "room");
        formData.append("entity_id", id);
        formData.append("discount_id", discountSelectedKey[0]);

        const resp = await post("/discount-assign", formData);

        if (resp) {
          message.success("Discount Selected succesfully");
          setLoading(false);
          // setIsModalOpen(false);
        }
      }
    } catch (error) {
      console.error(error);
      message.error("unable add discount please try again");
      setLoading(false);
    }
  };

  const handleListSearch = (text) => {
    setSearchText(text);
  };

  return (
    <div>
      <PageTitle
        title="Rooms"
        icon={<MdOutlineBedroomParent />}
        buttonText="Create room"
        handleAdd={handleAdd}
        isButtonShow={true}
        refreshData={() => fetchRoomData("", page, limit)}
        handleListSearch={handleListSearch}
        moreButton={[
          {
            name: "Room Type",
            icon: <TbCategoryPlus />,
            onclick: handleAddRoomType,
          },
          {
            name: "Price Rules",
            icon: <PiCurrencyInrBold />,
            onclick: handleAddPriceRules,
          },
        ]}
      />
      <div className="px-3">
        <TableComp
          columns={columns}
          dataSource={roomData}
          isDragble={false}
          loading={loading}
          total={total}
          page={page}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </div>
  );
}
