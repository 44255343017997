import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { IoIosAddCircle } from "react-icons/io";
import { useDrawerContext } from "../../context/DrawerControlContext";
import { getToken } from "../../services/Authentication";
import { get } from "../../services/ApiRouting";
import { Avatar, Button, Modal, Space } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import TableComp from "../../components/Table/TableComp";
import { RiLoginCircleFill } from "react-icons/ri";
import { MdHomeWork } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { render } from "@testing-library/react";
import moment from "moment";

export default function AccountList() {
  const { setDrawerData } = useDrawerContext();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const { access_token } = getToken();
  const navigate = useNavigate();

  const columns = [
    {
      title: "Profile",
      dataIndex: "logo",
      key: "logo",
      render: (image, record) => (
        <Avatar size={44} shape="square" icon={<MdHomeWork />} src={image} />
      ),
    },
    {
      title: "Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Description",
      dataIndex: "account_description",
      key: "account_description",
      render: (text, record) => <p className="paraGraph m-0">{text}</p>,
    },
    {
      title: "Phone",
      dataIndex: "account_phone",
      key: "account_phone",
    },
    {
      title: "Email",
      dataIndex: "account_email",
      key: "account_email",
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Category",
      dataIndex: "account_category",
      key: "account_category",
    },
    {
      title: "Duration",
      dataIndex: "started_at",
      key: "started_at",
      render: (text, value) =>
        value.package_start ? (
          moment(value.package_start).format("DD/MM/YYYY") +
          " To " +
          moment(value.package_end).format("DD/MM/YYYY")
        ) : (
          <span className="text-danger">Package not started yet</span>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "sttaus",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, value) => (
        <>
          <Button
            icon={<RiLoginCircleFill />}
            className="bg-success text-white"
            onClick={() =>
              navigate(
                "/?account_access=true&access_profile=" + renderData(value)
              )
            }
          >
            Access
          </Button>
          <Button
            className="bg-warning text-white"
            onClick={() => handleUpdate(value)}
            icon={<FaEdit />}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  const renderData = (value) => {
    const data = {
      ...value.user,
      "role": value.user.type,
      access: value.access,
      account: value,
    };

    return btoa(JSON.stringify(data));
  };

  const handleListSearch = () => {};
  const handleUpdate = (value) => {
    setDrawerData({
      title: "Update Account",
      width: 450,
      type: "UpdateAccount",
      isOpen: true,
      value: value,
    });
  };

  const handleAdd = () => {
    setDrawerData({
      title: "Add Account",
      width: 450,
      type: "AddAccount",
      isOpen: true,
    });
  };

  const fetchFeatures = async (page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(`/account/${page - 1}/${limit}`, header);

      if (resp) {
        setLoading(false);
        setDataSource(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      fetchFeatures(page, limit);
    }, 300);

    return () => clearTimeout(dbounce);
  }, [page, limit]);

  return (
    <>
      <PageTitle
        buttonText="Add Account"
        isButtonShow={true}
        handleListSearch={handleListSearch}
        handleAdd={handleAdd}
        icon={<IoIosAddCircle />}
        refreshData={() => fetchFeatures(page, limit)}
      />
      <TableComp
        isDragble={true}
        columns={columns}
        setDataSource={setDataSource}
        dataSource={dataSource}
        loading={loading}
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
}
