import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { getToken } from "../../../services/Authentication";
import { get, post } from "../../../services/ApiRouting";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import moment from "moment";

export default function AccountUpdateForm() {
  const [form] = Form.useForm();
  const { setDrawerData, drawerData } = useDrawerContext();
  const [active, setActive] = useState("account_access");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [dataSource, setDataSource] = useState([]);

  const fetchFeatures = async (page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(`/feature/${page - 1}/${limit}`, header);

      if (resp) {
        setLoading(false);
        setDataSource(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      fetchFeatures(page, limit);
    }, 300);

    return () => clearTimeout(dbounce);
  }, [page, limit]);

  useEffect(() => {
    const dbounce = setTimeout(() => {
      form.setFieldsValue({
        ...drawerData.value, // Spread drawerData.value to set form fields
        account_id: drawerData.value.id, // Explicitly setting account_id if it's needed
        package_start: moment(drawerData.value.package_start),
        package_end: moment(drawerData.value.package_end)
      });
    }, 300);

    return () => clearTimeout(dbounce); // Cleanup the timeout on unmount or dependency change
  }, [drawerData.value]);

  const columns = [
    { label: "", name: "account_id", col: "d-none", setp: "account_access" },
    {
      label: "Package",
      name: "package",
      col: "col-12",
      step: "account_access",
      prop: (
        <Select
          placeholder="Select Package"
          options={[{ value: "Basic Trail", label: "Basic Trial" }]}
        />
      ),
    },

    {
      label: "Status",
      name: "account_status",
      col: "col-12",
      step: "account_access",
      prop: (
        <Select
          placeholder="Select Status"
          options={[
            { value: 1, label: "Activate" },
            { value: 0, label: "Deactivate" },
          ]}
        />
      ),
    },
    {
      label: "Writet Status",
      name: "status",
      col: "col-12",
      step: "account_access",
      prop: <TextArea placeholder="Message" className="formInput" />,
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "account_access",
      prop: (
        <div className="buttonGroup d-flex justify-content-between mt-3">
          <Button className="uploadButton w-100" htmlType="submit">
            Update
          </Button>
        </div>
      ),
    },
    { label: "", name: "account_id", col: "d-none", step: "account" },
    {
      label: "Account Name",
      name: "account_name",
      col: "col-12",
      step: "account",
    },
    {
      label: "Account Description",
      name: "account_description",
      col: "col-12",
      prop: (
        <TextArea
          showCount
          maxLength={500}
          placeholder="Summary"
          className="formInput"
        />
      ),
      step: "account",
    },
    {
      label: "Account Phone",
      name: "account_phone",
      col: "col-6",
      step: "account",
    },
    {
      label: "Account Email",
      name: "account_email",
      col: "col-6",
      step: "account",
    },
    {
      label: "Account Category",
      name: "account_category",
      col: "col-12",
      prop: (
        <Select
          placeholder="Select Category"
          options={[
            { label: "Resort", value: "Resort" },
            { label: "Villa", value: "Villa" },
            { label: "Hotel", value: "Hotel" },
          ]}
        />
      ),
      step: "account",
    },
    {
      label: "Address One",
      name: "address_one",
      col: "col-12",
      step: "account",
    },
    {
      label: "Address Two",
      name: "address_two",
      step: "account",
      col: "col-12",
    },
    {
      label: "City",
      name: "city",
      col: "col-4",
      step: "account",
    },
    {
      label: "State",
      name: "state",
      col: "col-4",
      step: "account",
    },
    {
      label: "Pincode",
      name: "pincode",
      col: "col-4",
      step: "account",
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "account",
      prop: (
        <div className="buttonGroup d-flex justify-content-between mt-3">
          <Button className="uploadButton w-100" htmlType="submit">
            Update
          </Button>
        </div>
      ),
    },
    {
      label: "",
      name: "access",
      col: "col-12",
      step: "access",
      prop: (
        <Checkbox.Group
          style={{
            width: "100%",
          }}
          value={drawerData.value.access}
        >
          <Row>
            {dataSource.map((value) => (
              <Col span={12}>
                <Checkbox value={value.id}>{value.feature_title}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      ),
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "access",
      prop: <Empty />,
    },
  ];

  const onFinish = async (value) => {
    try {
      console.log(value);
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      value["_method"] = "PUT";
      value["id"] = value.account_id;
      value["package_start"] = moment(value.package_start).format("YYYY-MM-DD");
      value["package_end"] = moment(value.package_end).format("YYYY-MM-DD");
      let resp;
      if (active === "account" || active === "account_access") {
        resp = await post("/account", value, header);
      } else {
        resp = await post("/access-feature", value, header);
      }

      if (resp) {
        setLoading(false);
        form.resetFields();
        setDrawerData({
          isOpen: false,
        });
        message.success("Account updated successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable create account please try again");
    }
  };

  const onChange = (key) => {
    switch (key) {
      case "1":
        setActive("account_access");
        break;
      case "2":
        setActive("account");
        break;
      case "3":
        setActive("access");
        break;
      default:
        setActive("account_access");
        break;
    }
  };

  const items = [
    {
      key: "1",
      label: "Account Status",
    },
    {
      key: "2",
      label: "Account Detail",
    },
    // {
    //   key: "3",
    //   label: "Feature Access",
    // },
  ];

  return (
    <Spin spinning={loading}>
      <Tabs accessKey="1" items={items} onChange={onChange} />
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="row">
          {columns.map((val) => (
            <div className={`${val.col} ${val.step !== active && "d-none"}`}>
              <Form.Item label={val.label} name={val.name}>
                {val.prop ? (
                  val.prop
                ) : (
                  <Input placeholder={val.label} className="formInput" />
                )}
              </Form.Item>
            </div>
          ))}
        </div>
      </Form>
    </Spin>
  );
}
