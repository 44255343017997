import { Button, ColorPicker, Divider, Image, Input, message } from "antd";
import React, { useState } from "react";
import { getProfile, getToken } from "../../services/Authentication";
import { post } from "../../services/ApiRouting";

export default function StyleDetail() {
  const { profile } = getProfile();
  const [logoFile, setLogoFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    logo: profile.account.logo,
    profile_banner: profile.account.profile_banner,
    primary_color: profile.account.primary_color,
    secondary_color: profile.account.secondary_color,
  });

  const { access_token } = getToken();

  const handleLogo = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
    setData((prev) => ({ ...prev, logo: file && URL.createObjectURL(file) }));
  };

  const handleBanner = (e) => {
    const file = e.target.files[0];
    setBannerFile(file);
    setData((prev) => ({
      ...prev,
      profile_banner: file && URL.createObjectURL(file),
    }));
  };

  const handleSubmitForm = async () => {
    try {
      setLoading(true);
      let dataPrev = { ...profile.account }; // Copy the account object to avoid mutating the original
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        dataPrev[key] = data[key];
      });

      if (logoFile) {
        formData.append("logo", logoFile);
      }
      if (bannerFile) {
        formData.append("profile_banner", bannerFile);
      }
      formData.append("primary_color", data?.primary_color);
      formData.append("secondary_color", data?.secondary_color);
      formData.append("id", profile.account.account_style_id);
      formData.append("_method", "put");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/update-style", formData, header);

      if (resp) {
        const updatedProfile = { ...profile, account: dataPrev }; // Create a new profile object with the updated account data
        const profileString = JSON.stringify(updatedProfile);
        const findData = sessionStorage.getItem("profile");
        if (findData) {
          sessionStorage.setItem("profile", profileString);
        } else {
          localStorage.setItem("profile", profileString);
        }

        setLoading(false);
        message.success("Updated successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Sorry unable to update please try again");
    }
  };

  return (
    <div className="row">
      <div className="col-6">
        <h5>Logo</h5>
        <Image
          src={data?.logo}
          style={{ height: 150, margin: "10px 0", objectFit: "cover" }}
        />
        <Input
          type="file"
          name="logo"
          id=""
          className="formInput"
          onChange={handleLogo}
        />
      </div>
      <div className="col-6">
        <h5>Banner</h5>
        <Image
          src={data?.profile_banner}
          style={{ height: 150, objectFit: "cover", margin: "10px 0" }}
        />
        <Input
          type="file"
          name="logo"
          id=""
          className="formInput"
          onChange={handleBanner}
        />
      </div>
      <div className="col-12">
        <Divider />
      </div>
      <div className="col-6">
        <h5>Primary Colour</h5>
        <ColorPicker
          value={data?.primary_color}
          className="formInput w-100 d-flex justify-content-start px-2"
          onChange={(color) =>
            setData((prev) => ({ ...prev, primary_color: color.toHexString() }))
          }
          showText={(color) => (
            <span>Primary Colour ({color.toHexString()})</span>
          )}
        />
      </div>
      <div className="col-6">
        <h5>Secondary Colour</h5>

        <ColorPicker
          value={data?.secondary_color}
          className="formInput w-100 d-flex justify-content-start px-2"
          onChange={(color) =>
            setData((prev) => ({
              ...prev,
              secondary_color: color.toHexString(),
            }))
          }
          showText={(color) => (
            <span>Secondary Colour ({color.toHexString()})</span>
          )}
        />
      </div>

      <div className="col-12 mt-3">
        <Button
          loading={loading}
          className="uploadButton"
          onClick={handleSubmitForm}
        >
          Update Changes
        </Button>
      </div>
    </div>
  );
}
