import React, { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Image, Input, message, Upload } from "antd";
import { getProfile, getToken } from "../../services/Authentication";
import { post } from "../../services/ApiRouting";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function AccountDetail() {
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const { profile } = getProfile();
  const { access_token } = getToken();
  const [form] = Form.useForm();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleSubmitForm = async (values) => {
    try {
      setLoading(true);
      let data = profile;
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        data[key] = values[key];
        formData.append(key, values[key]);
      });

      data.image = fileList[0].thumbUrl;

      formData.append("image", fileList[0].originFileObj);
      formData.append("id", profile.id);
      formData.append("_method", "put");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/update-user", formData, header);

      if (resp) {
        const profileString = JSON.stringify(data);
        const findData = sessionStorage.getItem("profile");
        if (findData) {
          sessionStorage.setItem("profile", profileString);
        } else {
          localStorage.setItem("profile", profileString);
        }
        setLoading(false);
        message.success("Data updated successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Sorry unable to update please try again");
    }
  };

  useEffect(() => {
    form.setFieldsValue(profile);

    if (profile?.image) {
      setFileList([
        {
          thumbUrl: profile?.image,
        },
      ]);
    }

    // eslint-disable-next-line
  }, []);


  return (
    <div>
      <Form layout="vertical" onFinish={handleSubmitForm} form={form}>
        <div className="row">
          <Form.Item label="Profile Image">
            <div className="col-12">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>

              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: "none",
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
            </div>
          </Form.Item>
          <div className="col-6">
            <Form.Item label="Enter Name" name="name">
              <Input className="formInput" placeholder="Name" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Alternate Email" name="email">
              <Input className="formInput" placeholder="Email" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Username" name="username">
              <Input className="formInput" placeholder="Username" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Reset Password">
              <Button className="addButtons">Reset the Password</Button>
            </Form.Item>
          </div>
          <div className="col-12">
            <Button
              loading={loading}
              className="uploadButton"
              htmlType="submit"
            >
              Update Changes
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
