import React, { useState } from "react";
import { Badge, Button, Divider, Modal } from "antd";
import { FaAngleDown } from "react-icons/fa";
import DiscountAssignedList from "./DiscountAssignedList";
import DiscountAssignNew from "./DiscountAssignNew";

const DiscountAssignModal = ({ valueData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewComponent, setViewComponent] = useState("list");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };



  return (
    <>
      <Badge count={valueData?.list?.length} showZero overflowCount={99}>
        <Button
          onClick={showModal}
          style={{ color: "#000", borderColor: "#000" }}
          icon={<FaAngleDown />}
        >
          Applied In
        </Button>
      </Badge>
      <Modal
        width={1100}
        centered
        title={valueData?.name+" discount"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]
        }
      >
        <Divider />
        {viewComponent === "list" ? (
          <DiscountAssignedList
            value={valueData}
            setViewComponent={setViewComponent}
          />
        ) : (
          <DiscountAssignNew discountId = {valueData?.id} setViewComponent={setViewComponent} />
        )}
      </Modal>
    </>
  );
};
export default DiscountAssignModal;
