import React, { useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { useImageResizer } from "../../../context/imageResizer";
import { UploadOutlined } from "@ant-design/icons";
import { post } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";

export default function AddBranch() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [uploadFile, setUploadFile] = useState(null);
  const { resizeFile } = useImageResizer();
  const [form] = Form.useForm();
  const { profile } = getProfile();
  const { access_token } = getToken();

  const handleChange = async (info) => {
    let clearTimeData;
    setLoading(true);

    const maxWidth = 150;
    const maxHeight = 150;
    const targetFileSize = 60 * 1024;
    const imageExtension = "WEBP";

    clearTimeData = setTimeout(async () => {
      const compressedFile = await resizeFile(
        info.file.originFileObj,
        maxWidth,
        maxHeight,
        imageExtension,
        targetFileSize
      );
      setUploadFile(compressedFile);
      setImageUrl(URL.createObjectURL(compressedFile));
      setLoading(false);
    }, 500);

    return () => clearTimeout(clearTimeData);
  };

  const handleSubmitBranch = async (values) => {
    try {
      setLoading(false);
      const formData = new FormData();
      const header = {
        Authorization: "Bearer " + access_token,
      };
      let newData = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];
          formData.append(key, value);
          newData[key] = value; // Corrected: Use square brackets to set dynamic key
        }
      }
      formData.append("image", uploadFile);
      formData.append("account_id", profile.account_id);

      const resp = await post("/add-branches", formData, header);
      if (resp) {
        setLoading(false);
        message.success("Upload succesfully");
        form.resetFields();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmitBranch}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-12">
            <Form.Item name="foodImage" label="Select Branch Image">
              <div className="d-flex flex-column">
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                      maxWidth: 100,
                      borderRadius: 5,
                      marginBottom: 10,
                    }}
                  />
                )}
                <Upload
                  name="avatar"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  onChange={handleChange}
                  accept=".jpeg, .png, .jpg"
                >
                  <Button className="uploadButton" icon={<UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              label="Enter Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required !",
                },
              ]}
            >
              <Input placeholder="name" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-6">
            <Form.Item
              label="Enter Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone is required !",
                },
                {
                  max: 10,
                  message: "Enter valid phone",
                },
                {
                  min: 10,
                  message: "Enter valid phone",
                },
              ]}
            >
              <Input
                type="number"
                prefix="+91"
                placeholder="Phone"
                className="formInput"
              />
            </Form.Item>
          </div>

          <div className="col-6">
            <Form.Item
              label="Enter Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required !",
                },
                {
                  type: "email",
                  message: "Enter valid email",
                },
              ]}
            >
              <Input placeholder="Email" className="formInput" />
            </Form.Item>
          </div>
          <div className="col-13">
            <Form.Item
              extra="This will show with title of branch."
              label="Enter Location"
              name="location"
              rules={[
                {
                  required: true,
                  message: "Location is required !",
                },
              ]}
            >
              <Input placeholder="Location" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item
              label="Enter Address One"
              name="address_one"
              rules={[
                {
                  required: true,
                  message: "Address is required",
                },
                { max: 200, message: "Max length 200 characters" },
              ]}
            >
              <Input
                showCount
                max={200}
                placeholder="Address One"
                className="formInput"
              />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item
              label="Enter Address Two"
              name="address_two"
              rules={[{ max: 200, message: "Max length 200 characters" }]}
            >
              <Input
                showCount
                max={200}
                placeholder="Address Two"
                className="formInput"
              />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item
              label="Enter City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
            >
              <Input placeholder="City" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-6">
            <Form.Item
              label="Enter State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "State is required",
                },
              ]}
            >
              <Input placeholder="State" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-6">
            <Form.Item
              label="Enter Pincode"
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "Pincode is required",
                },
              ]}
            >
              <Input placeholder="Pincode" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                className="addButtons"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
