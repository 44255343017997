import "./App.css";
import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LayoutProvider from "./context/LayoutContext";
// import MenuList from "./pages/menu/MenuList";
import CategorieList from "./pages/Menu/CategorieList";
import Rooms from "./pages/Rooms";
import Menu from "./pages/Menu/menu";
import Discounts from "./pages/Discounts";
import FoodList from "./pages/Menu/FoodList";
import Booking from "./pages/Booking";
import Login from "./pages/Login";
import "./css/login.css";
import Branches from "./pages/Branches";
import { useSelector } from "react-redux";
import Accounts from "./pages/Accounts";
import Profile from "./pages/Profile";
import UpdateFood from "./components/Forms/FoodForm/UpdateFood";
import AddFood from "./components/Forms/FoodForm/AddFood";
import EnquiryList from "./pages/EnquiryList";
import Users from "./pages/Users";
import ForgotPassword from "./pages/ForgotPassword";
import AccountList from "./pages/Admin/AccountList";
import { getAccessProfile, getProfile } from "./services/Authentication";
import AccountFeatures from "./pages/Admin/AccountFeatures";
import brandLogo from "./assets/logo-removebg-preview.png";
import Locations from "./pages/Admin/Locations";
import Enquiries from "./pages/Admin/Enquiries";


function App() {
  const { token } = useSelector((state) => state.auth);
  const { profile } = getProfile();
  const { access_profile } = getAccessProfile();

  return (
    <BrowserRouter>
      <img className="brandLogo" src={brandLogo} />
      <Routes>
        <Route
          path="/"
          element={
            token ? (
              <LayoutProvider>
                <Dashboard />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/categories"
          element={
            token ? (
              <LayoutProvider title="Categories">
                <CategorieList />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/foods"
          element={
            token ? (
              <LayoutProvider title="Foods">
                <FoodList />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/rooms"
          element={
            token ? (
              <LayoutProvider title="Rooms">
                <Rooms />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/discount-list"
          element={
            token ? (
              <LayoutProvider title="Discounts">
                <Discounts />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/bookings"
          element={
            token ? (
              <LayoutProvider title="Bookings">
                <Booking />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/branches"
          element={
            token ? (
              <LayoutProvider title="Branches">
                <Branches />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/add-food"
          element={
            token ? <AddFood /> : <Navigate to={window.location.pathname} />
          }
        />

        <Route
          path="/update-food/:slug"
          element={token ? <UpdateFood /> : <Navigate to="/login" />}
        />

        <Route
          path="/accounts"
          element={
            token ? (
              <LayoutProvider title="Accounts">
                <Accounts />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/profile"
          element={
            token ? (
              <LayoutProvider title="Profile">
                <Profile />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/dashboard"
          element={
            token ? (
              <LayoutProvider title="Dashboard">
                <Dashboard />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/users"
          element={
            token ? (
              <LayoutProvider title="Users">
                <Users />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/enquiry"
          element={
            token ? (
              <LayoutProvider title="Enquiry List">
                <EnquiryList />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* ============= Non Heander and Fodter ========== */}
        <Route path="/menu" element={<Menu />} />
        <Route
          path="/login"
          element={
            !token ? (
              <Login />
            ) : (
              <Navigate
                to={profile?.role === "SuperAdmin" ? "/accounts-list" : "/"}
              />
            )
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* ============================ Admin Route ===================== */}

        <Route
          path="/accounts-list"
          element={
            token ? (
              <LayoutProvider title="Accounts List">
                <AccountList />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/locations"
          element={
            token ? (
              <LayoutProvider title="Locations">
                <Locations />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/enquiries"
          element={
            token ? (
              <LayoutProvider title="Enquiries">
                <Enquiries />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route
          path="/accounts-features"
          element={
            token ? (
              <LayoutProvider title="Accounts Features">
                <AccountFeatures />
              </LayoutProvider>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
