import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { getProfile, getToken } from "../../services/Authentication";
import { post } from "../../services/ApiRouting";

export default function ProfileDetail() {
  const [form] = Form.useForm();
  const { profile } = getProfile();
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();

  useEffect(() => {
    form.setFieldsValue(profile.account);
    // eslint-disable-next-line
  }, [profile]);

  const handleSubmitForm = async (values) => {
    try {
      setLoading(true);
      let data = { ...profile.account }; // Copy the account object to avoid mutating the original
      const formData = new FormData();
      // Update the data object with values submitted by the form
      Object.keys(values).forEach((key) => {
        data[key] = values[key];
        formData.append(key, values[key]);
      });

      formData.append("id", profile.account_id);
      formData.append("_method", "put");

      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await post("/account", formData, header);

      if (resp) {
        const updatedProfile = { ...profile, account: data }; // Create a new profile object with the updated account data
        const profileString = JSON.stringify(updatedProfile);
        const findData = sessionStorage.getItem("profile");
        if (findData) {
          sessionStorage.setItem("profile", profileString);
        } else {
          localStorage.setItem("profile", profileString);
        }
        setLoading(false);
        message.success("Data updated successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Sorry unable to update please try again");
    }
  };

  return (
    <div>
      <Form
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          <div className="col-12">
            <Form.Item name="account_name">
              <Input className="formInput" placeholder="Business Name" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item name="account_phone">
              <Input className="formInput" placeholder="Phone" prefix="+91" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item name="account_email">
              <Input className="formInput" placeholder="Email" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item name="account_description">
              <TextArea
                className="formInput"
                placeholder="Description"
                showCount
                maxLength={500}
                rows={3}
              />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item name="address_one">
              <Input
                className="formInput"
                placeholder="Address One"
                maxLength={200}
                showCount
              />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item name="address_two">
              <Input
                className="formInput"
                placeholder="Address Two"
                maxLength={200}
                showCount
              />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name="city">
              <Input className="formInput" placeholder="City" />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name="state">
              <Input className="formInput" placeholder="State" />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name="pincode">
              <Input className="formInput" placeholder="Pincode" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Button
              loading={loading}
              htmlType="submit"
              className="uploadButton"
            >
              Update Changes
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
