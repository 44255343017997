import { Col, Row, Skeleton, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Sticky, StickyContainer } from "react-sticky";
import { Input } from "antd";
import { get } from "../../services/ApiRouting";
import { PiCurrencyInrBold } from "react-icons/pi";
import "../../css/food.css";
import ViewFoodModal from "../../components/Modal/FoodModalaComponent/ViewFoodModal";
import Cart from "../../components/Modal/cart";
import { getToken } from "../../services/Authentication";

function Menu() {
  const [selectedTab, setSelectTab] = useState("appetizers");
  const [headerHeight, setHeaderHeight] = useState(null);
  const [FoodList, setFoodList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cart, setCart] = useState([]);
  const {access_token} = getToken();

  const fetchFoodWithCateg = async () => {
    setLoader(true);
    const header = {
      Authorization: "Bearer " + access_token,
    };
    const resp = await get("/food-with-categ",header);
    setFoodList(resp.data);
    setLoader(false);
  };

  useEffect(() => {
    fetchFoodWithCateg();
  }, []);

  const handleChange = (key) => {
    setSelectTab(key);

    const targetSection = document.getElementById(key);

    if (targetSection) {
      const elementTop =
        targetSection.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = elementTop - headerHeight;

      // Scroll to the target section using smooth scrolling
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      console.log("not found");
    }
  };

  useEffect(() => {
    const divElement = document.getElementById("menuList"); // Replace 'yourDivId' with the actual ID of your div element

    if (divElement) {
      const divHeight = divElement.offsetHeight;
      setHeaderHeight(divHeight + 80);
    }
  }, []);

  return (
    <>
      <StickyContainer>
        <Sticky topOffset={80}>
          {({ style }) => (
            <div style={style} className="menu-list" id="menuList">
              <div className="container mb-3 ">
                <div className="row">
                  <div className="headTitle maintext col-md-8 col-12">
                    <div className="textflex  ">
                      <div className="flex">
                        <img
                          src="https://www.nowjakarta.co.id/wp-content/uploads/2023/06/f3edd462-8908-4c9c-bdd5-24988ec9fcdd-1-min-1-edited.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="textflex">
                      <h2 className="fontsizeone">Resturant Image</h2>
                      <p className="fontsizepera" style={{ margin: 0 }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Perferendis doloribus magni modi eligendi.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2 col-6">
                    <Input
                      placeholder="Search for food...  "
                      size="large"
                      style={{ border: "0.5px solid black" }}
                      prefix={<SearchOutlined style={{ marginRight: 8 }} />}
                      //   onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  <div className="leftPanel textflex col-md-2 col-6 d-flex justify-content-end">
                   <Cart cart={cart} setCart={setCart} />
                  </div>
                </div>
              </div>
              <div className="container">
                <Tabs
                  defaultActiveKey={selectedTab}
                  tabPosition="top"
                  onTabClick={handleChange}
                  items={
                    !loader &&
                    FoodList &&
                    FoodList.map((value, i) => {
                      return {
                        label: (
                          <span
                            className="menuItem"
                            style={{ textTransform: "capitalize" }}
                          >
                            {value.name}
                          </span>
                        ),
                        key: value.slug,
                        children: null,
                      };
                    })
                  }
                />
              </div>
            </div>
          )}
        </Sticky>

        {loader && (
          <div className="container mb-5">
            <div className="row">
              {[...Array(12)].map((_, index) => (
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-3">
                  <div className="food-card-container">
                    <Row>
                      <Col span={8}>
                        <Skeleton.Image active={true} />
                      </Col>
                      <Col span={2}></Col>
                      <Col span={12}>
                        <Skeleton.Input active={true} size="default" />
                        <br />
                        <br />
                        <Skeleton.Avatar
                          active={true}
                          size={"default"}
                          shape={"square"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Skeleton.Button
                          active={true}
                          size={"default"}
                          shape={"square"}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Existing code... */}
        {FoodList.length > 0 &&
          FoodList.map((categValue) => (
            <div
              className="food-section"
              id={categValue.slug}
              key={categValue.slug}
            >
              <div className="container ">
                <h4 style={{ textTransform: "capitalize" }}>
                  {categValue.name}
                </h4>
                <div className="foodList justify-content-center">
                  <div className="row">
                    {categValue.foods.map((value, i) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-12"
                        key={i}
                      >
                        <div className="food-card-container">
                          <div className="food-card-image">
                            <img src={value.image} alt="" />
                          </div>
                          <div className="food-card-text">
                            <h3>{value.name}</h3>
                            <p>{value.description}</p>
                            <div className="food-card-button mb-3">
                              <h6 className="w-100 d-flex align-items-center justify-content-between">
                                <span>
                                  <PiCurrencyInrBold />
                                  &nbsp;{value.detail[0].price}
                                </span>
                                <span>{value.detail[0].quantity}</span>
                              </h6>

                              <ViewFoodModal value={value} cart={cart} setCart={setCart} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </StickyContainer>
    </>
  );
}

export default Menu;
