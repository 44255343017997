// PieChart.js
import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RoomBooks = () => {
  const data = {
    labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"], // Example labels for days
    datasets: [
      {
        label: "Room A",
        data: [30, 50, 60, 80, 70, 90, 100], // Example data for Room A bookings
        fill: false,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
      },
      {
        label: "Room B",
        data: [20, 40, 50, 70, 60, 80, 90], // Example data for Room B bookings
        fill: false,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        tension: 0.1,
      },
      {
        label: "Room C",
        data: [10, 30, 40, 60, 50, 70, 80], // Example data for Room C bookings
        fill: false,
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Comparative Bookings for Different Rooms",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="dashboardCard mt-4">
      <div className="dashboardHeader d-flex align-items-baseline justify-content-between">
        <div className="leftPanel">
          <h5>Most Booked Room's</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
        </div>
        {/* <div className="rightPanel">
    <Button icon={<CiExport />}>Export</Button>
  </div> */}
      </div>
      <div className="dashboardBody">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default RoomBooks;
