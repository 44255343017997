import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { TbCategoryPlus } from "react-icons/tb";
import TableComp from "../../components/Table/TableComp";
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { useDrawerContext } from "../../context/DrawerControlContext";
import { get, del, post } from "../../services/ApiRouting";
import moment from "moment";
import { Avatar, Button, Tooltip, message } from "antd";
import { FaRegImage } from "react-icons/fa";
import DiscountModal from "../../components/Modal/DiscountModal/DiscountModal";
import { getProfile, getToken } from "../../services/Authentication";

const columns = [
  {
    key: "sort",
    dataIndex: "sort",
    width: "2%",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "10%",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "30%",
  },
  // {
  //   title: "Discount",
  //   dataIndex: "discount",
  //   key: "discount",
  //   width: "10%",
  // },
  {
    title: "Total Food",
    dataIndex: "total_food",
    key: "total_food",
    width: "15%",
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    width: "15%",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export default function CategorieList() {
  const { setDrawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { access_token } = getToken();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const { profile } = getProfile();

  const fetchFoodData = async (search, page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const result = await get(
        `/food-and-categ-list/main/${profile.account_id}/${page - 1}/${limit}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );

      setTotal(result?.total);
      const convertedData = result.data.map((value, index) => ({
        ...value,
        key: index + 1,
        description:value.description !== "undefined" ? <p>{value.description}</p> : "-",
        created_at: moment(value.created_at).format("DD MMM YYYY"),
        total_food: (
          <Avatar.Group
            maxCount={4}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {value.foods.map((valueAminite) => (
              <Tooltip title={valueAminite.name} placement="top">
                <Avatar
                  src={valueAminite.image}
                  icon={<FaRegImage style={{ color: "#808080" }} />}
                  style={{ background: "#eee" }}
                />
              </Tooltip>
            ))}
          </Avatar.Group>
        ),
        discount: (
          <DiscountModal value={value} handleAddDiscount={handleAddDiscount} />
        ),
        action: (
          <div className="d-flex">
            {/* <FoodListModal categoryName={value.name} foodList={value.foods} id={value.id} /> */}
            <Tooltip title="Edit" placement="bottom">
              <Button
                className="iconButtonAction edit"
                onClick={() => handleUpdate(value)}
              >
                <FaEdit />
              </Button>
            </Tooltip>
            <Tooltip title="Remove" placement="bottom">
              <Button
                className="iconButtonAction remove"
                onClick={() => handleRemove(index, value.id)}
              >
                <FaTrash />
              </Button>
            </Tooltip>
          </div>
        ),
      }));
      setDataSource(convertedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const bounseTime = setTimeout(() => {
      fetchFoodData(searchText, page, limit);
    }, 500);

    return () => clearTimeout(bounseTime);
    // eslint-disable-next-line
  }, [searchText, page, limit]);

  const handleAdd = () => {
    setDrawerData({
      title: "Add Categorie",
      width: 500,
      type: "categAdd",
      isOpen: true,
    });
  };

  const handleUpdate = (value) => {
    setDrawerData({
      title: "Update Categorie",
      width: 400,
      type: "categUpdate",
      isOpen: true,
      data: value,
    });
  };

  const handleRemove = async (index, id) => {
    if (window.confirm("Are sure you want to remove ?")) {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const result = await del(`/food-and-categ-list/${id}`, header);
      if (result) {
        fetchFoodData("", page, limit);
      }

      setLoading(false);
    }
  };

  const handleAddDiscount = async (discountSelectedKey, id) => {
    try {
      if (discountSelectedKey) {
        setLoading(true);
        const header = {
          Authorization: "Bearer " + access_token,
        };

        const formData = new FormData();
        formData.append("entity_type", "foodCategory");
        formData.append("entity_id", id);
        formData.append("discount_id", discountSelectedKey[0]);

        const resp = await post("/discount-assign", formData, header);

        if (resp) {
          message.success("Discount Selected succesfully");
          setLoading(false);
          // setIsModalOpen(false);
        }
      }
    } catch (error) {
      console.error(error);
      message.error("unable add discount please try again");
      setLoading(false);
    }
  };

  const handleListSearch = (text) => {
    setSearchText(text);
  };

  return (
    <div>
      <PageTitle
        title="Food Categories"
        buttonText="Create Category"
        handleAdd={handleAdd}
        isButtonShow={true}
        icon={<TbCategoryPlus />}
        refreshData={() => fetchFoodData("", page, limit)}
        handleListSearch={handleListSearch}
      />
      <div className="px-4">
        <TableComp
          isDragble={true}
          columns={columns}
          setDataSource={setDataSource}
          dataSource={dataSource}
          loading={loading}
          total={total}
          page={page}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </div>
  );
}
