import { Button, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { FaAddressBook } from "react-icons/fa6";
import { get } from "../../services/ApiRouting";
import { getToken } from "../../services/Authentication";

export default function Count() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { access_token } = getToken();

  const getCountData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get("/today-report", header);

      if (resp) {
        setLoading(false);
        setData(resp);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  console.log(data);

  useEffect(() => {
    const renderTime = setTimeout(() => {
      getCountData();
    }, 300);

    return () => clearTimeout(renderTime);
  }, []);

  return (
    <div className="dashboardCard">
      <div className="dashboardHeader d-flex align-items-baseline justify-content-between">
        <div className="leftPanel">
          <h5>Today's Reports</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
        </div>
        <div className="rightPanel">
          <Button icon={<CiExport />}>Export</Button>
        </div>
      </div>
      <div className="dashboardBody">
        <div className="row">
          <div className="col-md-3">
            <div className="dashboardCounter red">
              <div className="icon">
                <FaAddressBook />
              </div>
              {loading ? (
                <Skeleton.Button active />
              ) : (
                <div className="count">100 +</div>
              )}
              <div className="title">Total Booking</div>
              {loading ? (
                <Skeleton.Input active size="small" />
              ) : (
                <p>+8% from yesterday</p>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardCounter purpule">
              <div className="icon">
                <FaAddressBook />
              </div>
              <div className="count">100 +</div>
              <div className="title">Total Users</div>
              <p>+8% from yesterday</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardCounter yellow">
              <div className="icon">
                <FaAddressBook />
              </div>
              <div className="count">100+</div>
              <div className="title">Room Booked's</div>
              <p>+8% from yesterday</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardCounter green">
              <div className="icon">
                <FaAddressBook />
              </div>
              <div className="count">100 +</div>
              <div className="title">Total Enquiry</div>
              <p>+8% from yesterday</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
