import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { IoIosAddCircle } from "react-icons/io";
import { useDrawerContext } from "../../context/DrawerControlContext";
import TableComp from "../../components/Table/TableComp";
import { getToken } from "../../services/Authentication";
import { del, get } from "../../services/ApiRouting";
import moment from "moment";
import { Button, message, Space } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";

const columns = [
  { title: "Title", dataIndex: "feature_title", key: "feature_title" },
  { title: "Name", dataIndex: "feature_name", key: "feature_name" },
  { title: "Updated At", dataIndex: "updated_at", key: "updated_at" },
  { title: "Action", dataIndex: "action", key: "action" },
];

export default function AccountFeatures() {
  const { setDrawerData } = useDrawerContext();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const { access_token } = getToken();

  const handleListSearch = async (id) => {
   
  };

  const handleAdd = () => {
    setDrawerData({
      title: "Add Feature",
      width: 450,
      type: "AddFeature",
      isOpen: true,
    });
  };

  const handleUpdate = (value) => {
    setDrawerData({
      title: "Update Feature",
      width: 450,
      type: "UpdateFeatures",
      isOpen: true,
      value: value,
    });
  };

  const handleRemove = async(id) => {
    if (window.confirm("Are you sure you want to remove this ?")) {
        try {
          const header = {
            Authorization: "Bearer " + access_token,
          };
  
          const resp = await del(`/feature/${id}`, header);
  
          if (resp) {
            message.success(resp.message);
            setDrawerData({
              isOpen: false,
            });
          }
        } catch (error) {
          console.log(error);
          message.error(error?.response?.data?.message);
        }
      }
  };

  const fetchFeatures = async (page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(`/feature/${page - 1}/${limit}`, header);

      if (resp) {
        setLoading(false);
        const data = resp.data.map((value) => ({
          ...value,
          updated_at: moment(value.updated_at).format("llll"),
          action: (
            <Space size="middle">
              <Button
                type="primary"
                className="iconButtonAction edit"
                onClick={() => handleUpdate(value)}
              >
                <FaEdit />
              </Button>
              <Button
                type="primary"
                className="iconButtonAction remove"
                onClick={() => handleRemove(value.id)}
              >
                <FaTrash />
              </Button>
            </Space>
          ),
        }));
        setDataSource(data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      fetchFeatures(page, limit);
    }, 300);

    return () => clearTimeout(dbounce);
  }, [page, limit]);

  return (
    <>
      <PageTitle
        buttonText="Add Feature"
        isButtonShow={true}
        handleListSearch={handleListSearch}
        handleAdd={handleAdd}
        icon={<IoIosAddCircle />}
        refreshData={() => fetchFeatures(page, limit)}
      />

      <TableComp
        isDragble={true}
        columns={columns}
        setDataSource={setDataSource}
        dataSource={dataSource}
        loading={loading}
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
}
