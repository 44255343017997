import { Button, Tabs } from "antd";
import React from "react";
import { IoCalendarSharp } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { FaBuildingCircleCheck } from "react-icons/fa6";
import { MdOutlineAddCircle } from "react-icons/md";
import Calender from "../components/Bookings/Calender/Calender";
import { useDrawerContext } from "../context/DrawerControlContext";
import CheckInDetail from "../components/Bookings/CheckInDetail/CheckInDetail";
import SearchDetail from "../components/Bookings/Search/SearchDetail";
import { MdBedroomParent } from "react-icons/md";
import { getProfile } from "../services/Authentication";

export default function Booking() {
  const { setDrawerData } = useDrawerContext();
  const { profile } = getProfile();

  const handleCreateBooking = () => {
    setDrawerData({
      title: "Create Booking",
      width: 450,
      type: "CreateBooking",
      isOpen: true,
      room: [],
      date: [],
    });
  };

  const handleRoomDetail = () => {
    setDrawerData({
      title: "Room Booking Details",
      width: 450,
      type: "RoomDetail",
      isOpen: true,
      room: [],
      date: [],
    });
  };

  return (
    <div className="container my-3">
      <Tabs
        tabBarExtraContent={{
          right: (
            <div className="buttonsGroup">
              {profile?.access?.make_booking && (
                <Button
                  className="addButtons mx-2"
                  onClick={handleCreateBooking}
                  icon={<MdOutlineAddCircle />}
                >
                  Make Booking
                </Button>
              )}
              <Button
                className="addButtons"
                onClick={handleRoomDetail}
                icon={<MdBedroomParent />}
              >
                Rooms Detail
              </Button>
            </div>
          ),
        }}
        items={[
          {
            label: "Calendar", // Corrected typo
            icon: <IoCalendarSharp />,
            key: "1",
            children: <Calender />,
          },
          profile?.access?.check_in && {
            label: "Check In",
            icon: <FaBuildingCircleCheck />,
            key: "2",
            children: <CheckInDetail />,
          },
          {
            label: "Search",
            icon: <FiSearch />,
            key: "3",
            children: <SearchDetail />,
          },
        ]}
      />
    </div>
  );
}
