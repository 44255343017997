import { Button, Form, Input, List, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareThreads } from "react-icons/fa6";
import { IoIosAdd } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { post, del } from "../../services/ApiRouting";
import { getProfile, getToken } from "../../services/Authentication";

export default function SocialMedia() {
  const { profile } = getProfile();
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [form] = Form.useForm();
  const [dataInput, setDataInput] = useState({ name: "", url: "" });

  useEffect(() => {
    setSocialData(profile?.account?.social);
  }, []);

  const socialList = [
    {
      label: "Facebook",
      value: "facebook",
      icon: <FaFacebook />,
    },
    {
      label: "Instagram",
      value: "instagram",
      icon: <FaSquareInstagram />,
    },
    {
      label: "Twitter",
      value: "twitter",
      icon: <FaSquareXTwitter />,
    },
    {
      label: "You Tube",
      value: "youtube",
      icon: <FaYoutube />,
    },
    {
      label: "What's App",
      value: "whatsapp",
      icon: <FaWhatsappSquare />,
    },
    {
      label: "LinkedIn",
      value: "linkedin",
      icon: <FaLinkedin />,
    },
    {
      label: "Threads",
      value: "threads",
      icon: <FaSquareThreads />,
    },
  ];

  const handleSubmit = async (value) => {
    setLoading(true); // Set loading state at the start
    try {
      const formData = new FormData();
      formData.append("name", value.social);
      formData.append("url", value.url);
      formData.append("account_id", profile.account_id);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      // Assume post is a function that sends a POST request
      const resp = await post("/add-social-media", formData, header);

      if (resp && resp.id) {
        // Ensure resp and resp.id are defined
        const data = {
          id: resp.id,
          name: value.social,
          url: value.url,
          account_id: profile.account_id,
        };

        // Safely parse the social property, handling undefined or invalid JSON
        let previousData = socialData;

        if (Array.isArray(previousData)) {
          previousData.push(data);
        } else {
          previousData = [data];
        }

        setSocialData(previousData);
        profile.account.social = previousData;
        const findData = sessionStorage.getItem("profile");
        const profileString = JSON.stringify(profile);
        if (findData) {
          sessionStorage.setItem("profile", profileString);
        } else {
          localStorage.setItem("profile", profileString);
        }
        form.resetFields();
      } else {
        console.warn("Response does not contain a valid ID.");
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    } finally {
      setLoading(false); // Ensure loading state is reset in all cases
    }
  };

  const handleRemove = async (id, index) => {
    try {
      if (window.confirm("Are sure you want to remove ?")) {
        setLoading(true);
        const header = {
          Authorization: "Bearer " + access_token,
        };

        const resp = await del("/delete-social-media/" + id, header);

        if (resp) {
          message.success("Removed successfully");
          setLoading(false);

          // Make a copy of the socialData array to avoid mutating the original state
          const updatedData = [...socialData];
          // Remove the item at the specified index
          updatedData.splice(index, 1);

          // Update the state with the modified array
          setSocialData(updatedData);

          // Update the profile object with the new social data
          profile.account.social = updatedData;

          // Check if profile data exists in sessionStorage
          const findData = sessionStorage.getItem("profile");
          const profileString = JSON.stringify(profile);

          // Update the stored profile data in the appropriate storage
          if (findData) {
            sessionStorage.setItem("profile", profileString);
          } else {
            localStorage.setItem("profile", profileString);
          }
        }
      }
    } catch (error) {
      console.error(error);
      message.error("Unable removed please try again !");
      setLoading(false);
    }
  };

  const handleUpdate = async (id, index) => {
    try {
      setLoading(true);
      const value = socialData.find((value) => value.id === id);
      const formData = new FormData();

      formData.append("id", id);
      formData.append("name", value.name);
      formData.append("url", value.url);
      formData.append("_method", "put");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/update-social-media", formData, header);

      if (resp) {
        message.success("updated successfully");
        setLoading(false);
        const findData = sessionStorage.getItem("profile");
        profile.account.social = socialData;
        const profileString = JSON.stringify(profile);

        // Update the stored profile data in the appropriate storage
        if (findData) {
          sessionStorage.setItem("profile", profileString);
        } else {
          localStorage.setItem("profile", profileString);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Sorry unable to update !");
    }
  };

  return (
    <div>
      <div className="my-4">
        <List
          loading={loading}
          dataSource={socialData}
          renderItem={(item, index) => (
            <List.Item>
              <div className="socialCard w-100">
                <div className="row">
                  <div className="col-md-2 d-flex align-items-start justify-content-center">
                    <span className={item.name + " socialIcon"}>
                      {
                        socialList?.find((value) => value.value == item.name)
                          ?.icon
                      }
                    </span>
                  </div>
                  <div className="col-md-8">
                    <Input
                      onChange={(e) => {
                        const prevData = [...socialData];
                        prevData[index].url = e.target.value;
                        setSocialData(prevData);
                      }}
                      style={{ padding: "0 10px" }}
                      placeholder="URL"
                      value={item.url}
                      className="formInput w-100"
                    />
                  </div>
                  <div className="col-md-2">
                    <Button className="updateButton" onClick={()=>handleUpdate(item.id, index)}>
                      <MdEdit />
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      htmlType="submit"
                      className="cancelButton"
                      onClick={() => handleRemove(item.id, index)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <div className="row">
          <div className="col-md-3">
            <Form.Item
              label="Select social media"
              name="social"
              rules={[{ required: true, message: "Social media is required" }]}
            >
              <Select placeholder="Social Meida List">
                {socialList
                  .filter(
                    (valueData) =>
                      !socialData.some(
                        (valueCheck) => valueCheck.name === valueData.value
                      )
                  )
                  .map((filteredValue) => (
                    <Select.Option
                      key={filteredValue.value}
                      value={filteredValue.value}
                      label={filteredValue.label}
                    >
                      {filteredValue.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Enter URL"
              name="url"
              rules={[
                { required: true, message: "Url is required" },
                { type: "url" },
              ]}
            >
              <Input className="formInput" placeholder="URL" />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label=" ">
              <Button
                loading={loading}
                htmlType="submit"
                className="addButtons"
                icon={<IoIosAdd />}
              >
                Add New
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
