import React, { useEffect, useState } from "react";
import { getProfile, getToken } from "../../services/Authentication";
import PageTitle from "../../components/PageTitle/PageTitle";
import TableComp from "../../components/Table/TableComp";
import { Avatar, Button } from "antd";
import { IoLocation } from "react-icons/io5";
import { get } from "../../services/ApiRouting";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { useDrawerContext } from "../../context/DrawerControlContext";
import { render } from "@testing-library/react";
import moment from "moment";
import { IoIosAddCircle } from "react-icons/io";



export default function Locations() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);
  const { access_token } = getToken();
  const { setDrawerData } = useDrawerContext();

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, render) => (
        <Avatar shape="square" size={54} src={text} icon={<IoLocation />} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, val) => moment(text).format("llll"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, val) => (
        <>
          <Button
            onClick={()=>handleUpdate(val)}
            className="bg-warning text-white"
            icon={<FaEdit />}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  const fetchFeatures = async (page, limit, search) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(
        `/location/${page - 1}/${limit}${search !== "" ? "/" + search : ""}`,
        header
      );

      if (resp) {
        setLoading(false);
        setDataSource(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      fetchFeatures(page, limit, searchText);
    }, 300);

    return () => clearTimeout(dbounce);
  }, [page, limit, searchText]);

  const handleListSearch = (val) => {
    setSearchText(val);
  };

  const handleAdd = () => {
    setDrawerData({
      title: "Add Location",
      width: 450,
      type: "AddLocation",
      isOpen: true,
    });
  };

  const handleUpdate = (value) => {
    setDrawerData({
      title: "Update Location",
      width: 450,
      type: "UpdateLocation",
      isOpen: true,
      value:value
    });
  };

  return (
    <div>
      <PageTitle
        buttonText="Add Location"
        isButtonShow={true}
        handleListSearch={handleListSearch}
        handleAdd={handleAdd}
        icon={<IoIosAddCircle />}
        refreshData={() => fetchFeatures(page, limit, searchText)}
      />

      <div className="px-3">
        <TableComp
          columns={columns}
          dataSource={dataSource}
          isDragble={false}
          loading={loading}
          total={total}
          page={page}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </div>
  );
}
