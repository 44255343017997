import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { MdBedroomParent } from "react-icons/md";
import { FaBroomBall } from "react-icons/fa6";
import AboutRoom from "../../RoomDetail/AboutRoom";
import CleanRoom from "../../RoomDetail/CleanRoom";
import { getProfile, getToken } from "../../../services/Authentication";
import { get } from "../../../services/ApiRouting";

export default function RoomDetail() {
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [rooms, setRooms] = useState([]);
  const { profile } = getProfile();

  const fetchRoomData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(
        `/room-detail-booking/${profile.account_id}`,
        header
      );
      if (resp) {
        setLoading(false);
        setRooms(resp.data);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchRoomData();
    }, 300);

    return () => clearTimeout(renderTime);
  }, []);

  const items = [
    {
      key: "1",
      label: "Room Status",
      children: (
        <AboutRoom loading={loading} rooms={rooms} setRooms={setRooms} />
      ),
      icon: <MdBedroomParent />,
    },
    profile?.access?.check_in && {
      key: "2",
      label: "Clean Status",
      children: (
        <CleanRoom loading={loading} rooms={rooms} setRooms={setRooms} />
      ),
      icon: <FaBroomBall />,
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
