import React, { useEffect, useState } from "react";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { message, Spin } from "antd";
import { get } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";

export default function BookingDetail() {
  const { drawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const { profile } = getProfile();
  const { access_token } = getToken();

  const handleFetchDetail = async (booking_id) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await get(
        `/booking/0/1/${profile.account_id}/${booking_id}`,
        header
      );

      if (resp) {
        setLoading(false);
        setFetchedData(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable to find data please try again !");
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      handleFetchDetail(drawerData?.value?.booking_id);
    }, 300);

    return () => clearTimeout(renderTime);
  }, [drawerData]);

  return <Spin spinning={loading}></Spin>;
}
