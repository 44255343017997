import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FaUserCircle } from "react-icons/fa";
import { FaPlus, FaMinus } from "react-icons/fa6";

export default function GuestDetailModal({
  modalDetail,
  setModalDetail,
  handleAddGuest,
  handleUpdateGuest,
  setFile,
}) {
  const [fileList, setFileList] = useState([]);
  const [ageInput, setAgeInput] = useState(modalDetail?.value?.age || 0);
  const [nameInput, setNameInput] = useState("");
  const [form] = Form.useForm();

  const handleCancel = () => {
    setModalDetail((prev) => ({ ...prev, isOpen: false }));
  };

  const handleSubmit = () => {
    form.submit();
    setModalDetail((prev) => ({ ...prev, isOpen: false }));
  };

  useEffect(() => {
    if (modalDetail.title === "Update") {
      form.setFieldsValue(modalDetail.value);
      setNameInput(modalDetail.value.name)
      setAgeInput(modalDetail.value.age ? modalDetail.value.age : 0);
      if (modalDetail.value.file) {
        setFileList([modalDetail.value.file]);
      } else {
        setFileList([]);
      }
    } else {
      setAgeInput(0);
      form.resetFields();
      setFileList([]);
    }
  }, [modalDetail]);

  useEffect(() => {
    // Set the form fields dynamically based on the input values
    form.setFieldsValue({
      name: nameInput,
      age: ageInput,
    });
  }, [nameInput, ageInput, form]);

  return (
    <Modal
      title={`${modalDetail.title} Details`}
      open={modalDetail.isOpen}
      onCancel={handleCancel}
      centered
      footer={[
        <Button
          key="cancel"
          className="bg-danger text-white"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          className="bg-success text-white"
          onClick={handleSubmit}
        >
          {modalDetail.title}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) =>
          modalDetail.title !== "Update"
            ? handleAddGuest({ ...values, age: ageInput, name: nameInput })
            : handleUpdateGuest({ ...values, age: ageInput, name: nameInput })
        }
      >
        <Form.Item
          label="Enter Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input
            prefix={<FaUserCircle />}
            className="formInput"
            placeholder="Full Name"
            onInput={(e) => setNameInput(e.target.value)}
          />
        </Form.Item>

        <div className="row">
          <div className="col-md-6">
            <div className="inputAge d-flex align-items-center justify-content-between">
              <Button
                onClick={() => setAgeInput((prev) => Math.max(prev - 1, 0))}
                style={{ height: "40px", background: "#000", color: "#fff" }}
              >
                <FaMinus />
              </Button>
              <Form.Item
                label="Enter Age"
                name="age"
                rules={[{ required: true, message: "Age is required" }]}
              >
                <Input
                  style={{ width: "100px", textAlign: "center" }}
                  type="number"
                  min={0}
                  readOnly
                  onInput={(e) => setAgeInput(e.target.value)}
                  className="formInput"
                />
              </Form.Item>

              <Button
                onClick={() => setAgeInput((prev) => prev + 1)}
                style={{ height: "40px", background: "#000", color: "#fff" }}
              >
                <FaPlus />
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <Form.Item label="Upload Document">
              <Upload
                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                listType="picture"
                fileList={fileList}
                onChange={({ file, fileList }) => {
                  setFileList(fileList);
                  setFile(file);
                }}
              >
                {fileList.length >= 1 ? null : (
                  <Button
                    className="addButtons w-100"
                    icon={<UploadOutlined />}
                  >
                    Upload Document
                  </Button>
                )}
              </Upload>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
