import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  List,
  Segmented,
  TimePicker,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MdWatchLater } from "react-icons/md";
// import { MdSunny } from "react-icons/md";
import { LuIndianRupee } from "react-icons/lu";
import { FaTrashAlt } from "react-icons/fa";
import { del, get, post } from "../../../services/ApiRouting";
import moment from "moment";
import { getProfile, getToken } from "../../../services/Authentication";

export default function PriceRules() {
  const [selectedTab, setSelectedTab] = useState("Price List");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkBoxRefs = useRef();
  const [checkedId, setCheckedId] = useState([]);
  const [form] = Form.useForm();
  const { profile } = getProfile();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const { access_token } = getToken();

  const fetchPriceDetail = async (type, page, limit, search) => {
    setLoading(true);

    const header = {
      Authorization: "Bearer " + access_token,
    };
    const resp = await get(
      `/room-other-detail/${type}/${profile.account_id}/${page}/${limit}${
        search !== "" ? "/" + search : ""
      }`,
      header
    );
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchPriceDetail("price", page, limit, searchText);
    }, 300);

    return () => clearTimeout(renderTime);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      let newData = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];

          if (key === "check_in_time" || key === "check_out_time") {
            formData.append(key, moment(new Date(value)).format("HH:mm:ss")); // Append key-value pair to formData
          } else {
            formData.append(key, value);
          } // Append key-value pair to formData

          newData[key] = value;
        }
      }
      formData.append("type", "price");
      formData.append("account_id", profile.account_id);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await post("/room-other-detail/", formData, header);
      if (resp) {
        const prevData = data;
        prevData.push(newData);
        setData([...prevData]);
        form.resetFields();
        message.success("Upload succesfully");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      message.error(error.data.message);
    }
  };

  // Function to handle "Check All" checkbox change
  const handleCheckAllChange = (e) => {
    let data = [];
    if (e.target.checked) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        if (checkbox.value != "on") {
          data.push(parseInt(checkbox.value));
        }
      });
      setCheckedId([...data]);
    } else {
      setCheckedId([]);
    }
  };

  const handleSingleChecked = (id) => {
    const prevData = checkedId;
    const index = prevData.findIndex((value) => value === id);
    if (index !== -1) {
      prevData.splice(index, 1);
    } else {
      prevData.push(id);
    }
    setCheckedId([...prevData]);
  };

  const handleRemoveCheckID = async () => {
    if (
      window.confirm(
        "Are sure want to remove because it will effect to your room list ?"
      )
    ) {
      try {
        const stringJson = JSON.stringify(checkedId);
        setLoading(true);
        const header = {
          Authorization: "Bearer " + access_token,
        };
        const resp = await del(
          "/room-other-detail-remove/" + stringJson,
          header
        );

        if (resp.message) {
          setLoading(false);
          message.success("Removed Successfully");
          fetchPriceDetail("price");
        }
      } catch (error) {
        console.error(error);
        message.error("Unable remove please try again");
      }
    }
  };

  return (
    <div>
      <Segmented
        defaultValue={selectedTab}
        style={{
          padding: 5,
          background: "#808080",
          color: "#fff",
        }}
        onChange={(value) => setSelectedTab(value)}
        options={["Price List", "Add Price"]}
      />
      <Divider />
      {selectedTab === "Price List" ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <Checkbox onChange={handleCheckAllChange}>Select All</Checkbox>
            <Button
              onClick={handleRemoveCheckID}
              danger
              type="primary"
              disabled={checkedId.length === 0}
            >
              <FaTrashAlt />
              &nbsp;Removed
            </Button>
          </div>
          <Divider />
          <List
            className="priceList"
            loading={loading}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <details style={{ width: "100%" }}>
                  <summary style={{ width: "100%" }}>
                    <List.Item.Meta
                      avatar={
                        <Checkbox
                          id="checkDataId"
                          name={"id" + index}
                          value={item.id}
                          ref={checkBoxRefs}
                          checked={checkedId.some((value) => value == item.id)}
                          onChange={() => handleSingleChecked(item.id)}
                        />
                        // eslint-disable-next-line
                      }
                      title={<strong>{item.name}</strong>}
                      description={
                        <div className="d-flex justify-content-between">
                          <span>
                            <MdWatchLater />
                            {moment(
                              "2024-03-23 " + item.check_in_time,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("hh:mm A")}
                            -
                            {moment(
                              "2024-03-23 " + item.check_out_time,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("hh:mm A")}
                          </span>
                          <span>
                            <LuIndianRupee />
                            {item.price}
                          </span>
                        </div>
                      }
                    />
                  </summary>
                  <div>
                    <div className="row">
                      <div className="col-7">
                        <strong>Extra Adult Charge</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.exctra_adult_price}
                      </div>

                      <div className="col-7">
                        <strong>Extra Child Charge</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.extra_child_price ? item.extra_child_price : 0}
                      </div>

                      <div className="col-7">
                        <strong>Overtime Surcharge</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.surcharge_price}
                      </div>

                      <div className="col-7">
                        <strong>Overtime rounded an hour</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <MdWatchLater /> {item.surcharge_hour} Min
                      </div>

                      <div className="col-7">
                        <strong>Friday Price</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.friday_price}
                      </div>

                      <div className="col-7">
                        <strong>Saturday Price</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.saturday_price}
                      </div>

                      <div className="col-7">
                        <strong>Sunday Price</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.sunday_price}
                      </div>

                      <div className="col-7">
                        <strong>Holiday Price</strong>
                      </div>
                      <div className="col-1">-</div>
                      <div className="col-4">
                        <LuIndianRupee />
                        {item.holiday_price}
                      </div>
                    </div>
                  </div>
                </details>
              </List.Item>
            )}
          />
        </>
      ) : (
        <Form layout="vertical" onFinish={handleSubmit} ref={form}>
          <Form.Item label="Rule Name" name="name">
            <Input placeholder="Rule Name" className="formInput" />
          </Form.Item>
          <div className="row">
            <div className="col-12">
              {/* <h6>
                <MdSunny style={{ color: "orange" }} />
                &nbsp;&nbsp;Rent by day
              </h6> */}
            </div>
            <div className="col-12">
              <Form.Item label="Price" name="price">
                <Input placeholder="Price" className="formInput" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Extra adult price" name="exctra_adult_price">
                <Input placeholder="Extra adult price" className="formInput" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Extra child price" name="extra_child_price">
                <Input placeholder="Extra child price" className="formInput" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Check in time" name="check_in_time">
                <TimePicker
                  use12Hours
                  format="hh:mm a"
                  style={{ width: "100%" }}
                  className="formInput"
                  placeholder="Check in time"
                />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Check out time" name="check_out_time">
                <TimePicker
                  use12Hours
                  format="hh:mm a"
                  style={{ width: "100%" }}
                  className="formInput"
                  placeholder="Check out time"
                />
              </Form.Item>
            </div>
          </div>
          <h6>
            <LuIndianRupee style={{ color: "blue" }} />
            &nbsp;&nbsp;Surcharge
          </h6>
          <div className="row">
            <div className="col-6">
              <Form.Item label="Overtime surcharge" name="surcharge_price">
                <Input
                  suffix=" / Hour"
                  placeholder="Overtime surcharge"
                  className="formInput"
                />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Rounded hour" name="surcharge_hour">
                <Input
                  suffix=" / Min"
                  placeholder="Rounded minutes to a hour"
                  className="formInput"
                />
              </Form.Item>
            </div>
          </div>

          <h6>
            <LuIndianRupee style={{ color: "blue" }} />
            &nbsp;&nbsp;Weekend's and Holiday price
          </h6>
          <div className="row">
            <div className="col-6">
              <Form.Item label="Friday price" name="friday_price">
                <Input placeholder="Friday price" className="formInput" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Saturday Price" name="saturday_price">
                <Input placeholder="Saturday Price" className="formInput" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Sunday Price" name="sunday_price">
                <Input placeholder="Sunday Price" className="formInput" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item label="Holiday Price" name="holiday_price">
                <Input placeholder="Holiday Price" className="formInput" />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button
              className="uploadButton w-100"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
