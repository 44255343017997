import { Button, DatePicker, Form, Input, Select, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { BiSolidDiscount } from "react-icons/bi";
import { post } from "../../../services/ApiRouting";
import { PiCurrencyInrBold } from "react-icons/pi";
import { getToken } from "../../../services/Authentication";

const { RangePicker } = DatePicker;

export default function AddDiscount() {
  const [form] = Form.useForm();
  const [isSelected, setIsSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const {access_token} = getToken();

  const generateCoupon = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let couponCode = "";
    const length = 8; // Change the length as needed

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      couponCode += characters[randomIndex];
    }

    form.setFieldsValue({
      coupon_code: couponCode,
    });
  };

  // =============== Create Discount ============

  const handleSubmitDiscount = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      let newData = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];
          if (key === "time") {
            formData.append(
              "start_at",
              moment(new Date(value[0])).format("YYYY-MM-DD HH:mm:ss")
            );
            formData.append(
              "end_at",
              moment(new Date(value[1])).format("YYYY-MM-DD HH:mm:ss")
            );

            newData.start_at = moment(new Date(new Date(value[0]))).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            newData.end_at = moment(new Date(new Date(value[1]))).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else {
            formData.append(key, value);
            newData[key] = value;
          } // Corrected: Use square brackets to set dynamic key
        }
      }

      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await post("/discount-detail", formData,header);

      if (resp) {
        setLoading(false);

        message.success("Addedd successfully");
        form.resetFields();
      }
    } catch (error) {
      setLoading(false);

      message.error("Sorry failed to create please try again");
      console.error(error);
    }
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        // ref={formRef}
        onFinish={handleSubmitDiscount}
      >
        <div className="row">
          <div className="col-12">
            <Form.Item
              label="Enter Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Discount name is required !",
                },
              ]}
            >
              <Input placeholder="name" className="formInput" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              label="Select Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Discount type is required !",
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  setIsSelected(value);
                  form.setFieldsValue({
                    coupon_code: "",
                  });
                }}
                placeholder="Select type"
                options={[
                  {
                    label: "Discount",
                    value: "discount",
                  },
                  {
                    label: "Coupon Code",
                    value: "coupon",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <Form.Item label=" ">
                  <Button
                    disabled={isSelected !== "coupon"}
                    type="primary"
                    style={{
                      background: "rgb(0,0,0)",
                      color: "#fff",
                      height: "40px",
                    }}
                    icon={<BiSolidDiscount />}
                    onClick={generateCoupon}
                  >
                    Generate
                  </Button>
                </Form.Item>
              </div>
              <div className="col-8">
                <Form.Item label="Coupon Code" name="coupon_code">
                  <Input
                    className="formInput"
                    placeholder="coupone code"
                    readOnly
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <Form.Item
                  label="Select"
                  name="amount_rate_type"
                  rules={[
                    {
                      required: true,
                      message: "Amount type is required !",
                    },
                  ]}
                >
                  <Select
                    placeholder="Discount type"
                    options={[
                      {
                        label: (
                          <>
                            <PiCurrencyInrBold /> Amt
                          </>
                        ),
                        value: "amount",
                      },
                      {
                        label: "% Prcnt",
                        value: "percent",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-8">
                <Form.Item label="Enter Amount / Rate" name="amount_rate">
                  <Input
                    className="formInput"
                    placeholder="Amount / Rate"
                    rules={[
                      {
                        required: true,
                        message: "Amount / Rate is required !",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Form.Item
              label="Discount Time Period"
              name="time"
              rules={[
                {
                  required: true,
                  message: "Time period is required ?",
                },
              ]}
            >
              <RangePicker
                allowClear
                use12Hours
                style={{ width: "100%" }}
                showTime
                className="formInput"
              />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              label="Select Discount Status"
              name="active_status"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select Status"
                options={[
                  { label: "Active", value: "active" },
                  { label: "Pending", value: "pending" },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                className="uploadButton"
              >
                Upload Discount
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
