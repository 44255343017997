import { Button, message } from "antd";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MdDragIndicator } from "react-icons/md";

export default function ComboFoodData({
  comboOptionFoodList,
  setComboOptionFoodList,
  setSelectedCategory,
  selectedCategory,
}) {
  const [isInRange, setIsInRange] = useState(false);

  const handleSetCategoryData = (e, value) => {
    if (comboOptionFoodList.length > 0) {
      setSelectedCategory(value);
    }
    e.stopPropagation(); // Prevents the event from propagating to the parent div
  };

  const removeCategory = (e, index, id) => {
    const prevData = comboOptionFoodList;
    prevData.splice(index, 1);
    setComboOptionFoodList([...prevData]);
    setSelectedCategory((prev) => (prev && prev.id === id ? null : prev));
    e.stopPropagation(); // Prevents the event from propagating to the parent div
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    let data = event.dataTransfer.getData("categories");
    if (data) {
      data = JSON.parse(data);
      data.option_select = "0";
      data.is_required = "0";

      setSelectedCategory(data);
      const prevData = comboOptionFoodList;
      prevData.push(data);
      setComboOptionFoodList([...prevData]);
    } else {
      message.error("Please drop categories only here...");
    }
    setIsInRange(false);

  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsInRange(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setIsInRange(false);
  };
  return (
    <>
      {comboOptionFoodList.length > 0 ? (
        <div
          className="comboFoodList px-2 position-relative"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "rgb(0,0,0,.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                opacity:isInRange? 1: 0,
                visibility:isInRange?"visible":"hidden",
                transition:"0.3s ease-in-out"
              }}
            >
              <h2>Drop Here..</h2>
            </div>
          <div className="row">
            {comboOptionFoodList.map((value, i) => (
              <div className="col-md-6 mb-4" key={i}>
                <div
                  className={`ComboCard d-flex align-items-basline justify-content-between ${
                    selectedCategory &&
                    selectedCategory.id === value.id &&
                    "active"
                  }`}
                  style={{
                    boxShadow: "0 0 3px #a1a1a1",
                    padding: "10px",
                    borderRadius: "3px",
                  }}
                  onClick={(e) => handleSetCategoryData(e, value)}
                  key={i}
                >
                  <div className="detailCombo d-flex align-items-basline">
                    <MdDragIndicator
                      style={{
                        margin: "5px 5px 0 0",
                        fontSize: "1.1rem",
                        cursor: "move",
                      }}
                    />
                    <div className="content">
                      <h5>{value.name}</h5>
                      <p className="m-0">{value.description}</p>
                    </div>
                  </div>
                  <div className="bittonDetail">
                    <Button
                      style={{ zIndex: 1000 }}
                      onClick={(e) => removeCategory(e, i, value.id)}
                      className="cancelButtonWithIcon"
                    >
                      <FaTrash />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="dropDesign mt-3"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img
            src="https://img.freepik.com/premium-vector/menu-vector-icon-white-background_855620-559.jpg"
            alt=""
          />
          <h5>Drag and drop combo food</h5>
          <p>
            Create your own menu by draging food and category card from right
            panel and drop here to generate your menu list
          </p>
        </div>
      )}
    </>
  );
}
