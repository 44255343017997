import React, { useState } from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import TableComp from "../components/Table/TableComp";
import { getProfile } from "../services/Authentication";

const columns = [
  {
    title: "Profile",
    dataIndex: "profile",
    key: "profile",
    width:"5%"
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width:"20%"
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width:"15%"

  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width:"20%"
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    width:"10%"
  },
  {
    title: "Bookings",
    dataIndex: "bookings",
    key: "bookings",
    width:"10%"
  },
  {
    title: "Arrived Date",
    dataIndex: "created_at",
    key: "created_at",
    width:"10%"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export default function Users() {
  const [roomData, setRoomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { profile } = getProfile();
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);

  return (
    <div>
      <PageTitle
        title="Users"
        icon=""
        buttonText=""
        handleAdd={null}
        isButtonShow={null}
        isShwoButton={true}
        refreshData={null}
        handleListSearch={null}
       
      />
      <div className="px-3">
        <TableComp
          columns={columns}
          dataSource={roomData}
          isDragble={false}
          loading={loading}
          total={total}
          page={page}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </div>
  );
}
