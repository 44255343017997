import React from "react";
import TableComp from "../../Table/TableComp";

export default function SearchTable({
  dataSource,
  total,
  page,
  limit,
  loading,
  setPage,
  setLimit
}) {
  const columns = [
    { title: "Name", dataIndex: "name", key: "name̦" },
    { title: "Check In", dataIndex: "check_in", key: "check_in" },
    { title: "Check Out", dataIndex: "check_out", key: "check_out" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Rooms", dataIndex: "rooms", key: "rooms" },
    { title: "Guest", dataIndex: "guest", key: "guest" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Booked At", dataIndex: "created_at", key: "created_at" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];


  return (
    <div className="my-4">
      <TableComp
        columns={columns}
        page={page}
        limit={limit}
        total={total}
        dataSource={dataSource}
        loading={loading}
        setLimit={setLimit}
        setPage={setPage}
      />
    </div>
  );
}
