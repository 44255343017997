import React, { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import { BiSolidDiscount } from "react-icons/bi";
import { useDrawerContext } from "../context/DrawerControlContext";
import { Badge, Button, Space, Tag, Tooltip } from "antd";
import { get } from "../services/ApiRouting";
import moment from "moment";
import { PiCurrencyInrBold } from "react-icons/pi";
import { FaAngleDown, FaEdit, FaTrash } from "react-icons/fa";
import DiscountAssignModal from "../components/Modal/DiscountAssignModal/DiscountAssignModal";
import { getToken } from "../services/Authentication";
import TableComp from "../components/Table/TableComp";

export default function Discounts() {
  const { setDrawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const [discountList, setDiscountList] = useState([]);

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Coupon Code",
      key: "coupon",
      dataIndex: "coupon",
    },
    {
      title: "Amount / Rate",
      key: "amountRate",
      dataIndex: "amountRate",
    },
    {
      title: "Status",
      key: "active_status",
      dataIndex: "active_status",
    },
    {
      title: "Start At",
      key: "start",
      dataIndex: "start",
    },
    {
      title: "End At",
      key: "end",
      dataIndex: "end",
    },
    {
      title: "Assigned To",
      key: "assignTo",
      dataIndex: "assignTo",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
    },
  ];

  const handleAdd = () => {
    setDrawerData({
      title: "Add Discount",
      width: 400,
      type: "AddDiscount",
      isOpen: true,
    });
  };

  const handleEdit = (value) => {
    setDrawerData({
      title: "Edit Discount",
      width: 400,
      type: "EditDiscount",
      isOpen: true,
      value: value,
    });
  };

  const {access_token} = getToken();

  const fetchData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization:"Bearer "+access_token,
      }
      const resp = await get("/discount-detail",header);

      if (resp) {
        const data = resp.data.map((valueData) => ({
          ...valueData,
          key: valueData.id,
          name: (
            <span style={{ textTransform: "capitalize" }}>
              {valueData.name}
            </span>
          ),
          type: (
            <span style={{ textTransform: "capitalize" }}>
              {valueData.type}
            </span>
          ),
          coupon: valueData.coupon_code ? valueData.coupon_code : "-",
          active_status: (
            <Tag className={`discountStatus ${valueData.active_status}`}>
              {valueData.active_status.toUpperCase()}
            </Tag>
          ),
          amountRate:
            valueData.amount_rate_type === "amount" ? (
              <div>
                <PiCurrencyInrBold /> <span>{valueData.amount_rate}</span>
              </div>
            ) : (
              <div>
                <span>{valueData.amount_rate}</span>&nbsp;%
              </div>
            ),
          assignTo:
            valueData.type === "coupon" ? (
              <Badge count={0} showZero overflowCount={99}>
                <Button
                  style={{ color: "#000", borderColor: "#000" }}
                  icon={<FaAngleDown />}
                >
                  Used In
                </Button>
              </Badge>
            ) : (
              <DiscountAssignModal valueData={valueData} />
            ),
          start: valueData.start_at
            ? moment(valueData.start_at).format("DD MMM YYYY")
            : "-",
          end: valueData.end_at
            ? moment(valueData.end_at).format("DD MMM YYYY")
            : "-",
          created: moment(valueData.created_at).format("DD MMM YYYY"),
          action: (
            <Space>
              <Tooltip title="Edit Discount" placement="bottom">
                <Button
                  type="primary"
                  className="iconButtonAction edit"
                  onClick={() => handleEdit(valueData)}
                >
                  <FaEdit />
                </Button>
              </Tooltip>

              <Tooltip title="Removed Discount" placement="bottom">
                <Button type="primary" className="iconButtonAction remove">
                  <FaTrash />
                </Button>
              </Tooltip>
            </Space>
          ),
        }));
        setDiscountList(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <PageTitle
        title="Discount List"
        buttonText="Add Discount"
        link=""
        icon={<BiSolidDiscount />}
        isButtonShow={true}
        handleAdd={handleAdd}
        isShwoButton={false}
      />
      <div className="container">
        <TableComp loading={loading} columns={columns} dataSource={discountList} />
      </div>
    </div>
  );
}
