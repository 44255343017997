import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BookingChart() {
  const getPrevious7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(moment(date).format("DD MMM"));
    }
    return dates;
  };

  const data = {
    labels: getPrevious7Days(),
    datasets: [
      {
        label: 'Online Bookings',
        data: [120, 150, 170, 200, 190, 220, 250], // Example data for online bookings
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
      {
        label: 'Offline Bookings',
        data: [80, 90, 100, 110, 130, 120, 140], // Example data for offline bookings
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Online vs Offline Bookings for the Previous 7 Days',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="dashboardCard mt-4">
      <div className="dashboardHeader d-flex align-items-baseline justify-content-between">
        <div className="leftPanel">
          <h5>Total Revenue</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
        </div>
        {/* <div className="rightPanel">
        <Button icon={<CiExport />}>Export</Button>
      </div> */}
      </div>
      <div className="dashboardBody">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}
