import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { getToken } from "../../../services/Authentication";
import { get, post } from "../../../services/ApiRouting";
import { useDrawerContext } from "../../../context/DrawerControlContext";

export default function AccountAddForm() {
  const { form } = Form.useForm();
  const { setDrawerData } = useDrawerContext();
  const [active, setActive] = useState("account");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [dataSource, setDataSource] = useState([]);

  const fetchFeatures = async (page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(`/feature/${page - 1}/${limit}`, header);

      if (resp) {
        setLoading(false);
        setDataSource(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounce = setTimeout(() => {
      fetchFeatures(page, limit);
    }, 300);

    return () => clearTimeout(dbounce);
  }, [page, limit]);

  const columns = [
    {
      label: "Account Name",
      name: "account_name",
      col: "col-12",
      step: "account",
    },
    {
      label: "Account Description",
      name: "account_description",
      col: "col-12",
      prop: (
        <TextArea
          showCount
          maxLength={500}
          placeholder="Summary"
          className="formInput"
        />
      ),
      step: "account",
    },
    {
      label: "Account Phone",
      name: "account_phone",
      col: "col-6",
      step: "account",
    },
    {
      label: "Account Email",
      name: "account_email",
      col: "col-6",
      step: "account",
    },
    {
      label: "Account Category",
      name: "account_category",
      col: "col-12",
      prop: (
        <Select
          placeholder="Select Category"
          options={[
            { label: "Resort", value: "Resort" },
            { label: "Villa", value: "Villa" },
            { label: "Hotel", value: "Hotel" },
          ]}
        />
      ),
      step: "account",
    },
    {
      label: "Address One",
      name: "address_one",
      col: "col-12",
      step: "account",
    },
    {
      label: "Address Two",
      name: "address_two",
      step: "account",
      col: "col-12",
    },
    {
      label: "City",
      name: "city",
      col: "col-4",
      step: "account",
    },
    {
      label: "State",
      name: "state",
      col: "col-4",
      step: "account",
    },
    {
      label: "Pincode",
      name: "pincode",
      col: "col-4",
      step: "account",
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "account",
      prop: (
        <Button
          className="addButtons w-100 mt-4"
          onClick={() => setActive("login")}
        >
          Next
        </Button>
      ),
    },
    { label: "Name", name: "name", col: "col-12", step: "login" },
    { label: "Phone", name: "phone", col: "col-6", step: "login" },
    { label: "Email", name: "email", col: "col-6", step: "login" },
    { label: "Username", name: "username", col: "col-12", step: "login" },
    { label: "Password", name: "password", col: "col-6", step: "login" },
    {
      label: "Confirm Password",
      name: "confirm_password",
      col: "col-6",
      step: "login",
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "login",
      prop: (
        <div className="buttonGroup d-flex justify-content-between mt-3">
          <Button
            className="addButtons bg-danger"
            onClick={() => setActive("account")}
          >
            Back
          </Button>
          <Button className="addButtons" onClick={() => setActive("access")}>
            Next
          </Button>
        </div>
      ),
    },
    {
      label: "",
      name: "account_access",
      col: "col-12",
      step: "access",
      prop: (
        <Checkbox.Group
          style={{
            width: "100%",
          }}
        >
          <Row>
            {dataSource.map((value) => (
              <Col span={12}>
                <Checkbox value={value.id}>{value.feature_title}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      ),
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "access",
      prop: (
        <div className="buttonGroup d-flex justify-content-between mt-3">
          <Button
            className="addButtons bg-danger"
            onClick={() => setActive("login")}
          >
            Back
          </Button>
          <Button className="uploadButton" htmlType="submit">
            Submit
          </Button>
        </div>
      ),
    },
  ];

  const onFinish = async (value) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await post("/account", value, header);

      if (resp) {
        setLoading(false);
        setDrawerData({
          isOpen: false,
        });
        message.success("Account created successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable create account please try again");
    }
  };

  return (
    <Spin spinning={loading}>
      <h5>
        {active === "account"
          ? `Account Detail`
          : active === "login"
          ? "Login Detail"
          : "Account Access"}
      </h5>
      <Divider />
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="row">
          {columns.map((val) => (
            <div className={`${val.col} ${val.step !== active && "d-none"}`}>
              <Form.Item label={val.label} name={val.name}>
                {val.prop ? (
                  val.prop
                ) : (
                  <Input placeholder={val.label} className="formInput" />
                )}
              </Form.Item>
            </div>
          ))}
        </div>
      </Form>
    </Spin>
  );
}
