import { Button, Input, Select, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { get, post } from "../../../services/ApiRouting";
import { getToken } from "../../../services/Authentication";

export default function DiscountAssignNew({ discountId, setViewComponent }) {
  const [selectedList, setSelectedList] = useState("room");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Discount",
      dataIndex: "discountData",
      key: "discountData",
    },

    {
      title: "Category",
      dataIndex: "typeName",
      key: "typeName",
    },
  ];

  const {access_token} = getToken();

  const fetchDiscountAssignList = async (selectedList) => {
    try {
      setLoading(true);
      let url;
      switch (selectedList) {
        case "room":
          url = "/rooms-detail-discount";
          break;
        case "roomCategory":
          url = "/room-other-detail-discount";
          break;
        case "food":
          url = "/food-details-discount";
          break;
        case "foodCategory":
          url = "/food-categ-discount";
          break;
        default:
          url = "/rooms-detail-discount";
          break;
      }
      const header = {
        Authorization:"Bearer "+access_token,
      }
      const resp = await get(url,header);

      if (resp) {
        const data = resp.data.map((value) => ({
          ...value,
          key: value.id,
          type: (
            <Tag className={selectedList}>
              {selectedList.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()}
            </Tag>
          ),
          discountData:
            value.discount.length > 0 ? value?.discount[0]?.name : "-",
        }));
        setDataSource(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDiscountAssignList(selectedList);
    // eslint-disable-next-line
  }, [selectedList]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.discount.length > 0,
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleAssignDiscount = async () => {
    setLoading(true);
    const formData = new FormData();

    selectedRowKeys.forEach((element) => {
      formData.append("entity_id[]", element);
    });
    formData.append("entity_type", selectedList);
    formData.append("discount_id", discountId);

    const resp = await post("/discount-assign-all", formData);

    if (resp) {
      setLoading(false);
      message.success("Addedd succesfully");
      fetchDiscountAssignList(selectedList);
    }
  };

  return (
    <div>
      <div className="headerPanel mb-4 d-flex justify-content-between align-items-center">
        <div className="leftSide d-flex align-items-center justify-content-start w-100">
          <Select
            className="selectInput"
            placeholder="Applying to ?"
            style={{ marginRight: "20px", width: "150px" }}
            onChange={(value) => setSelectedList(value)}
            value={selectedList}
            options={[
              {
                label: "Rooms",
                value: "room",
              },
              {
                label: "Room Types",
                value: "roomCategory",
              },
              { label: "Foods", value: "food" },
              { label: "Food Types", value: "foodCategory" },
            ]}
          />
          <Input
            prefix={<FiSearch />}
            className="searchInput"
            placeholder="Search here.."
          />
        </div>
        <Button
          onClick={() => setViewComponent("list")}
          icon={<IoMdAddCircle />}
          style={{ height: "40px", background: "green", color: "#fff" }}
          type="primery"
        >
          View List
        </Button>
      </div>

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
      />

      <div className="w-100 text-right d-flex align-items-center justify-content-end mt-3">
        <Button
          type="primary"
          style={{ color: "#fff", background: "green" }}
          onClick={handleAssignDiscount}
        >
          Apply to All
        </Button>
      </div>
    </div>
  );
}
