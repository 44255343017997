import {
  Button,
  Form,
  DatePicker,
  List,
  Checkbox,
  Divider,
  message,
  Popover,
  InputNumber,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { MdCurrencyRupee, MdWatchLater } from "react-icons/md";
import { get } from "../../../services/ApiRouting";
import moment from "moment/moment";
import { LuIndianRupee } from "react-icons/lu";
import { BiRupee } from "react-icons/bi";
import { getProfile, getToken } from "../../../services/Authentication";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { useDrawerContext } from "../../../context/DrawerControlContext";

const { RangePicker } = DatePicker;

export default function CheckAvailable({
  setBookingSteps,
  rooms,
  setRooms,
  selectedRoomsList,
  setSelectedRoomsList,
  selectedDate,
  setSelectedDate,
  selectedRooms,
  setSelectedRooms,
  handleFetchRoomList
}) {
  const [loading, setLoading] = useState(false);
  const checkBoxRefs = useRef();

  const handleSingleChecked = (id, item) => {
    const prevData = selectedRooms;
    const prevList = selectedRoomsList;

    const index = prevData.findIndex((value) => value === id);
    if (index !== -1) {
      prevData.splice(index, 1);
      prevList.splice(index, 1);
    } else {
      prevData.push(id);
      item["quantity"] = 1;
      prevList.push(item);
    }

    setSelectedRoomsList([...prevList]);
    setSelectedRooms([...prevData]);
  };


  const handleQuamtity = (id, qty) => {
    const index = selectedRoomsList.findIndex(value => value.id === id);
    const previousData = selectedRoomsList;
    previousData[index].quantity = qty;

    setSelectedRoomsList([...previousData]);
  };

  return (
    <div>
      <>
        <Form layout="vertical" autoComplete="off">
          <Form.Item label="Select Date">
            <RangePicker
              className="formInput w-100"
              format="DD MMM YYYY"
              placeholder={["Check In", "Check Out"]}
              onChange={handleFetchRoomList}
              value={selectedDate}
            />
          </Form.Item>
          <Divider orientation="left">Rooms</Divider>
          <List
            className="priceList"
            loading={loading}
            itemLayout="horizontal"
            dataSource={rooms}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  style={{ marginTop: "10px" }}
                  avatar={
                    <Checkbox
                      id="checkDataId"
                      name={"id" + index}
                      value={item.id}
                      ref={checkBoxRefs}
                      checked={selectedRooms.some((value) => value == item.id)}
                      onChange={() => handleSingleChecked(item.id, item)}
                    />
                    // eslint-disable-next-line
                  }
                  title={
                    <div className="d-flex align-items-center justify-content-between">
                      <strong
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSingleChecked(item.id, item)}
                      >
                        {item.room_villa_name}
                      </strong>
                      <span
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <Popover
                          style={{ maxWidth: 300 }}
                          content={
                            <div style={{ maxWidth: 300 }}>
                              <h5>{item.priceName}</h5>
                              <div className="row">
                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Price
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.price}
                                </div>
                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Check In At
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <MdWatchLater />
                                  {item.check_in_time}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Check Out At
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <MdWatchLater />
                                  {item.check_out_time}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Extra Adult Charge
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.exctra_adult_price}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Extra Child Charge
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.extra_child_price
                                    ? item.extra_child_price
                                    : 0}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Overtime Surcharge
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.surcharge_price}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Overtime rounded an hour
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <MdWatchLater /> {item.surcharge_hour} Min
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Friday Price
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.friday_price}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Saturday Price
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.saturday_price}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Sunday Price
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.sunday_price}
                                </div>

                                <div className="col-7">
                                  <strong style={{ fontSize: ".8rem" }}>
                                    Holiday Price
                                  </strong>
                                </div>
                                <div className="col-1">-</div>
                                <div className="col-4">
                                  <LuIndianRupee />
                                  {item.holiday_price}
                                </div>
                              </div>
                            </div>
                          }
                          title=""
                        >
                          <span
                            style={{
                              color: "#000",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <BiRupee /> {item.price}&nbsp;
                            <FaAngleDown />
                          </span>
                        </Popover>
                      </span>
                      <span>
                        {item.adult_space} Adult, {item.child_space} Child
                      </span>
                    </div>
                  }
                  description={
                    <div className="d-flex align-items-center justify-content-between">
                      <p>{item.typeName}</p>{" "}
                      <span>
                        <MdOutlineMeetingRoom /> {item.available_priority} /{" "}
                        {item.priority}
                      </span>
                      {selectedRooms.some((value) => value == item.id) && (
                        <InputNumber
                          placeholder="Qty"
                          onChange={(value) => handleQuamtity(item.id, value)}
                          defaultValue={1}
                          max={parseInt(item.available_priority)}
                          min={1}
                        />
                      )}
                    </div>
                  }
                />
              </List.Item>
            )}
          />

          {/* <Form.Item>
          <Row className="mt-3">
            <Col span={8} className="d-flex align-items-center">
              <h6>Adults</h6>
            </Col>
            <Col span={2} className="d-flex align-items-center">
              :
            </Col>
            <Col span={14} className="d-flex align-items-center">
              <Button
                style={{
                  width: 35,
                  height: 35,
                  background: "#000",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50px",
                }}
                onClick={() => setAdults((prev) => prev + 1)}
              >
                <IoMdAdd style={{ transform: "scale(5)" }} />
              </Button>
              <Input
                readOnly
                min={0}
                value={adults}
                type="number"
                className="formInput mx-3"
              />
              <Button
                style={{
                  width: 35,
                  height: 35,
                  background: "#000",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50px",
                }}
                onClick={() => setAdults((prev) => (prev > 0 ? prev - 1 : 0))}
              >
                <FaMinus style={{ transform: "scale(4)" }} />
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col span={8} className="d-flex align-items-center">
              <h6>Childs</h6>
            </Col>
            <Col span={2} className="d-flex align-items-center">
              :
            </Col>
            <Col span={14} className="d-flex align-items-center">
              <Button
                style={{
                  width: 35,
                  height: 35,
                  background: "#000",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50px",
                }}
                onClick={() => setChilds((prev) => prev + 1)}
              >
                <IoMdAdd style={{ transform: "scale(5)" }} />
              </Button>
              <Input
                readOnly
                min={0}
                value={childs}
                type="number"
                className="formInput mx-3"
              />
              <Button
                style={{
                  width: 35,
                  height: 35,
                  background: "#000",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50px",
                }}
                onClick={() => setChilds((prev) => (prev > 0 ? prev - 1 : 0))}
              >
                <FaMinus style={{ transform: "scale(4)" }} />
              </Button>
            </Col>
          </Row>
        </Form.Item> */}

          <Form.Item>
            <Button
              disabled={selectedRooms.length === 0}
              className="uploadButton w-100 mt-3"
              onClick={() => setBookingSteps("guestDetails")}
            >
              Submit & Next
            </Button>
          </Form.Item>
        </Form>
      </>
    </div>
  );
}
