import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Segmented,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { IoIosRemoveCircle } from "react-icons/io";
import { MdCurrencyRupee } from "react-icons/md";
import { MdOutlineLineWeight } from "react-icons/md";
import { MdDragIndicator } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { MdCloudUpload } from "react-icons/md";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { get } from "../../../services/ApiRouting";
import { getToken } from "../../../services/Authentication";
import { IoIosArrowBack } from "react-icons/io";

const customeButtonStyle = {
  marginRight: 20,
  border: "unset",
  boxShadow: "unset",
  fontSize: "1.5rem",
  display: "flex",
  alignItems: "center",
  padding: 0,
};

// ============== Drag Items ===============
const SortableItem = ({
  id,
  value,
  index,
  handleUpdateData,
  RemoveQuanity,
  foodQuantity,
  AddMoreQuantity,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <div
      className="row mt-3"
      key={value.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="col-5 d-flex align-items-center">
        <MdDragIndicator
          ref={setActivatorNodeRef}
          style={{
            marginRight: "10px",
            cursor: "move",
            fontSize: "1.2rem",
          }}
          {...listeners}
        />

        <Input
          prefix={<MdOutlineLineWeight />}
          placeholder="Food Quantity"
          className="formInput"
          value={value.quantity}
          onChange={(e) => handleUpdateData(e, "quantity", index)}
        />
      </div>
      <div className="col-5">
        <InputNumber
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          prefix={<MdCurrencyRupee />}
          placeholder="Food Price"
          className="formInput"
          value={value.price}
          onChange={(e) => handleUpdateData(e, "price", index)}
          min={0}
        />
      </div>
      <div className="col-2 d-flex align-items-center">
        <Button
          onClick={() => RemoveQuanity(index)}
          style={{ ...customeButtonStyle, color: "red" }}
        >
          <IoIosRemoveCircle />
        </Button>
        {index === foodQuantity.length - 1 && (
          <Button
            onClick={() => AddMoreQuantity(value.id)}
            style={{ ...customeButtonStyle, color: "green" }}
          >
            <IoMdAddCircle />
          </Button>
        )}
      </div>
    </div>
  );
};

export default function FoodDetail({
  foodQuantity,
  setFoodQuantity,
  formRef,
  fileList,
  setFileList,
  formData,
  handleSubmitNewFood,
  handleImageUpload,
  setImage,
  image,
}) {
  // ============ Add more quantity =========
  const AddMoreQuantity = (id) => {
    const newAdd = { id: id + 1, quantity: "", price: 0 };
    const PreviousData = foodQuantity;
    PreviousData.push(newAdd);
    setFoodQuantity([...PreviousData]);
  };

  // ============ Remove Quantity ===========
  const RemoveQuanity = (index) => {
    if (foodQuantity.length === 1) {
      alert("Minimum one quantity required !");
      return;
    }
    const PreviousData = foodQuantity;
    PreviousData.splice(index, 1);
    setFoodQuantity([...PreviousData]);
  };

  // =============== Update Quantity ============
  const handleUpdateData = (e, type, index) => {
    const previousData = foodQuantity;

    switch (type) {
      case "quantity":
        previousData[index].quantity = e.target.value;
        break;
      case "price":
        previousData[index].price = e;
        break;
      default:
        break;
    }

    setFoodQuantity([...previousData]);
  };

  // ============ Drag and Sort List of quantity ==============

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFoodQuantity((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList)
    if (newFileList.length > 0) {
      handleImageUpload(newFileList[0].originFileObj);
      if (image) {
        setImage(URL.createObjectURL(newFileList[0].originFileObj));
      }
    }
    setFileList(newFileList);
  };


  const uploadButton = (
    <Button
      disabled={fileList.length > 0}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        color: "#000",
        border: "1px solid #444",
        height: 40,
      }}
    >
      <MdCloudUpload />
      &nbsp; Upload Image
    </Button>
  );

  // =================== Fetch Categ List ================
  const [loading, setLoading] = useState(false);
  const [categList, setCategList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { access_token } = getToken();

  const getCatgoryList = async (search) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await get(
        `/category-list${search !== "" ? "/" + search : ""}`,
        header
      );

      if (resp.data) {
        setLoading(false);
        setCategList(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      getCatgoryList(searchText);
    }, 300);

    return () => clearTimeout(renderTime);
  }, [searchText]);

  return (
    <div>
      <div className="updateFoodDetails">
        <Form form={formData} ref={formRef} onFinish={handleSubmitNewFood}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Form.Item name="upload" style={{ margin: 0 }}>
              {image && (
                <Image
                  src={image}
                  style={{ maxWidth: "50px", marginRight: "10px" }}
                />
              )}
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                fileList={fileList}
                onChange={handleChange}
                accept=".JPG, .PNG, .JPEG, .WEBP"
              >
                {uploadButton}
              </Upload>
            </Form.Item>

            <div className="vegNonvegSelect d-flex align-items-center">
              <Form.Item name="vegOrNonVeg" style={{ margin: 0 }} rules={[{required:true, message:"This field is required !"}]}>
                <Segmented
                  style={{ background: "#afafaf", padding: 5 }}
                  options={[
                    {
                      label: (
                        <div className="vegNonvegText">
                          <span className="veg box"></span> <strong>Veg</strong>
                        </div>
                      ),
                      value: "Veg",
                    },
                    {
                      label: (
                        <div className="vegNonvegText">
                          <span className="nonVeg box"></span>
                          <strong>Non Veg</strong>
                        </div>
                      ),
                      value: "Non Veg",
                    },
                  ]}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                name="name"
                rules={[{ required: true, message: "This field is required !" }]}
              >
                <Input className="formInput" placeholder="Food Name" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="category"
                rules={[{ required: true, message: "This field is required !"  }]}
              >
                <Select
                  placeholder="Select Categorie"
                  onClick={() => getCatgoryList("")}
                  showSearch
                  loading={loading}
                  onSearch={(value) => setSearchText(value)}
                >
                  {categList.map((value) => (
                    <Select.Option label={value.name} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Form.Item name="description" rules={[{required:true, message:"This field is required !"}]}>
            <TextArea
              placeholder="Food Description"
              className="formTextArea"
              showCount
              rows={4}
              maxLength={500}
            />
          </Form.Item>
        </Form>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={foodQuantity.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            {foodQuantity.map((value, index) => (
              <SortableItem
                id={value.id}
                value={value}
                index={index}
                handleUpdateData={handleUpdateData}
                RemoveQuanity={RemoveQuanity}
                foodQuantity={foodQuantity}
                AddMoreQuantity={AddMoreQuantity}
              />
            ))}
          </SortableContext>
        </DndContext>
        {foodQuantity.length === 0 && (
          <span style={{ color: "red" }}>Atleast add one quantity</span>
        )}
      </div>
    </div>
  );
}
