import { Input, List, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdDragIndicator } from "react-icons/md";
import { get } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";

export default function SearchFood({
  foodList,
  setFoodList,
  searchText,
  setSearchText,
  loading,
  setLoading,
}) {
  const [listType, setListType] = useState("foods");
  const { access_token } = getToken();
  const { profile } = getProfile();

  const hendleFoodList = async (search, type) => {
    setLoading(true);
    let data;
    const header = {
      Authorization: "Bearer " + access_token,
    };

    if (type === "foods") {
      data = await get(
        `/food-details${search !== "" ? "/" + search : ""}`,
        header
      );
    } else {
      data = await get(
        `/food-and-categ-list/sub/${profile.account_id}/0/15${
          search !== "" ? "/" + search : ""
        }`,
        header
      );
    }
    if (data) {
      setFoodList(data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    const bounceData = setTimeout(() => {
      hendleFoodList(searchText, listType);
    }, 300);

    return () => clearTimeout(bounceData);

    // eslint-disable-next-line
  }, [searchText, listType]);

  const handleChangeList = (value) => {
    setListType(value);
  };

  // =================== Handle Drag Product ================
  const [dragData, setDragData] = useState(null);

  const handleDragStart = (event: React.DragEvent, data) => {
    event.dataTransfer.setData(listType, JSON.stringify(data));
    setDragData(data);
  };

  const handleDragEnd = (event: React.DragEvent) => {
    setDragData(null);
  };

  return (
    <div
      className="searchDataLeft"
      style={{ position: "sticky", top: "100px" }}
    >
      <h6>Search Foods / Category</h6>
      <div className="searchInput w-100 d-flex justify-content-between">
        <div className="SearchTextInput position-relative w-100">
          <Input
            className="formInput"
            type="search"
            placeholder="Search here"
            onInput={(e) => setSearchText(e.target.value)}
          />
          <FiSearch
            style={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
            }}
          />
        </div>
        <Select
          onChange={handleChangeList}
          placeholder="Select Type"
          style={{ marginLeft: "5px", maxWidth: "100px" }}
        >
          <Select.Option value="foods">Foods</Select.Option>
          <Select.Option value="categories">Catogaries</Select.Option>
        </Select>
      </div>
      <div className="searchList">
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={foodList}
          style={{ maxHeight: "80vh", overflow: "auto", padding: "10px 5px" }}
          renderItem={(item, index) => (
            <List.Item
              key={index + 1}
              className={`dragCard ${
                dragData && dragData.id === item.id ? "dragging" : ""
              }`}
              draggable
              onDragStart={(e) => handleDragStart(e, item)}
              onDragEnd={(e) => handleDragEnd(e)}
            >
              <List.Item.Meta
                avatar={<MdDragIndicator />}
                title={
                  <span style={{ textTransform: "capitalize" }}>
                    {item.name}
                  </span>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
