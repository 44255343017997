import React, { useEffect, useState } from "react";
import SearchHeader from "./SearchHeader";
import SearchTable from "./SearchTable";
import { get } from "../../../services/ApiRouting";
import { Button, Divider, Popover, message } from "antd";
import { getProfile, getToken } from "../../../services/Authentication";
import moment from "moment";
import { MdCurrencyRupee } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { IoDocumentTextSharp } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";

export default function SearchDetail() {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { profile } = getProfile();
  const { access_token } = getToken();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleDataGet = async (page, limit, account_id, search) => {
    try {
      setLoading(true);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await get(
        `/booking/${page - 1}/${limit}/${account_id}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );

      if (resp) {
        setLoading(false);
        const data = resp.data.map((value, index) => ({
          ...value,
          check_in: moment(value.check_in).format("lll"),
          check_out: moment(value.check_out).format("lll"),
          phone: <>+91&nbsp;{value.phone}</>,
          amount: (
            <>
              <MdCurrencyRupee />
              {value.total}
            </>
          ),
          rooms: (
            <Popover
              placement="bottom"
              content={
                <div className="container" style={{ width: "300px" }}>
                  {value.items.map((valueData) => (
                    <div className="row border-bottom">
                      <div className="col-md-6  d-flex align-items-center justify-content-start">
                        {valueData.room_villa_name}
                      </div>
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        {valueData.quantity} room
                      </div>
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <MdCurrencyRupee />
                        {valueData.amount}
                      </div>
                    </div>
                  ))}
                </div>
              }
            >
              {value.items.length.toString().padStart(2, "0")} <FaAngleDown />
            </Popover>
          ),
          guest: (
            <Popover
              placement="bottom"
              content={
                <div className="container" style={{ width: "300px" }}>
                  {value.guests.map((valueData) => (
                    <div className="row border-bottom">
                      <div className="col-md-6  d-flex align-items-center justify-content-start">
                        {valueData.name}
                      </div>
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        {valueData.age} Yrs
                      </div>
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <Button className="iconButtonAction blackButton">
                          <IoDocumentTextSharp />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              }
            >
              {value.guests.length.toString().padStart(2, "0")} <FaAngleDown />
            </Popover>
          ),
          status: (
            <span
              className={`${value.booking_status} badge p-2`}
              style={{ fontSize: ".7rem" }}
            >
              {value.booking_status}
            </span>
          ),
          created_at: moment(value.created_at).format("lll"),
          action: (
            <Button className="iconButtonAction edit">
              <FaEdit />
            </Button>
          ),
        }));
        setDataSource(data);
        setTotal(resp.total);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable to get data please check agan");
    }
  };

  useEffect(() => {
    const timeRender = setTimeout(() => {
      handleDataGet(page, limit, profile.account_id, search);
    }, 500);

    return () => clearTimeout(timeRender);
  }, [page, limit, search]);

  return (
    <div>
      <SearchHeader
        page={page}
        limit={limit}
        account_id={profile.account_id}
        handleDataGet={handleDataGet}
        setSearch={setSearch}
        search={search}
      />
      <Divider />
      <SearchTable
        dataSource={dataSource}
        total={total}
        page={page}
        limit={limit}
        loading={loading}
        setPage={setPage}
        setLimit={setLimit}
      />
    </div>
  );
}
