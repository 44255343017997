import React, { useEffect, useState } from "react";
import { MdBedroomParent } from "react-icons/md";
import { MdEventAvailable } from "react-icons/md";
import { Spin } from "antd";

export default function AboutRoom({loading, rooms}) {
  

  return (
    <Spin spinning={loading}>
      <div className="row">
        <div className="col-md-8"></div>
        <div className="col-md-2 d-flex justify-content-center">
          <MdBedroomParent style={{ fontSize: "1.4rem" }} />
        </div>
        <div className="col-md-2 d-flex justify-content-center">
          <MdEventAvailable style={{ fontSize: "1.4rem" }} />
        </div>
      </div>

      {rooms.map((value) => (
        <div className="row mt-3">
          <div className="col-md-8">
            <h6>{value.room_villa_name}</h6>
          </div>
          <div className="col-md-2 d-flex justify-content-center">
            <strong>{value?.priority.toString().padStart(2, '0')}</strong>
          </div>
          <div className="col-md-2 d-flex justify-content-center">
            <strong>{value?.booking.toString().padStart(2, '0')}</strong>
          </div>
        </div>
      ))}
    </Spin>
  );
}
