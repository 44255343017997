import React, { useState } from "react";
import { Form, Input, Button, DatePicker, Select, Modal } from "antd";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { MdFilterListAlt } from "react-icons/md";
import { FaFilter } from "react-icons/fa6";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function BookingFilterModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values: ", values);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={showModal}
        icon={<FaFilter />}
        className="addButtons mx-2"
      >
        Filter Data
      </Button>
      <Modal
        title="Filter Booking List"
        open={isModalOpen}
        onOk={handleOk}
        centered
        width={900}
        onCancel={handleCancel}
        footer={[
          <Button className="addButtons" icon={<FaFilterCircleXmark />}>
            Clear Filter
          </Button>,
          <Button className="uploadButton" icon={<MdFilterListAlt />}>
            Apply Filter
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            bookingStatus: "",
            roomType: "",
            paymentStatus: "",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Item name="bookingStatus" label="Booking Status">
                <Select placeholder="Select a status">
                  <Option value="">All</Option>
                  <Option value="booked">Booked</Option>
                  <Option value="notBooked">Not Booked</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item name="dateRange" label="Booking between">
                <RangePicker className="formInput w-100" />
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item name="roomType" label="Room Type">
                <Select placeholder="Select a room type">
                  <Option value="">All</Option>
                  <Option value="single">Single</Option>
                  <Option value="double">Double</Option>
                  <Option value="suite">Suite</Option>
                </Select>
              </Form.Item>
            </div>
            
            <Form.Item name="paymentStatus" label="Payment Status">
              <Select placeholder="Select payment status">
                <Option value="">All</Option>
                <Option value="paid">Paid</Option>
                <Option value="pending">Pending</Option>
                <Option value="unpaid">Unpaid</Option>
              </Select>
            </Form.Item>

            <Form.Item name="checkInDate" label="Check-in Date">
              <DatePicker />
            </Form.Item>

            <Form.Item name="checkOutDate" label="Check-out Date">
              <DatePicker />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}
