import { Dialog, Slide } from "@mui/material";
import { Button, Divider, InputNumber } from "antd";
import React, { useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";
import "../../../css/menu.css";
import { BiSolidCartAdd } from "react-icons/bi";
import { FaCartPlus } from "react-icons/fa";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewFoodModal({ value, cart, setCart }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const dataTotal = (value, type) => {
    const prevData = [...selectedData]; // Ensure immutability by creating a copy of selectedData
    const index = prevData.findIndex((data) => data.name === type); // Check if data with the same name exists
    const newData = {
      // Create the new data object
      name: type,
      price: value.price,
      quantity: value.quantity,
      product: value.name
    };

    if (index !== -1) {
      // If data with the same name exists, update its price and quantity
      prevData[index].price = value.price;
      prevData[index].quantity = value.quantity;
      prevData[index].product = value.name;
    } else {
      // If no data with the same name exists, push the new data object
      prevData.push(newData);
    }

    setSelectedData(prevData); // Update the selectedData state
  };

  function getTotalAmount(dataArray) {
    let total = 0;
    dataArray.forEach((item) => {
      if (item) {
        total += parseInt(item.price);
      }
    });
    return total * quantity;
  }

  const addToCard = () => {
    const prevData = cart;
    const data = {
      amount:getTotalAmount(selectedData),
      name: value.name.toLowerCase()+selectedData.map(value=> value.name === "quantity" ? " "+value.quantity : value.product.toLowerCase()+"("+value.quantity+")").join(", "),
      quantity:quantity,
      image:value.image,
    }

    prevData.push(data);
    setCart([...prevData]);
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="add-to-cart-button-container" onClick={handleOpen}>
        Add
        <BiSolidCartAdd />
      </div>

      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={handleCancel}
        TransitionComponent={Transition}
      >
        {value && (
          <div className="viewDetails p-4">
            <div className="row">
              <div className="col-md-6">
                <div className="details">
                  <img
                    src={
                      value.image
                        ? value.image
                        : "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                    }
                    alt=""
                  />
                  <div className="foodDetailContents mt-4">
                    <h5 className="d-flex align-items-center">
                      {value.name}&nbsp;
                      <span
                        className={
                          value.type === "Veg" ? "vegClass" : "nonVegClass"
                        }
                      />
                    </h5>
                    <p style={{ color: "#444", fontSize: ".8rem" }}>
                      {value.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <ul className="w-100">
                  <li className="w-100">
                    <div className="categoryTitl">
                      <h6 className="d-flex align-items-center">
                        Available options
                        <span
                          style={{
                            color: "#808080",
                            fontSize: ".8rem",
                            marginLeft: "10px",
                          }}
                        >
                          (requred)
                        </span>
                      </h6>
                      <p style={{ color: "#808080", fontSize: ".9rem" }}>
                        Select any one
                      </p>
                    </div>
                    {value.detail &&
                      value.detail.length > 0 &&
                      value.detail.map((valueData, index) => (
                        <div
                          className="checkInput d-flex align-items-center w-100"
                          key={index}
                        >
                          <input
                            type="radio"
                            id={"index" + index}
                            name="quantity"
                            className="form-check-input"
                            onChange={() => dataTotal(valueData, "quantity")}
                          />
                          <label
                            className="quantityDetail d-flex align-items-center justify-content-between w-100 mx-2"
                            htmlFor={"index" + index}
                          >
                            <span>{valueData.quantity} </span>
                            <span style={{ fontSize: ".9rem" }}>
                              <PiCurrencyInrBold />
                              &nbsp;{valueData.price}
                            </span>
                          </label>
                        </div>
                      ))}
                  </li>

                  {value.offers.map((valueData) => (
                    <li className="w-100">
                      <Divider />
                      <div className="categoryTitl">
                        <h6 className="d-flex align-items-center">
                          {valueData.name}
                          <span
                            style={{
                              color: "#808080",
                              fontSize: ".8rem",
                              marginLeft: "10px",
                            }}
                          >
                            {valueData.is_required === "0" ? "" : "(Required)"}
                          </span>
                        </h6>
                        <p style={{ color: "#808080", fontSize: ".9rem" }}>
                          {valueData.description}
                        </p>
                      </div>
                      {valueData.foods.map((valueDataFood, index) => (
                        <div
                          className="checkInput d-flex align-items-center w-100"
                          key={index}
                        >
                          <input
                            type={
                              parseInt(valueData.option_select) > 1
                                ? "checkbox"
                                : "radio"
                            }
                            id={valueDataFood.name + index}
                            name={valueData.name}
                            className="form-check-input"
                            onChange={() =>
                              dataTotal(valueDataFood, valueData.name)
                            }
                          />
                          <label
                            className="quantityDetail d-flex align-items-center justify-content-between w-100 mx-2"
                            htmlFor={valueDataFood.name + index}
                          >
                            <span>
                              {valueDataFood.name}{" "}
                              <span style={{ color: "#808080" }}>
                                ({valueDataFood.quantity})
                              </span>
                            </span>
                            <span style={{ fontSize: ".9rem" }}>
                              <PiCurrencyInrBold />
                              &nbsp;{valueDataFood.price}
                            </span>
                          </label>
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              </div>
              <Divider />
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="totalDetail d-flex align-items-center">
                  <div>Total:</div>
                  <div className="mx-4 d-flex align-items-center">
                    <PiCurrencyInrBold />
                    {getTotalAmount(selectedData)}
                  </div>
                </div>
                <InputNumber
                  placeholder="Qty"
                  min={1}
                  value={quantity}
                  onChange={(value) => setQuantity(value)}
                />
                <Button
                onClick={addToCard}
                  style={{
                    background: "green",
                    color: "#fff",
                    minHeight: "40px",
                  }}
                >
                  Add into cart&nbsp;
                  <FaCartPlus />
                </Button>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
