import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import BookingList from "./BookingList";
import dayjs from "dayjs";
import moment from "moment";
import { message } from "antd";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { getProfile, getToken } from "../../../services/Authentication";
import { get } from "../../../services/ApiRouting";

export default function Calender() {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const { access_token } = getToken();
  const [dateRange, setDateRange] = useState([
    dayjs(),
    dayjs().add(20, "days"),
  ]);
  const { profile } = getProfile();

  const { setDrawerData } = useDrawerContext();

  const handleCreateBooking = (date) => {
    const selectedDate = new Date(date);
    const nextDate = new Date(selectedDate);
    nextDate.setDate(selectedDate.getDate() + 1);

    setDrawerData({
      title: "Create Booking",
      width: 450,
      type: "CreateBooking",
      isOpen: true,
      room: [],
      date: date ? [dayjs(selectedDate), dayjs(nextDate)] : [],
    });
  };

  const fetchData = async () => {
    try {
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const res = await get(
        `/booked-rooms/${moment(new Date(dateRange[0])).format(
          "YYYY-MM-DD"
        )}/${moment(new Date(dateRange[1])).format("YYYY-MM-DD")}/${
          profile.account_id
        }`,
        header
      );

      if (res) {
        setData(res.data);
        message.success("Booking data found");
      }
    } catch (error) {
      console.error(error);
      message.error("No data found");
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(renderTime);
  }, [dateRange]);

  return (
    <div>
      <SubHeader
        dateRange={dateRange}
        setDateRange={setDateRange}
        searchText={searchText}
        setSearchText={setSearchText}
        fetchData={fetchData}
      />
      <BookingList
        dateRange={dateRange}
        searchText={searchText}
        data={data}
        fetchData={fetchData}
        handleCreateBooking={handleCreateBooking}
      />
    </div>
  );
}
