import React, { useEffect, useState } from "react";
import CheckIn from "./CheckIn";
import CheckInHeader from "./CheckInHeader";
import dayjs from "dayjs";
import { message, Spin } from "antd";
import { get } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";
import moment from "moment";

export default function CheckInDetail() {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [data, setData] = useState([]);
  const { profile } = getProfile();
  const { access_token } = getToken();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = async (date, search) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(
        `/booking-rooms/${moment(new Date(date)).format("YYYY-MM-DD")}/${
          profile.account_id
        }${search !== "" ? "/" + search : ""}`,
        header
      );

      if (resp) {
        setData(resp.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable to fetch data");
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchData(currentDate, search);
    }, 300);

    return () => clearTimeout(renderTime);
  }, [currentDate, search]);

  return (
    <div>
      <CheckInHeader
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        search={search}
        setSearch={setSearch}
        fetchData={fetchData}
      />
      <Spin spinning={loading}>
        <CheckIn data={data} setData={setData} setLoading={setLoading} />
      </Spin>
    </div>
  );
}
