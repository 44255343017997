import React from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import TableComp from "../components/Table/TableComp";
import { IoMdCreate } from "react-icons/io";

export default function Accounts() {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width:"15%",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width:"5%",

    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width:"10%",

    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width:"20%",

    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width:"15%",

    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width:"15%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <div>
      <PageTitle
        buttonText="Create Login"
        icon={<IoMdCreate />}
        link=""
        // handleAdd={handleAdd}
        isButtonShow={true}
        isShwoButton={false}
      />

      <div className="container">
        <TableComp columns={columns} />
      </div>
    </div>
  );
}
