import { AppBar, Toolbar } from "@mui/material";
import { Button, Divider, Form, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import SearchFood from "../../Modal/FoodModalaComponent/SearchFood";
import FoodDetail from "../../Modal/FoodModalaComponent/FoodDetail";
import ComboFoodData from "../../Modal/FoodModalaComponent/ComboFoodData";
import CategorySelected from "../../Modal/FoodModalaComponent/CategorySelected";
import { MdCloudUpload } from "react-icons/md";
import { get, post } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useImageResizer } from "../../../context/imageResizer";

export default function UpdateFood() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [foodList, setFoodList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [comboOptionFoodList, setComboOptionFoodList] = useState([]);
  const [finalFile, setFinalFile] = useState(null);
  const [image, setImage] = useState(null);
  const [foodQuantity, setFoodQuantity] = useState([
    { id: 1, quantity: "", price: 0 },
  ]);
  const [fileList, setFileList] = useState([]);
  const { access_token } = getToken();
  const { profile } = getProfile();
  const formRef = useRef();
  const [formData] = Form.useForm();
  const { slug } = useParams();
  const [id, setId] = useState();

  // ====================== Fetch Details ================

  const fetchFoodDetail = async (search, page, limit) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };

      const result = await get(
        `/main-food-details/${profile.account_id}/${page - 1}/${limit}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );

      if (result) {
        setId(result.data[0].id);
        setImage(result.data[0].image);
        setFoodQuantity(result.data[0].detail);
        setComboOptionFoodList(result.data[0].offers);
        setSelectedCategory(null);
        formData.setFieldsValue({
          name: result.data[0].name,
          description: result.data[0].description,
          vegOrNonVeg: result.data[0].type,
          category: result.data[0].category_id_reff,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      message.error("Someting went wrong please refresh page");
    }
  };

  useEffect(() => {
    fetchFoodDetail(slug, 1, 1);
  }, [slug]);

  // =============== Add new combo category ===============
  const addNewCategory = () => {
    const prevData = comboOptionFoodList;
    const prevId = prevData.length > 0 ? prevData[prevData.length - 1].id : 0;

    const data = {
      id: prevId + 1,
      name: "",
      slug: "",
      description: "",
      option_select: 1,
      is_required: false,
      foods: [],
    };
    prevData.push(data);
    setComboOptionFoodList([...prevData]);
    setSelectedCategory(data);
  };

  // ============== Create New Food ==================

  const slugGenearte = (name) => {
    return name
      .toLowerCase() // Convert the string to lowercase
      .trim() // Remove leading and trailing whitespaces
      .replace(/\s+/g, "-");
  };

  const navigate = useNavigate();

  const { resizeFile } = useImageResizer();

  const handleImageUpload = async (file) => {
    const maxWidth = 150;
    const maxHeight = 150;
    const targetFileSize = 100 * 1024;

    const compresedFile = await resizeFile(
      file,
      maxWidth,
      maxHeight,
      targetFileSize
    );

    setFinalFile(compresedFile);
  };

  const handleSubmitNewFood = async (value) => {
    try {
      setUploadLoading(true);

      const formData = new FormData();
      formData.append("id", id);
      formData.append("name", value.name);
      formData.append("slug", slugGenearte(value.name));
      formData.append("description", value.description);
      formData.append("type", value.vegOrNonVeg);
      formData.append("detail", JSON.stringify(foodQuantity));
      formData.append("offer", JSON.stringify(comboOptionFoodList));
      formData.append("account_id", profile.account_id);
      formData.append("category_id_reff", value.category);
      // formData.append("branch_id_reff", JSON.stringify(comboOptionFoodList));
      // Add image file to FormData
      if (finalFile) {
        formData.append("upload", finalFile);
      } else {
        formData.append("image", image.split('storage/')[1]);
    
      }

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/main-food-details", formData, header);
      if (resp) {
        setFoodQuantity([{ id: 1, quantity: "", price: 0 }]);
        setComboOptionFoodList([]);
        setSelectedCategory(null);
        setFileList([]);
        setUploadLoading(false);
        navigate("/foods");
      }
    } catch (error) {
      setUploadLoading(false);
      message.error("Unable to upload please try again");
      console.error(error);
    }
  };

  const submitForm = () => {
    formRef.current.submit();
  };

  return (
    <Spin spinning={uploadLoading}>
      <div>
        <AppBar sx={{ position: "sticky", top: 0, background: "#000" }}>
          <Toolbar>
            <div className="w-100 d-flex align-items-center justify-content-between">
              <NavLink
                to="/foods"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontWeight: "500",
                }}
              >
                <IoIosArrowBack /> ADD NEW FOOD
              </NavLink>
              <Button
                onClick={submitForm}
                className="uploadButton"
                icon={<MdCloudUpload />}
              >
                Update
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <div className="row p-4">
          <div className="col-md-3 px-md-4">
            <SearchFood
              foodList={foodList}
              setFoodList={setFoodList}
              searchText={searchText}
              setSearchText={setSearchText}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
          <div
            className="col-md-6"
            style={{
              borderRight: "1px solid #d5d5d5",
              borderLeft: "1px solid #d5d5d5",
              height: "88vh",
            }}
          >
            <FoodDetail
              foodQuantity={foodQuantity}
              setFoodQuantity={setFoodQuantity}
              formRef={formRef}
              fileList={fileList}
              setFileList={setFileList}
              handleSubmitNewFood={handleSubmitNewFood}
              handleImageUpload={handleImageUpload}
              formData={formData}
              image={image}
              setImage={setImage}
            />
            <div className="foodDevider d-flex align-items-center">
              <div className="px-3 w-100">
                <Divider orientation="left">Combo / Option Food List</Divider>
              </div>
              <Button className="uploadButton" onClick={addNewCategory}>
                Add new
              </Button>
            </div>
            <ComboFoodData
              comboOptionFoodList={comboOptionFoodList}
              setComboOptionFoodList={setComboOptionFoodList}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
          </div>
          <div className="col-md-3 px-3">
            <CategorySelected
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              comboOptionFoodList={comboOptionFoodList}
              setComboOptionFoodList={setComboOptionFoodList}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
}
