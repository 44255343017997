import { Button, Form, Input, message } from "antd";
import React from "react";
import { post } from "../../../services/ApiRouting";
import { getToken } from "../../../services/Authentication";
import { useDrawerContext } from "../../../context/DrawerControlContext";

export default function AddFeatures() {
  const [form] = Form.useForm();
  const { access_token } = getToken();
  const { setDrawerData } = useDrawerContext();

  const columns = [
    {
      label: "Featue Title",
      name: "feature_title",
      col: "col-12",
    },
    {
      label: "Featue Name",
      name: "feature_name",
      col: "col-12",
    },
    {
      label: "",
      name: "",
      col: "col-12",
      step: "account",
      prop: (
        <Button htmlType="submit" className="uploadButton w-100 mt-4">
          Submit
        </Button>
      ),
    },
  ];

  const onFinish = async (value) => {
    try {
      const formData = new FormData();
      formData.append("feature_name", value.feature_name);
      formData.append("feature_title", value.feature_title);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/feature", formData, header);

      if (resp) {
        message.success(resp.message);
        setDrawerData({
          isOpen: false,
        });
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="row">
          {columns.map((val) => (
            <div className={`${val.col}`}>
              <Form.Item label={val.label} name={val.name}>
                {val.prop ? (
                  val.prop
                ) : (
                  <Input placeholder={val.label} className="formInput" />
                )}
              </Form.Item>
            </div>
          ))}
        </div>
      </Form>
    </>
  );
}
