import React, { useState } from "react";
import { Button, Divider, Dropdown, message, Popover } from "antd";
import { SlOptionsVertical } from "react-icons/sl";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { MdVpnKey } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { FaDoorOpen } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { RiArrowLeftRightLine } from "react-icons/ri";
import NotFoundComman from "../../NotFound/NotFoundComman";
import moment from "moment";
import { post } from "../../../services/ApiRouting";
import { getToken } from "../../../services/Authentication";

export default function CheckIn({ data, setLoading }) {
  const { access_token } = getToken();

  const UpdateBookingStatus = async (status, id) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("_method", "put");
      formData.append("id", id);
      formData.append("status", status);
      formData.append("action", "updateRoomStatus");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/booking", formData, header);
      if (resp) {
        setLoading(false);
        message.success("Status updated refresh and check");
      }
    } catch (error) {
      console.error(error);
      message.error("Unable to update status try again");
      setLoading(false);
    }
  };

  return data.length > 0 ? (
    <div>
      <Divider />
      <div className="row">
        {data?.map((value, index) => (
          <div className="col-md-3" key={index}>
            <div className="checkInCard">
              <div className="checkInCardHeader d-flex align-items-center justify-content-between">
                <strong>{value.booking_id}</strong>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 1,
                        label: <span style={{ fontSize: "1rem" }}>Edit</span>,
                        icon: (
                          <FaEdit
                            style={{ fontSize: "1rem" }}
                            className="iconButtonAction edit"
                          />
                        ),
                      },
                      {
                        key: 2,
                        label:
                          value.status === "booked" ? (
                            <span
                              onClick={() =>
                                UpdateBookingStatus("check-in", value.id)
                              }
                              style={{ fontSize: "1rem" }}
                            >
                              Check In
                            </span>
                          ) : (
                            <span style={{ fontSize: "1rem" }}>Check Out</span>
                          ),
                        icon: (
                          <MdVpnKey
                            style={{ fontSize: "1rem" }}
                            className="iconButtonAction success"
                          />
                        ),
                      },
                      {
                        key: 3,
                        label: (
                          <span
                            onClick={() =>
                              UpdateBookingStatus("cancel", value.id)
                            }
                            style={{ fontSize: "1rem" }}
                          >
                            Cancel Room
                          </span>
                        ),
                        icon: (
                          <MdCancel
                            style={{ fontSize: "1rem" }}
                            className="iconButtonAction remove"
                          />
                        ),
                      },
                      {
                        key: 4,
                        label: <span style={{ fontSize: "1rem" }}>Delete</span>,
                        icon: (
                          <FaTrash
                            style={{ fontSize: "1rem" }}
                            className="iconButtonAction remove"
                          />
                        ),
                      },
                    ],
                  }}
                  placement="bottomRight"
                  arrow
                >
                  <Button
                    type="primary"
                    className="iconButtonAction blackButton"
                  >
                    <SlOptionsVertical />
                  </Button>
                </Dropdown>
              </div>
              <Divider />
              <div className="checkInCardBody">
                <div className="detail">
                  <FaUser />
                  <h6>{value.name}</h6>
                </div>
                <div className="detail">
                  <RiArrowLeftRightLine />
                  <h6 style={{ textTransform: "capitalize" }}>
                    {value.status === "booked"
                      ? "Not Check In Yet"
                      : value.status}
                  </h6>
                </div>
                <div className="detail">
                  <FaClock />
                  <h6>
                    {moment(value.from).format("DD MMM")} -{" "}
                    {moment(value.to).format("DD MMM")}
                  </h6>
                </div>
                <div className="detail">
                  <IoCall />
                  <h6>+91 {value.phone}</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="detail">
                    <FaBed />
                    <h6>{value.room}</h6>
                  </div>
                  <div className="detail">
                    <FaDoorOpen style={{ fontSize: "2rem" }} />
                    <h6>{value.quantity}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <>
      <Divider />
      <NotFoundComman
        title="No Room Found !"
        description=""
        img="https://www.iconpacks.net/icons/2/free-sad-face-icon-2691-thumb.png"
      />
    </>
  );
}
