import React, { useEffect, useRef, useState } from "react";
import { List, Spin, Input } from "antd";
import { get } from "../../services/ApiRouting";
import { getToken } from "../../services/Authentication";
import AddUserModal from "../Modal/AddUserModal";
import { FiSearch } from "react-icons/fi";

export default function SearchPopupModal({
  api,
  selected,
  setSelected,
  list,
  setList,
}) {
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);

  const popupRef = useRef();
  const handleFetchUsersList = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(api + search, header);
      if (resp) {
        setLoading(false);
        setList(resp.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (search !== "") {
      const dbounce = setTimeout(() => {
        handleFetchUsersList();
      }, 500);

      return () => clearTimeout(dbounce);
    }
  }, [search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleSelected = (item) => {
    setSelected(item);
    setSearch(item.name);
    setShow(false);
  };

  return (
    <div>
      <div className="search position-relative">
        <Input
          className="formInput"
          placeholder="Name / Email / Phone"
          onClick={() => setShow(true)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          prefix={<FiSearch />}
        />
        <Spin spinning={loading}>
          <div
            className={`searchData ${
              show && search.length > 0 ? "active" : ""
            }`}
            ref={popupRef}
          >
            <List
              dataSource={list}
              className="mb-2"
              renderItem={(item) => (
                <List.Item onClick={() => handleSelected(item)}>
                  {item.name} ({item.phone})
                </List.Item>
              )}
            />
            <AddUserModal />
          </div>
        </Spin>
      </div>
    </div>
  );
}
