import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { post } from "../../../services/ApiRouting";
import { getToken } from "../../../services/Authentication";

export default function UpdateCategory() {
  const [form] = Form.useForm();
  const { drawerData, setDrawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const {access_token} = getToken();

  const handleUploadFood = async (values) => {
    try {

      console.log(values)
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("slug", generateSlug(values.name));
      formData.append("_method", "put");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/food-and-categ-list/"+values.id, formData, header);

      if (resp.message  ) {
        setDrawerData((prev) => ({ ...prev, isOpen: false }));
        setLoading(false);
        message.success("Updated Successfully");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      message.error("Unable to update please try again");
    }
  };

  useEffect(() => {
    if (drawerData) {
      form.setFieldsValue({
        id:drawerData.data.id,
        name: drawerData.data.name,
        description: drawerData.data.description,
      });
    }
    // eslint-disable-next-line
  }, [drawerData]);

    // ============= Genearte slug ============
    function generateSlug(name) {
      const slug = name.toLowerCase().replace(/\s+/g, "-");
      const cleanedSlug = slug.replace(/[^\w-]/g, "");
  
      return cleanedSlug;
    }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleUploadFood}
      autoComplete="off"
    >
      <Form.Item name="id" className="d-none">
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        label="Enter Categorie Name"
        rules={[
          {
            required: true,
            message: "Please enter categorie name",
          },
        ]}
      >
        <Input className="formInput" />
      </Form.Item>
      <Form.Item name="description" label="Enter Categorie Description">
        <TextArea className="formTextArea" rows={4} showCount={true} />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} htmlType="submit" className="uploadButton">
          Update Changes
        </Button>
      </Form.Item>
    </Form>
  );
}
