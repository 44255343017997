import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Button, Modal, Spin, Pagination, message } from "antd";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { post } from "../../services/ApiRouting";
import { getToken } from "../../services/Authentication";

const AdminitiesAddModal = ({ setAminitiesList, aminitiesList }) => {
  const [iconsData, setIconsData] = useState([]);
  const [searchIcon, setSearchIcon] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isActive, setIsActive] = useState();
  const [aminitieName, setAminitieName] = useState("");
  const { access_token } = getToken();

  const getIcons = async (search, page) => {
    setLoading(true);
    const acountPage = page - 1;
    const resp = await axios.get(
      "https://iconfinder-api-auth.herokuapp.com/v4/icons/search?query=" +
        search +
        "&count=24&icon_style=outline&offset=" +
        acountPage +
        "",
      {
        headers: {
          Authorization:
            "Bearer XUgH1N1AhqCefRNDhfh83MvJFT67jzAjyQXNo6UJGxTj1STVXYBFbCubQ83Zt8GS",
        },
      }
    );
    if (resp) {
      setIconsData(resp.data.icons);
      setTotal(resp.data.total_count);
    }
    setLoading(false);
  };

  useEffect(() => {
    const timeRender = setTimeout(() => {
      getIcons(searchIcon, page);
    }, 500);

    return () => clearTimeout(timeRender);
  }, [searchIcon, page]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const handleCreateAminitie = async () => {
    try {
      setLoading(true);
      const aminitieData = {
        aminites_image: isActive,
        aminities_name: aminitieName,
      };

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/aminities-detail", aminitieData, header);

      if (resp) {
        aminitieData.aminties_id = resp.id;
        setAminitiesList((prev) => [...prev, aminitieData]);
        setLoading(false);
        setIsActive(null);
        setAminitieName("");
        setIsModalOpen(false);
        message.success("Added succesfully");
      }
    } catch (error) {
      console.error(error);
      message.error("Unable add aminitie");
    }
  };

  return (
    <>
      <Button
        onClick={showModal}
        type="text"
        icon={<PlusOutlined />}
        style={{ width: "100%" }}
      >
        Add New
      </Button>
      <Modal
        title="Create Aminities"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={768}
        centered
      >
        <div className="amintiesSection">
          <div className="searchAminities">
            <Input
              placeholder="Search here.."
              className="formInput"
              prefix={<FiSearch />}
              onChange={(e) => setSearchIcon(e.target.value)}
            />
            <Divider orientation="left">Select Icon</Divider>
          </div>
          <Spin spinning={loading}>
            <div className="listOfIcons">
              <div className="row">
                {iconsData.map((value) =>
                  value.raster_sizes[5] ? (
                    <div
                      onClick={() =>
                        setIsActive(
                          value.raster_sizes[5]?.formats[0]?.preview_url
                        )
                      }
                      className={`col-md-2 d-flex align-items-center justify-content-center py-3 iconCard ${
                        isActive ===
                        value.raster_sizes[5]?.formats[0]?.preview_url
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={value.raster_sizes[5]?.formats[0]?.preview_url}
                        style={{ width: "100%", maxWidth: "50px" }}
                      />
                    </div>
                  ) : null
                )}
                <Divider />
                <div className="col-md-4 d-flex align-items-center">
                  <Pagination
                    simple
                    showSizeChanger={false}
                    current={page}
                    total={total}
                    onChange={(page, pageSize) => setPage(page)}
                  />
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <Input
                    value={aminitieName}
                    className="formInput"
                    placeholder="Enter Aminitie Name"
                    prefix={<MdDriveFileRenameOutline />}
                    onChange={(e) => setAminitieName(e.target.value)}
                  />
                  <Button
                    loading={loading}
                    disabled={aminitieName === ""}
                    className="uploadButton mx-3"
                    onClick={handleCreateAminitie}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};
export default AdminitiesAddModal;
