import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  List,
  Segmented,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import TextArea from "antd/es/input/TextArea";
import { del, get, post } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";
import { BiSearch } from "react-icons/bi";

export default function RoomType() {
  const [selectedTab, setSelectedTab] = useState("Room Type List");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkBoxRefs = useRef();
  const [checkedId, setCheckedId] = useState([]);
  const [form] = Form.useForm();
  const { access_token } = getToken();
  const { profile } = getProfile();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");

  const fetchPriceDetail = async (type, page, limit, search) => {
    setLoading(true);
    const header = {
      Authorization: "Bearer " + access_token,
    };
    const resp = await get(
      `/room-other-detail/${type}/${profile.account_id}/${page}/${limit}${
        search !== "" ? "/" + search : ""
      }`,
      header
    );
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchPriceDetail("categorie", page, limit, searchText);
    }, 300);

    return () => clearTimeout(renderTime);
    // eslint-disable-next-line
  }, [page, limit, searchText]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      let newData = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];
          formData.append(key, value);
          newData[key] = value; // Corrected: Use square brackets to set dynamic key
        }
      }
      formData.append("type", "categorie");
      formData.append("account_id", profile.account_id);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await post("/room-other-detail/", formData, header);
      if (resp) {
        const prevData = data;
        prevData.push(newData);
        setData([...prevData]);
        setLoading(false);
        message.success("Upload succesfully");
        form.resetFields();
      }
    } catch (error) {
      console.error(error);
      message.error("Name already exist please try with other name");
    }
  };

  // Function to handle "Check All" checkbox change
  const handleCheckAllChange = (e) => {
    let data = [];
    if (e.target.checked) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        if (checkbox.value != "on") {
          data.push(parseInt(checkbox.value));
        }
      });
      setCheckedId([...data]);
    } else {
      setCheckedId([]);
    }
  };

  const handleSingleChecked = (id) => {
    const prevData = checkedId;
    const index = prevData.findIndex((value) => value === id);
    if (index !== -1) {
      prevData.splice(index, 1);
    } else {
      prevData.push(id);
    }
    setCheckedId([...prevData]);
  };

  const handleRemoveCheckID = async () => {
    if (
      window.confirm(
        "Are sure want to remove because it will effect to your room list ?"
      )
    ) {
      try {
        const stringJson = JSON.stringify(checkedId);
        setLoading(true);
        const header = {
          Authorization: "Bearer " + access_token,
        };
        const resp = await del(
          "/room-other-detail-remove/" + stringJson,
          header
        );

        if (resp.message) {
          setLoading(false);
          message.success("Removed Successfully");
          fetchPriceDetail("price", 0, 10, "");
        }
      } catch (error) {
        console.error(error);
        message.error("Unable remove please try again");
      }
    }
  };
  return (
    <div>
      <Segmented
        defaultValue={selectedTab}
        style={{
          padding: 5,
          background: "#808080",
          color: "#fff",
        }}
        onChange={(value) => setSelectedTab(value)}
        options={["Room Type List", "Add Room Type"]}
      />
      <Divider />

      {selectedTab === "Room Type List" ? (
        <>
          <Input
            placeholder="Search here..."
            className="searchInput"
            prefix={<BiSearch />}
            onInput={(e) => setSearchText(e.target.value)}
          />
          <Divider />
          <div className="d-flex align-items-center justify-content-between">
            <Checkbox onChange={handleCheckAllChange}>Select All</Checkbox>
            <Button
              danger
              type="primary"
              disabled={checkedId.length === 0}
              onClick={handleRemoveCheckID}
            >
              <FaTrashAlt />
              &nbsp;Removed
            </Button>
          </div>
          <Divider />
          <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  avatar={
                    <Checkbox
                      id="checkDataId"
                      name={"id" + index}
                      value={item.id}
                      ref={checkBoxRefs}
                      checked={checkedId.some((value) => value == item.id)}
                      onChange={() => handleSingleChecked(item.id)}
                    />
                  }
                  title={<strong>{item.name}</strong>}
                  description={
                    item.description && item.description !== "undefined" ? (
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item.description}
                      </p>
                    ) : (
                      ""
                    )
                  }
                />
              </List.Item>
            )}
          />
        </>
      ) : (
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item label="Title" name="name">
            <Input placeholder="Title" className="formInput" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea
              placeholder="Description"
              showCount
              maxLength={500}
              rows={5}
              className="formInput"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              className="uploadButton w-100"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
