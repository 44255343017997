import {
  Input,
  Switch,
  List,
  Empty,
  Button,
  Tabs,
  Form,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import {
  MdCurrencyRupee,
  MdDragIndicator,
  MdOutlineLineWeight,
} from "react-icons/md";
import { IoIosRemoveCircle } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { IoIosList } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
import { PlusOutlined } from "@ant-design/icons";
// import { post } from "../../../services/ApiRouting";

// =============== List Style =============
const ListData = ({ selectedCategory, setSelectedCategory }) => {
  const removeFromList = (index) => {
    const previous = selectedCategory;
    previous.foods.splice(index, 1);
    setSelectedCategory(previous);
    // setSelectedCategory(previous);
    message.success("Removed Successs");
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={selectedCategory.foods}
      renderItem={(item, index) => (
        <List.Item
          key={index + 1}
          style={{
            boxShadow: "0 0 1.5px #808080",
            padding: "5px 7px",
            margin: "10px 0",
            cursor: "grab",
            background: "#fff",
          }}
          // onDragStart={(e) => handleDragStart(e, item)}
        >
          <List.Item.Meta
            avatar={<MdDragIndicator />}
            title={
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ textTransform: "capitalize" }}
              >
                <strong>{item.name}</strong>
                <div className="controllButton">
                  <FaEdit
                    style={{
                      color: "orange",
                      fontSize: "1.2rem",
                      cursor: "pointer",
                    }}
                  />
                  &nbsp;&nbsp;
                  <IoIosRemoveCircle
                    onClick={() => removeFromList(index)}
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            }
            description={
              <div className="priceQuantity">
                <span>
                  <MdCurrencyRupee /> {item.price}
                </span>
                &nbsp;&nbsp;
                <span>Qty {item.quantity}</span>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

// =============== Add Food Form =================

const FoodAddForm = ({
  selectedCategory,
  setSelectedCategory,
  setActiveTab,
}) => {
  const [fileList, setFileList] = useState([]);
  const [checked, setChecked] = useState(true);
  const [form] = Form.useForm();

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <Button
      disabled={fileList.length > 0}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <PlusOutlined />
      Upload Images
    </Button>
  );

  const slugGenearte = (name) => {
    return name
      .toLowerCase() // Convert the string to lowercase
      .trim() // Remove leading and trailing whitespaces
      .replace(/\s+/g, "-");
  };

  const handleFoodSubmit = async (value) => {
    // try {
    // const formData = new FormData();
    // formData.append("name", value.name);
    // formData.append("slug", slugGenearte(value.name));
    // formData.append("description", value.description);
    // if (fileList.length > 0) {
    //   formData.append("image", fileList[0].originFileObj);
    // }
    // formData.append("type", value.foodType === true ? "veg" : "non veg");
    // formData.append("price[]", value.price);
    // formData.append("quantity[]", value.quantity);

    // const resp = await post("/food-details", formData);

    // if (resp) {
    message.success("Upload succesfully");
    form.resetFields();
    setFileList([]);
    setActiveTab("1");

    const data = {
      name: value.name,
      slug: slugGenearte(value.name),
      description: value.description,
      type: value.foodType === true ? "veg" : "non veg",
      detail: [
        {
          price: value.price,
          quantity: value.quantity,
        },
      ],
      price: value.price,
      quantity: value.quantity,
    };
    const previousData = selectedCategory;
    previousData.foods.push(data);
    setSelectedCategory(previousData);
    //   }
    // } catch (error) {
    //   message.error("Failed to upload");
    //   console.log(error);
    // }
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [selectedCategory]);

  return (
    <Form form={form} onFinish={handleFoodSubmit}>
      {/* <div className="d-flex align-items-center justify-content-between">
        <Form.Item name="image">
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            fileList={fileList}
            onChange={handleChange}
            accept=".JPG, .PNG, .JPEG, .WEBP"
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item name="foodType">
          <Switch
            checkedChildren="Veg"
            unCheckedChildren="Non Veg"
            checked={checked}
            onChange={(e) => setChecked(e)}
          />
        </Form.Item>
      </div> */}
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please enter name" }]}
      >
        <Input className="formInput" placeholder="Name" />
      </Form.Item>

      <Form.Item name="description">
        <TextArea
          placeholder="Description"
          className="formTextArea mb-2"
          rows={3}
          showCount
          maxLength={200}
        />
      </Form.Item>

      <div className="row">
        <div className="col-6 d-flex align-items-center">
          <Form.Item
            name="quantity"
            rules={[{ required: true, message: "Please enter quantity" }]}
          >
            <Input
              prefix={<MdOutlineLineWeight />}
              placeholder="Quantity"
              className="formInput"
              // value={value.quantity}
              // onChange={(e) => handleUpdateData(e, "quantity", index)}
            />
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item
            name="price"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
              prefix={<MdCurrencyRupee />}
              placeholder="Price"
              className="formInput"
              // value={value.price}
              // onChange={(e) => handleUpdateData(e, "price", index)}
              min={0}
            />
          </Form.Item>
        </div>
      </div>
      <Button htmlType="submit" className="uploadButton">
        Submit
      </Button>
    </Form>
  );
};

// ================ Main Function ==============
export default function CategorySelected({
  selectedCategory,
  setSelectedCategory,
  setComboOptionFoodList,
  comboOptionFoodList,
}) {
  const [isInRange, setIsInRange] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  // ================= Tab List ====================

  const tabList = [
    {
      key: 1,
      label: "List",
      children: (
        <ListData
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setActiveTab={setActiveTab}
        />
      ),
      icon: <IoIosList />,
    },
    {
      key: 2,
      label: "Add",
      children: (
        <FoodAddForm
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setActiveTab={setActiveTab}
        />
      ),
      icon: <IoIosAddCircle />,
    },
  ];

  const slugGenearte = (name) => {
    return name
      .toLowerCase() // Convert the string to lowercase
      .trim() // Remove leading and trailing whitespaces
      .replace(/\s+/g, "-");
  };

  // ============= Update Category Data ===========
  const updateSelecteCategoryData = (data, type) => {
    switch (type) {
      case "name":
        setSelectedCategory((prev) => ({ ...prev, name: data.target.value }));
        setSelectedCategory((prev) => ({
          ...prev,
          slug: slugGenearte(data.target.value),
        }));
        break;
      case "description":
        setSelectedCategory((prev) => ({
          ...prev,
          description: data.target.value,
        }));
        break;
      case "option_select":
        setSelectedCategory((prev) => ({ ...prev, option_select: data.target.value }));
        break;
      case "is_required":
        setSelectedCategory((prev) => ({ ...prev, is_required: data }));
        break;
      default:
        break;
    }
  };
  // =============== Save Category =================
  const SaveCategoryData = () => {
    const index = comboOptionFoodList.findIndex(
      (value) => value.id === selectedCategory.id
    );
    const prevData = comboOptionFoodList;
    prevData[index] = selectedCategory;

    setComboOptionFoodList([...prevData]);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    let data = event.dataTransfer.getData("foods");
    if (data) {
      data = JSON.parse(data);
      data.detail = [
        {
          price: data.price,
          quantity: data.quantity,
        },
      ];
      
      const previousData = selectedCategory;
      previousData.foods.push(data);
      setSelectedCategory(previousData);
    } else {
      message.error("Please drop foods only over here...");
    }
    setIsInRange(false);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsInRange(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setIsInRange(false);
  };

  return selectedCategory ? (
    <div
      className="selectedFoodDetails my-2"
      style={{ position: "sticky", top: "100px" }}
    >
      <h6 className="d-flex align-items-center justify-content-between">
        Selected Category Details{" "}
        <Button onClick={SaveCategoryData} className="uploadButton">
          Save
        </Button>
      </h6>

      <Input
        className="formInput mb-2"
        placeholder="Title"
        value={selectedCategory.name}
        onChange={(e) => updateSelecteCategoryData(e, "name")}
      />

      <TextArea
        placeholder="Description"
        className="formTextArea mb-2 mt-2"
        showCount
        rows={3}
        maxLength={200}
        value={selectedCategory.description}
        onChange={(e) => updateSelecteCategoryData(e, "description")}
      />
      <div className="row mt-2">
        <div className="col-md-6">
          <select
            className="form-select"
            onChange={(e) => updateSelecteCategoryData(e, "option_select")}
            placeholder="Any oft"
          >
            <option disabled selected>
              Select Up to
            </option>
            <option value={null}>No need</option>
            {selectedCategory.foods.map((value, index) => (
              <option
                selected={selectedCategory.select === index + 1}
                value={index + 1}
              >
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <strong>Required : </strong>
          <Switch
            checked={selectedCategory.is_required}
            onChange={(e) => updateSelecteCategoryData(e, "is_required")}
          />
        </div>
      </div>

      <div
        className="position-relative"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <Tabs defaultActiveKey={activeTab} items={tabList} />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgb(0,0,0,.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            opacity: isInRange ? 1 : 0,
            visibility: isInRange ? "visible" : "hidden",
            transition: "0.3s ease-in-out",
          }}
        >
          <h2>Drop Here</h2>
        </div>
      </div>
    </div>
  ) : (
    <Empty />
  );
}
