import { Button, Input, Table, Tag, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { get, del } from "../../../services/ApiRouting";
import { FaTrash } from "react-icons/fa";
import moment from "moment";
import { getToken } from "../../../services/Authentication";

export default function DiscountAssignedList({ value, setViewComponent }) {
  const [dataSource, setdataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      width: "30%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Assigned At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  // =========== Fetch Rooms =============

  const {access_token} = getToken();

  const fetchDiscountData = async (id) => {
    try {
      setLoading(true);
      const header = {
        Authorization:"Bearer "+access_token,
      }
      const resp = await get("/discount-id/" + id,header);
      const data = resp.data.map((valueData) => ({
        ...valueData,
        created_at: moment(valueData.created_at).format("lll"),
        type: (
          <Tag className={valueData.entity_type}>
            {valueData.entity_type.toUpperCase()}
          </Tag>
        ),
        action: (
          <>
            <Tooltip placement="bottom" title="Remove List">
              <Button
                className="iconButtonAction remove"
                onClick={() => handleRemovedAssignedList(valueData.id)}
              >
                <FaTrash />
              </Button>
            </Tooltip>
          </>
        ),
      }));

      setdataSource(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDiscountData(value.id);
    // eslint-disable-next-line 
  }, [value]);

  const handleRemovedAssignedList = async (id) => {
    if (window.confirm("Are sure you want to remove ?")) {
      setLoading(true);
      const resp = await del("/discount-assign/" + id);

      if (resp) {
        message.success("Removed Success");
        setLoading(false);
        fetchDiscountData(value.id);
      }
    }
  };

  return (
    <div>
      <div className="headerPanel mb-4 d-flex justify-content-between align-items-center">
        <Input
          prefix={<FiSearch />}
          className="searchInput"
          placeholder="Search here.."
        />
        <Button
          onClick={() => setViewComponent("add")}
          icon={<IoMdAddCircle />}
          style={{ height: "40px", background: "green", color: "#fff" }}
          type="primery"
        >
          Apply New
        </Button>
      </div>
      <Table columns={columns} dataSource={dataSource} loading={loading} />
    </div>
  );
}
