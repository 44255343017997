import React, { useState } from "react";
import { Input, Button } from "antd";
import { FiSearch } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";
import BookingFilterModal from "../../Modal/BookingFilterModal";

export default function SearchHeader({
  handleDataGet,
  setSearch,
  search,
  page,
  limit,
  account_id,
}) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="w-100" style={{ paddingRight: "20px" }}>
        <Button
          icon={<IoMdRefresh />}
          onClick={() => handleDataGet(page, limit, account_id, search)}
          className="addButtons"
        >
          Refresh Data
        </Button>
        {/* <BookingFilterModal /> */}
      </div>
      <Input
        style={{ maxWidth: "300px" }}
        placeholder="Search Name / Phone / Email"
        className="formInput"
        prefix={<FiSearch />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}
