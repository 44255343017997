import { Button, Checkbox, Divider, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getProfile, getToken } from "../../services/Authentication";
import { FaKey } from "react-icons/fa6";
import { post } from "../../services/ApiRouting";

export default function PaymentDetail() {
  const [advancePayment, setAdvancePayment] = useState(false);
  const [form] = Form.useForm();
  const { profile } = getProfile();
  const [paymentType, setPaymentType] = useState("");
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();

  useEffect(() => {
    form.setFieldsValue(profile.account);
    setPaymentType(profile.account.pg_type);
    setAdvancePayment(profile.account.is_advnc_pay_active === 1 ? true : false);
  }, []);

  console.log(advancePayment);

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      let dataPrev = { ...profile.account }; // Copy the account object to avoid mutating the original
      Object.keys(value).forEach((key) => {
        dataPrev[key] = value[key];
      });
      dataPrev["is_advnc_pay_active"] = advancePayment ? 1 : 0;

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const formData = new FormData();
      formData.append("id", profile.account.payment_id);
      formData.append("advnc_pay", value.advnc_pay);
      formData.append("is_advnc_pay_active", advancePayment ? 1 : 0);
      formData.append("tax_payment", value.tax_payment);
      formData.append("pg_type", value.pg_type);
      formData.append("pg_category", value.pg_category);
      formData.append("pg_key", value.pg_key);
      formData.append("_method", "put");

      const res = await post("/update-payment", formData, header);

      if (res) {
        const updatedProfile = { ...profile, account: dataPrev }; // Create a new profile object with the updated account data
        const profileString = JSON.stringify(updatedProfile);
        const findData = sessionStorage.getItem("profile");
        if (findData) {
          sessionStorage.setItem("profile", profileString);
        } else {
          localStorage.setItem("profile", profileString);
        }

        setLoading(false);
        message.success("Update successfully");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable to update, please try again !");
    }
  };

  console.log(advancePayment);
  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Form.Item
            label="Select Advance Payment"
            name="advnc_pay"
            required={[{ required: true, message: "Select payment percent" }]}
          >
            <Select
              placeholder="Select Advance Payment"
              disabled={!advancePayment}
              options={[
                { label: "10%", value: "10%" },
                { label: "30%", value: "30%" },
                { label: "50%", value: "50%" },
                { label: "70%", value: "70%" },
              ]}
            />
          </Form.Item>

          {/* <Form.Item valuePropName="checked" name="is_advnc_pay_active"> */}
          <Checkbox
            onChange={(e) => setAdvancePayment(e.target.checked)}
            checked={advancePayment}
          >
            Accept Advance Payment
          </Checkbox>
          {/* </Form.Item> */}
        </div>

        <div className="col-md-6">
          <Form.Item label="Enter tax percent" name="tax_payment">
            <Input suffix="%" className="formInput" placeholder="Tax Amount" />
          </Form.Item>
        </div>

        <div className="col-12">
          <Divider orientation="left">Payment Gateway Detail</Divider>
        </div>

        <div className="col-md-6">
          <Form.Item label="Payment Type" name="pg_type">
            <Select
              placeholder="Select Payment Type"
              onChange={(value) => setPaymentType(value)}
              options={[
                {
                  label: "Application Payment Gateway",
                  value: "application",
                },
                {
                  label: "Own Payment Gateway",
                  value: "own",
                },
              ]}
            />
          </Form.Item>
        </div>

        <div className="col-md-6">
          <Form.Item label="Payment Gateway Type" name="pg_category">
            <Select
              disabled={paymentType === "application"}
              placeholder="Select Payment Gateway Type"
              options={[
                {
                  label: "Rayzorpay",
                  value: "rayzorpay",
                },
                {
                  label: "CC Avenue",
                  value: "cc-avenue",
                },
                {
                  label: "Phone Pay",
                  value: "phone-pay",
                },
                {
                  label: "Paytm",
                  value: "paytm",
                },
              ]}
            />
          </Form.Item>
        </div>

        <div className="col-12">
          <Form.Item label="Enter PG Key" name="pg_key">
            <Input
              prefix={<FaKey />}
              disabled={paymentType === "application"}
              className="formInput"
              placeholder="Enter Key"
            />
          </Form.Item>
        </div>

        <div className="col-12">
          <Button htmlType="submit" loading={loading} className="uploadButton">
            Update Details
          </Button>
        </div>
      </div>
    </Form>
  );
}
