import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { post } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";

export default function AddCategory() {
  const [form] = Form.useForm();
  const { setDrawerData } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const [databaseError, setDatabaseError] = useState(null);
  const { profile } = getProfile();

  const handleUploadFood = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("name", values.categName);
      formData.append("slug", generateSlug(values.categName));
      formData.append("image", "");
      formData.append("type", "main");
      formData.append("description", values.categDiscrp);
      formData.append("account_id", profile.account_id);
      formData.append("branch_id_reff", "");

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const result = await post("/food-and-categ-list", formData, header);

      if (result) {
        message.success("Add sucessfully");
        setDrawerData((prev) => ({ ...prev, isOpen: false }));
        form.resetFields();
        setDatabaseError(null);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      message.error(e.response.data.message);
      setLoading(false);
      setDatabaseError(e.response.data.message);
    }
  };

  // ============= Genearte slug ============
  function generateSlug(name) {
    const slug = name.toLowerCase().replace(/\s+/g, "-");
    const cleanedSlug = slug.replace(/[^\w-]/g, "");

    return cleanedSlug;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleUploadFood}
      autoComplete="off"
    >
      {databaseError && (
        <span style={{ color: "red" }}>
          <strong>Error:</strong> {databaseError}
        </span>
      )}

      <Form.Item
        name="categName"
        label="Enter Categorie Name"
        rules={[
          {
            required: true,
            message: "Please enter categorie name",
          },
        ]}
      >
        <Input className="formInput" />
      </Form.Item>
      <Form.Item name="categDiscrp" label="Enter Categorie Description">
        <TextArea className="formTextArea" rows={4} showCount={true} />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} htmlType="submit" className="uploadButton">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
